import { useSelector, useDispatch } from "react-redux";
import { removeModal } from "../../reducers/modal/dialogModalSlice";
export default function DialogModal() {
  const dispatch = useDispatch();
  const { title, content, rightButtonText, rightButtonFunction } = useSelector((state) => state.dialogModal) || {};

  const handleCloseModal = () => {
    dispatch(removeModal());
  };

  const handleRightButtonOnClick = () => {
    rightButtonFunction();
    handleCloseModal();
  };

  if (!title) return;

  return (
    <>
      <div className=" fixed top-0 left-0 w-full h-screen bg-black bg-opacity-80 z-50 bg-blur" onClick={handleCloseModal}></div>
      <div
        id="defaultModal"
        className={`animate__animated animate__bounceIn fixed top-0 right-0 left-0 bottom-0 m-auto z-50 w-full xl:w-[400px] h-[300px]`}
      >
        <div className="relative w-full h-full">
          <div className="relative bg-white h-full rounded-lg shadow">
            <div className="flex justify-between items-center p-4 rounded-t border-b h-[65px]">
              <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
              <button
                type="button"
                onClick={handleCloseModal}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5 ml-auto inline-flex items-center"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="h-[170px] max-w-full p-4 overflow-y-auto flex items-center">
              <p className="leading-[33px] text-black text-[16px]" dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
            <div className="flex items-center py-5 px-2 justify-end rounded-b border-t border-gray-200 h-[65px]">
              <button
                onClick={handleCloseModal}
                type="button"
                className="text-white font-bold bg-stone-600 hover:opacity-80 py-1 px-5 rounded mr-2"
              >
                Hủy
              </button>
              <button
                onClick={handleRightButtonOnClick}
                type="button"
                className="text-white font-bold bg-green-700 hover:opacity-80 py-1 px-5 rounded"
              >
                {rightButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
