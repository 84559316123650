import React from 'react'
import Logo from './Components/Logo/Logo'

export default function LittleHeader() {
  return (
    <div
      // Tag này bọc ngoài phần header mini -> để có thể căn chỉnh các phần tử bên trong (Vỏ bọc của header mini)
      // Chiều cao của header mini là 7rem (h-28)
      // Màu nền của header mini là màu primary
      className="
            w-full
            h-28
            bg-[#fafafa]
            border-b-4
            border-solid
            shadow-lg
            border-primary-color
            relative
            select-none
            cursor-pointer
        "
      style={{
        backgroundImage: "url('/images/bg-home.webp')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={() => {
        window.location.href = '/'
      }}
    >
      {/* Phần header mini này có 1 phần tử duy nhất là logo
       */}
      {/* Phần logo*/}
      <Logo />
    </div>
  )
}
