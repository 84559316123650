import React from "react";

export default function PageNotFound() {
  return (
    <section className="flex items-center justify-center h-screen bg-gray-100 p-5">
      <div className="flex flex-col items-center justify-center mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl text-primary-color">
            404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl pb-12">
            Trang này không tồn tại, vui lòng quay lại trang chủ
          </p>
          <a
            rel="noopener noreferrer"
            href="/"
            className="px-8 py-3 font-semibold rounded bg-primary-color text-white hover:opacity-80"
          >
            Trang chủ
          </a>
        </div>
      </div>
    </section>
  );
}
