import axios from "axios";
import { useState, useRef } from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import Loading from "../Loading/Loading";
import { BsCameraFill } from "react-icons/bs";
import {useDispatch} from "react-redux";
import { addSuccessMessage } from "../../reducers/message/messageSlice";


export default function CameraSection({
  uri,
  handleTakePhotoAnimationDone,
  onUpload,
  scrollToInput,
  id,
}) {
  const dispatch = useDispatch();
  const cameraRef = useRef();
  const [loading, setLoading] = useState(false);
  const [facingMode, setFacingMode] = useState(FACING_MODES.ENVIRONMENT);
  const handleCameraError = () => { };
  const handleCameraStart = () => { };
  const handleCameraStop = () => { };
  const handleUploadImage = (uri) => {
    setLoading(true);
    setShowCam(false);
    const url = "https://demo.bagang.ai:4443/api/ocr/cavet";
    const img = uri.split(",")[1];
    axios
      .post(url, { img: img })
      .then((responses) => {
        onUpload(responses.data);
        dispatch(addSuccessMessage({ title: "Upload thành công", content: "Thông tin đã được tự động điền, bạn kiểm tra lại thông tin nhé!"}));
        scrollToInput();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleScrollToCamera = () => {
    cameraRef.current.scrollIntoView();
  }

  const [showCam, setShowCam] = useState(false);
  const [showImg, setShowImg] = useState(false);

  const handleShowCam = () => {
    setShowCam(true);
    // cameraRef.current.scrollIntoView();
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageUpload = async (e) => {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      const image = e.target.files[0];
      const base64 = await convertBase64(image);
      handleTakePhotoAnimationDone(base64);
      handleUploadImage(base64);
      setShowImg(true);
    }
  };

  const rotateCamera = () => {
    if (facingMode === FACING_MODES.ENVIRONMENT) {
      setFacingMode(FACING_MODES.USER);
    } else {
      setFacingMode(FACING_MODES.ENVIRONMENT);
    }
    // alert("Đang xoay camera");
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="mb-5" id={id}>
        <div className="px-5">
          <button
            type="button"
            name="upload-section"
            onClick={handleShowCam}
            className={`border-2 border-dashed border-primary-color w-full px-5 py-20 rounded-[15px]`}
          >
            <div className="text-center text-primary-color text-lg">
              <i className="text-[40px] font-semibold text-center">
                <BsCameraFill style={{ margin: "auto" }} />
              </i>

              <h3 className="text-[18px] text-center font-semibold px-[45px] text-gray-700">VUI LÒNG CHỤP CÀ VẸT THÔNG TIN SẼ TỰ ĐIỀN</h3>
            </div>
          </button>
        </div>

        <div className="text-right mt-2">
          <button type="button" name="upload" className="bg-primary-color text-white font-bold py-2 px-2 mr-5 mb-2 relative hover:opacity-80 hover:cursor-pointer rounded-[4px] text-[13px]">
            <i className="fa-solid fa-cloud-arrow-up mr-2"></i>
            Tải ảnh
            <input
              className="opacity-0 absolute w-full h-full top-0 left-0"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e)}
            />
          </button>
        </div>

        <div ref={cameraRef}>
          {showCam || uri ? (
            <>
              {showCam === true && (
                <div className="fixed h-screen w-full bg-black bg-opacity-80 bg-blur top-0 left-0 z-50 flex items-center justify-center" onClick={() => setShowCam(false)}>
                  <i className="fa-solid fa-xmark text-white absolute top-10 right-10 text-[50px] hover:opacity-80 hover:cursor-pointer" onClick={() => setShowCam(false)}></i>
                  <div className="relative" onClick={(e) => e.stopPropagation()}>
                    <Camera
                      idealFacingMode={facingMode}
                      onTakePhotoAnimationDone={(dataUri) => {
                        if (dataUri && dataUri !== "" && dataUri !== 'data:,') {
                          handleTakePhotoAnimationDone(dataUri);
                          handleUploadImage(dataUri);
                        } else {
                          alert("Không thế nhận hình ảnh, vui lòng cấp quyền truy cập camera và thử lại sau!");
                        }
                      }}
                      onCameraError={(error) => {
                        handleCameraError(error);
                      }}
                      onCameraStart={(stream) => {
                        handleCameraStart(stream);
                      }}
                      onCameraStop={() => {
                        handleCameraStop();
                      }}
                    />
                    <i className="fa-solid fa-camera-rotate absolute bottom-10 right-10 text-white text-[30px] hover:opacity-80 hover:cursor-pointer" onClick={() => rotateCamera()}></i>
                  </div>
                </div>
              )}

              {uri && (
                <img
                  src={uri}
                  alt="preview-image"
                  className="w-full md:w-1/3 mx-auto mt-10"
                ></img>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
