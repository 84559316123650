import React from 'react'
import { Link } from 'react-router-dom'
import GetInsuranceSection from './GetInsuranceSection';

export default function PaymentCompletedForm({ status, message, orderId, email, linkFileCertificate, note }) {

    const getTextClassColor = (status) => {
        if (status === 'success') {
            return 'text-green-600';
        };
        if (status === 'fail') {
            return 'text-red-600';
        };
        return 'text-gray-600';
    };

    const getBackgroundClassColor = (status) => {
        if (status === 'success') {
            return 'bg-green-600';
        };
        if (status === 'fail') {
            return 'bg-red-600';
        };
        return 'bg-gray-600';
    };

    const getClassIcon = (status) => {
        if (status === 'success') {
            return 'fa-solid fa-check';
        };
        if (status === 'fail') {
            return 'fa-solid fa-xmark';
        };
        return 'fa-solid fa-check';
    };

    return (
        <div className="w-full min-h-screen flex items-center justify-center shadow mx-auto py-4 bg-white ">
            <div className="text-center">
                <div
                    className={`h-24 w-24 rounded-full ${getBackgroundClassColor(
                        status
                    )} flex items-center justify-center mx-auto`}
                >
                    <i
                        className={`${getClassIcon(
                            status
                        )} text-white text-[45px] text-white`}
                    ></i>
                </div>
                <div className="m-3 text-center text-xl leading-[40px] text-gray-600">
                    {message && <h2>
                        Thanh toán đang ở trạng thái{" "}
                        <b className={`${getTextClassColor(status)}`}>{message}</b>
                    </h2>}
                    <p className={`${getTextClassColor(status)} font-semibold text-md`}>
                        Ghi chú: {note}
                    </p>
                    <div>
                        Mã số đơn hàng của bạn là{" "}
                        <b className={`${getTextClassColor(status)}`}>{orderId}</b>
                    </div>
                </div>
                <Link to={"/"} className="mb-5">
                    <div className="text-center">
                        <button
                            className={`bg-gray-600 text-white text-[15px] text-center h-12 w-52 font-medium hover:opacity-80`}
                        >
                            QUAY VỀ TRANG CHỦ
                        </button>
                    </div>
                </Link>
                {status === 'success' && <GetInsuranceSection linkFileCertificate={linkFileCertificate} email={email} />}
            </div>
        </div>
    )
};
