import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Loading from '../../components/Loading/Loading'
import Axios from '../../functions/axios'
import PaymentCompletedForm from '../../components/PaymentCompleted/PaymentCompletedForm'
import { accessTrade } from '../../functions/checkCookie'
import { act } from '../../App'

export default function PaymentZalo() {
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams('')
  const [status, setStatus] = useState(null)
  const [message, setMessage] = useState('')
  const [orderId, setOrderId] = useState('')
  const [email, setEmail] = useState('')
  const [linkFileCertificate, setLinkFileCertificate] = useState('')
  const [note, setNote] = useState('')

  // Sau khi thanh toán giấy bảo hiểm có thể chưa có liền nên phải refetch
  const refetchAPI = (refetchCount = 0, orderId) => {
    let linkFileCertificate = ''
    let voucherOrderId = 0
    let transactionId = ''
    let netAmount = 0 
    refetchCount++
    Axios.get(`/api/insurance/GetInsuranceOrderById/${orderId}`)
      .then((res) => {
        linkFileCertificate = res.data.data?.detail?.linkFileCertificate
        voucherOrderId = res.data.data?.voucherOrderId
        transactionId = res.data.data?.voucherOrderId
        netAmount = res.data.data?.netAmount
        const email = res.data.data?.detail?.email
        if (email) setEmail(email)
        if (linkFileCertificate) {
          setLinkFileCertificate(linkFileCertificate)
          // Co giay bao hiem
          // Kiem tra xem co phải gọi từ accessTrade không
          // Nếu có thì gọi API để lưu lại

          console.log('Checker', act.checkCookieOnBrowser())
          let statusCheck = act.checkCookieOnBrowser()
          // Nếu như có thì gọi qua lại accessTrade để lưu lại record cho họ rồi xóa cookie
          try {
            if (statusCheck) {
              console.log('RUN HERE')
              let cookieValues = act.getCookieValues() // Định dạng là 1 mảng [_aff_network,_aff_sid]
              console.log('cookieValues', cookieValues)
              let _aff_network = cookieValues[0]
              let _aff_sid = cookieValues[1]
              // Gọi api để lưu lại record
              // _aff_network, _aff_sid khác null thì mới gọi api
              if (_aff_network && _aff_sid)
                Axios.post('api/Insurance/SendConversionsAccessTrade', {
                  _aff_network: _aff_network,
                  _aff_sid: _aff_sid,
                  VoucherOrderId: voucherOrderId,
                  TransactionId: transactionId.toString(),
                  NetAmount: netAmount,
                })
                  .then((res) => {
                    console.log('SendConversionsAccessTrade', res.data)
                    // Xóa cookie
                    act.clearCookieOnBrowser()
                    act.clearCookieValues()
                    console.log('Delete cookie', act.getCookieValueFromBrowser())
                  })
                  .catch((err) => {
                    console.log('SendConversionsAccessTrade', err)
                  })
            }
          } catch (err) {
            console.log('Error', err)
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false) //loading nguyên page ko cần load bây h chỉ cần load giấy thôi (có 2 loading lận)
        if (refetchCount >= 5 && !linkFileCertificate) {
          setStatus('fail')
          setMessage('Hoàn thành')
          setNote(
            'Đơn hàng đã thanh toán nhưng không tìm thấy giấy bảo hiểm. Vui lòng liên hệ với chúng tôi qua email buuboi@bagang.ai để được hỗ trợ. Chúng tôi sẽ liên hệ lại và hỗ trợ sớm nhất có thể trong vòng 48h.',
          )
          setLoading(false)
        } else if (!linkFileCertificate) {
          setTimeout(() => {
            refetchAPI(refetchCount, orderId)
          }, 4500)
        }
      })
  }

  useEffect(() => {
    const message = searchParams.get('vpc_Message')
    const orderId = searchParams.get('orderId')
    const resCode = searchParams.get('status')
    const status = getStatusOnePayResCode(resCode)
    setMessage(message)
    setOrderId(orderId)
    setStatus(status)
    setNote(getMsgFromOnePayResCode(resCode))
    if (status === 'success' && orderId) {
      setLoading(true)
      refetchAPI(0, orderId)
    }
  }, [])

  const getMsgFromOnePayResCode = (resCode) => {
    resCode = parseInt(resCode)
    if (resCode < 1) return 'Giao dịch thất bại, vui lòng thử lại sau!'
    if (resCode === 1) return 'Giao dịch thành công.'
    if (resCode > 1 && resCode < 48) return 'Giao dịch đang chờ xử lý.'
  }

  const getStatusOnePayResCode = (resCode) => {
    resCode = parseInt(resCode)
    if (resCode < 1) return 'fail'
    if (resCode === 1) return 'success'
    if (resCode > 1 && resCode < 48) return 'pending'
    return 'fail'
  }

  return (
    <>
      <Loading loading={loading} />
      <PaymentCompletedForm
        status={status}
        message={message}
        orderId={orderId}
        email={email}
        linkFileCertificate={linkFileCertificate}
        note={note}
      />
    </>
  )
}
