import React from "react";
import Back from "../../../components/Button/Back";
import moment from "moment/moment";
import { useNavigate } from "react-router";

export default function InforDetail({ onBackClick = () => {}, item = {} }) {
  const {
    orderId,
    inceptionDate,
    expireDate,
    insuranceFullName,
    netAmount,
    nhaBaoHiem,
    typeVehicle,
  } = item;
  const navigate = useNavigate();
  return (
    <div
      className="
        w-full
        h-full
        bg-gray-100
        min-h-[calc(20rem)]
      "
    >
      <div
        className="
          w-full
          flex
          items-center
          justify-between
          px-2
          py-4
        "
      >
        {/*Nút back về lại list tìm kiếm*/}
        <Back onClick={onBackClick} />

        <button></button>
        <div
          className="
            
          "
        >
          <span
            className="
              text-base
              text-primary-color
            "
          >
            Chi tiết đơn hàng <span className="font-bold">#{orderId}</span>
          </span>
        </div>
        <div
          className="
            w-12
            "
        />
      </div>
      {/*Line*/}
      <div
        className="
          w-[calc(100%-4rem)]
          h-0.5
          bg-primary-color
          mx-auto
        "
      ></div>
      {/*Nội dung chi tiết của item được chọn*/}
      <div
        className="
          w-full
          h-full
          flex
          flex-col
          px-2
          py-4
          space-y-2

        "
      >
        {/*Các thông tin của item được chọn*/}
        {/*Tên*/}
        <div
          className="
            w-full
            h-auto
            flex
            items-center
            "
        >
          <div
            className="
              w-2
              h-2
              bg-primary-color   
            "
          />
          <span
            className="
              ml-2
              text-sm
              font-bold
              text-primary-color
              lg:text-base
            "
          >
            Tên:{" "}
            <span
              className="
                font-medium
                text-secondary-color
              "
            >
              {insuranceFullName}
            </span>
          </span>
        </div>
        {/*Mã hóa đơn*/}
        <div
          className="
            w-full
            h-auto
            flex
            items-center
            "
        >
          <div
            className="
              w-2
              h-2
              bg-primary-color   
            "
          />
          <span
            className="
              ml-2
              lg:text-base
              font-bold
              text-primary-color
              text-sm
            "
          >
            Mã hóa đơn:{" "}
            <span
              className="
                font-medium
                text-secondary-color
              "
            >
              {orderId}
            </span>
          </span>
        </div>
        {/*Ngày bắt đầu*/}
        <div
          className="
            w-full
            h-auto
            flex
            items-center
            "
        >
          <div
            className="
              w-2
              h-2
              bg-primary-color   
            "
          />
          <span
            className="
            ml-2
            lg:text-base
            font-bold
            text-primary-color
            text-sm
            "
          >
            Thời gian bắt đầu:{" "}
            <span
              className="
                font-medium
                text-secondary-color
              "
            >
              {moment(inceptionDate).format("HH:mm:ss DD/MM/YYYY ")}
            </span>
          </span>
        </div>
        {/*Ngày kết thúc*/}
        <div
          className="
            w-full
            h-auto
            flex
            items-center
            "
        >
          <div
            className="
              w-2
              h-2
              bg-primary-color   
            "
          />
          <span
            className="
            ml-2
            lg:text-base
            font-bold
            text-primary-color
            text-sm
            "
          >
            Thời gian hết hạn:{" "}
            <span
              className="
                font-medium
                text-secondary-color
              "
            >
              {moment(expireDate).format("HH:mm:ss DD/MM/YYYY ")}
            </span>
          </span>
        </div>
        {/*Số tiền*/}
        <div
          className="
            w-full
            h-auto
            flex
            items-center
            "
        >
          <div
            className="
              w-2
              h-2
              bg-primary-color   
            "
          />
          <span
            className="
            ml-2
            lg:text-base
            font-bold
            text-primary-color
            text-sm
            "
          >
            Số tiền:{" "}
            <span
              className="
                font-medium
                text-secondary-color
              "
            >
              {netAmount} VNĐ
            </span>
          </span>
        </div>
        {/*Nhà bảo hiểm*/}
        <div
          className="
            w-full
            flex
            items-center
            "
        >
          <div
            className="
              w-2
              h-2
              bg-primary-color   
            "
          />
          <span
            className="
              ml-2
              lg:text-base
              font-bold
              text-primary-color
              flex
              items-center
              text-sm
            "
          >
            Nhà bảo hiểm:{" "}
            <div
              className="
              w-8
              h-8
              ml-2
              shadow-md
            "
            >
              {<img src={`/images/${nhaBaoHiem?.toLowerCase()}.webp`} />}
            </div>
          </span>
        </div>
        {/*Loại xe*/}
        <div
          className="
            w-full
            h-auto
            flex
            items-center
            "
        >
          <div
            className="
              w-2
              h-2
              bg-primary-color   
            "
          />
          <span
            className="
              ml-2
              lg:text-base
              font-bold
              text-primary-color
              text-sm
            "
          >
            Loại xe:{" "}
            <span
              className="
                font-medium
                text-secondary-color
              "
            >
              {typeVehicle}
            </span>
          </span>
        </div>
        <button
          className="py-2 bg-primary-color text-white font-bold rounded-md"
          onClick={() =>
            typeVehicle === "Mô tô - xe máy"
              ? navigate(`/create/motorbike/${orderId}`)
              : navigate(`/create/car/${orderId}`)
          }
        >
          Tái tục
        </button>
      </div>
    </div>
  );
}
