import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "./index.css";
import CarInsuranceForm from "./pages/CarInsuranceForm";
import Constacts from "./pages/Constacts";
import FAQ from "./pages/FAQ";
import Home from "./pages/Home";
import MotorbikeInsuranceForm from "./pages/MotorbikeInsuranceForm";
import DialogModal from "./components/Modal/DialogModal";
import AlertBoxSection from "./components/Alert/AlertBoxSection";
import RequestClaim from "./pages/RequestClaim";
import PaymentCompleted from "./pages/Payment/PaymentCompleted";
import Download from "./pages/Download";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PageNotFound from "./pages/PageNotFound";
import PaymentPolicy from "./pages/PaymentPolicy";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ActivateAccount from "./pages/ActivateAccount";
import InterestsPolicy from "./pages/InterestsPolicy";
import ReimbursePolicy from "./pages/ReimbursePolicy";
import Policy from "./pages/Policy";
import Protector from "./components/Protector";
import AppLabel from "./components/Label/AppLabel";
import { accessTrade } from "./functions/checkCookie";
import SearchResult from "./pages/SearchResult";
export const act = new accessTrade();
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    let checker = act.checkCookieOnBrowser();
    if (checker) {
      act.writeCookieValues();
      console.log("accessTrade", act.getCookieValues());
      // act.clearCookieOnBrowser()
      // act.clearCookieValues()
      // console.log('accessTrade', act.getCookieValues())
    }
  }, []);
  // Reload page
  // useEffect(() => {
  //   console.log(window.performance.navigation)
  // }, [])

  return (
    <Protector>
      <AppLabel />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/search-result" element={<SearchResult />}></Route>
        <Route
          exact
          path="/create/request-claim"
          element={<RequestClaim />}
        ></Route>
        <Route path="/create/car">
          <Route path="" element={<CarInsuranceForm />} />
          <Route path=":orderId" element={<CarInsuranceForm />} />
        </Route>
        <Route path="/create/motorbike">
          <Route path="" element={<MotorbikeInsuranceForm />} />
          <Route path=":orderId" element={<MotorbikeInsuranceForm />} />
        </Route>
        <Route exact path="/constacts" element={<Constacts />}></Route>
        <Route exact path="/faq" element={<FAQ />}></Route>
        <Route
          exact
          path="/payment/completed"
          element={<PaymentCompleted />}
        ></Route>
        <Route exact path="/download" element={<Download></Download>}></Route>
        <Route
          exact
          path="/poclicy/privacy"
          element={<PrivacyPolicy />}
        ></Route>
        <Route
          exact
          path="/poclicy/payment"
          element={<PaymentPolicy />}
        ></Route>
        {/* <Route exact path="/payment/ck" element={<PaymentCK />}></Route> */}
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/register" element={<Register />}></Route>
        <Route exact path="/login-auto"></Route>
        <Route
          exact
          path="/activate-account"
          element={<ActivateAccount />}
        ></Route>
        <Route
          exact
          path="/poclicy/interests"
          element={<InterestsPolicy />}
        ></Route>
        <Route
          exact
          path="/poclicy/reimburse"
          element={<ReimbursePolicy />}
        ></Route>
        <Route exact path="/poclicy" element={<Policy />}></Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <AlertBoxSection />
      <DialogModal />
    </Protector>
  );
}

export default App;
