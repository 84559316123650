import React from 'react'
import './Style.css'
export default function ItemSearchResult({
  // Các props này được truyền từ component ListResults
  onClick = () => {},
  // Item này là một object chứa các thông tin của một item
  item = {},
}) {
  const {
    orderId,
    inceptionDate,
    expireDate,
    insuranceFullName,
    fileCertificate,
    netAmount,
    nhaBaoHiem,
    insuranceVehicle,
    typeVehicle,
    paymentType,
    prettyPaymentType,
  } = item
  return (
    <div
      className="
                w-full
                h-auto
                pl-2
        "
      onClick={onClick}
    >
      <div
        className="
                    w-full
                    h-36
                    lg:h-44
                    flex
                    bg-primary-color
                    relative
                    item-container
                    "
      >
        {/* Phần này là content hiện trên background */}
        <div
          className="     
                w-full
                h-full
                absolute
                -top-1
                -left-1
                z-10
                border-primary-color
                border-solid
                border-2
                item-content
                bg-white
                bg-opacity-95
                shadow-lg
                flex
                flex-col
                
            "
        >
          {/*Block header item*/}
          <div
            className="
              w-full
              flex
              flex-col
              lg:flex-row
              px-2
              py-2
            "
          >
            <div
              className="
                w-full
                lg:w-1/2
                flex
                lg:flex-col
                items-center
                lg:items-start
              "
            >
              <span
                className="
                    text-primary-color
                    font-bold
                    text-sm
                    mr-2
                    lg:mr-0
                  "
              >
                Tên:{' '}
              </span>
              <span
                className="
                 text-secondary-color
                 font-bold
              "
              >
                {insuranceFullName}
              </span>
            </div>
            <div
              className="
              w-full
              lg:w-1/2
              flex
              lg:flex-col
              items-center
              lg:items-end
            "
            >
              <span
                className="
                    text-primary-color
                    font-bold
                    text-sm
                    mr-2
                    lg:mr-0
                  "
              >
                Mã hóa đơn:
              </span>
              <span
                className="
                    text-secondary-color
                    font-bold
                "
              >
                {orderId}
              </span>
            </div>
          </div>
          {/*Divider*/}
          <div
            className="
                w-11/12
                h-0.5
                bg-primary-color
                my-1
                mx-auto
              "
          ></div>
          {/*Block body item*/}
          <div
            className="
              w-full
              flex
              px-2
              py-2
              flex-col
            "
          >
            <div
              className="
              w-full
              flex
              items-center
            "
            >
              {/*1 cái chấm nhỏ nhỏ*/}
              <div
                className="
                    w-2
                    h-2
                    rounded-full
                    bg-primary-color
                    mr-2
                    "
              ></div>
              {/*Nội dung*/}
              <div
                className="
                    w-full
                "
              >
                <span
                  className="
                    text-primary-color
                    text-sm
                    font-bold  
                  "
                >
                  Nhà bảo hiểm:
                </span>
                <span>{nhaBaoHiem}</span>
              </div>
            </div>
            <div
              className="
              w-full
              flex
              items-center
            "
            >
              {/*1 cái chấm nhỏ nhỏ*/}
              <div
                className="
                    w-2
                    h-2
                    rounded-full
                    bg-primary-color
                    mr-2
                    "
              ></div>
              {/*Nội dung*/}
              <div
                className="
                    w-full
                "
              >
                <span
                  className="
                    text-primary-color
                    text-sm
                    font-bold  
                  "
                >
                  Loại phương tiện:
                </span>
                <span>{typeVehicle}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
