import { useState, useEffect } from 'react'
import TitleBanner from '../Banner/TitleBanner';
import CommonInput from '../Input/CommonInput';
import PhoneInput from '../Input/PhoneInput';
import EmailInput from '../Input/EmailInput';
import { isValidEmail, isValidPhoneNumber } from '../../functions';

export default function ContactRegisterSection({ phoneNumber, email, error, setPhoneNumber, setEmail }) {




    return (
        <>
            <TitleBanner title="NGƯỜI NHẬN GIẤY BẢO HIỂM" />
            <div className="px-5">
                <div className='w-full md:w-1/3'>
                    <PhoneInput
                        field="Số điện thoại"
                        name="phoneNumber"
                        phoneNumber={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Điền số điện thoại. vd: 0909122122"
                        maxLength={12}
                        error={error.phoneNumber}
                        required
                    />
                </div>
                <div className='w-full md:w-1/3'>
                    <EmailInput
                        field="Email"
                        name="email"
                        email={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Điền địa chỉ email. vd: bhx@gmail.com"
                        maxLength={50}
                        error={error.email}
                        required
                    />
                </div>
                <div className='text-yellow-600 text-sm'>
                    <i className="fa-solid fa-triangle-exclamation"></i> Đảm bảo <b>Số điện thoại</b> và <b>email</b> được nhập chính xác.
                </div>
            </div>
        </>
    )
}
