import React from 'react'

export default function SearchSideBarLayout({ children }) {
  return (
    // Giao diện của phần sidebar trong trang SearchResult (Phần này sẽ chứa các list kết quả tìm kiếm,v.v...)
    // Ở chế độ full page nó sẽ có chiều rộng 1/4 trang (w-1/4)
    // Ở chế độ mobile nó sẽ có chiều rộng 100% (w-full)
    <div
      className="
            w-full
            min-h-fit
            lg:h-screen
            lg:w-[30%]
            border-primary-color
            lg:border-r-4
            lg:shadow-lg
            bg-primary-color
        "
    >
      {children}
    </div>
  )
}
