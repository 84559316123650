import { useState } from 'react'
import { Link } from 'react-router-dom'
import HomeLayout from '../layouts/HomeLayout' // requires a loader

import '../scss/button.scss'
import '../scss/switch.scss'

import 'animate.css/animate.min.css'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import CarBikeChoose from '../components/Modal/CarBikeChoose'
import SearchOrderBox from '../components/Nav/SearchOrderBox/SearchOrderBox'

export default function Home() {
  const [benefitShowing, setBenefitShowing] = useState('car')
  const [modalCarBikeShowing, setModalCarBikeShowing] = useState(false)

  return (
    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
      <HomeLayout>
        <CarBikeChoose
          show={modalCarBikeShowing}
          onClose={() => setModalCarBikeShowing(false)}
        />
        <div className="">
          <div
            className="pt-[10px] md:p-0 xl:p-12 2xl:p-24 relative bg-center bg-cover bg-left bg-no-repeat"
            style={{ backgroundImage: 'url("/images/bg-home.webp")' }}
          >
            {/* <div
              className="absolute h-full w-full top-0 right-0 md:flex md:items-center bg-white bg-opacity-0"> */}
            <AnimationOnScroll
              animateOnce={true}
              animateIn="animate__fadeInDown"
            >
              <div className=" bg-white bg-opacity-50 flex flex-wrap justify-between border-primary-color">
                <div
                  className="
                          lg:hidden
                          w-full

                  "
                >
                  <SearchOrderBox
                    customeMoreClassName="
                      items-center
                      border-secondary-color
                      shadow-2xl
                      py-2
                      px-8
                      "
                    showSearchInputOnly={true}
                  />
                </div>
              </div>
              <div className="px-8 py-4 bg-white bg-opacity-50 flex flex-wrap justify-between border-primary-color">
                <div>
                  {/* <div className="mb-4 text-[2rem] xs:text-[3rem] md:text-[4rem] 2xl:text-[5rem] font-black text-primary-color uppercase">
                      Bảo Hiểm Xe
                    </div> */}
                  <img
                    alt="Bảo Hiểm Xe"
                    src="/images/baohiemxe-text.png"
                    className="w-3/4 md:w-[60%]"
                  ></img>
                  <div className="text-gray-900 xs:text-[1.2rem] md:text-[1.5rem] 2xl:text-[2.5rem] 2xl:pr-[40vw] font-bold">
                    <div className="pb-5 md:pb-3">
                      Thủ tục{' '}
                      <span className="p-2 bg-primary-color text-white rounded-[4px]">
                        Nhanh gọn
                      </span>
                    </div>
                    <div className="pb-5 md:pb-3">
                      <span className="p-2 bg-primary-color text-white rounded-[4px]">
                        Tự động
                      </span>{' '}
                      Quy trình
                    </div>
                    <div className="pb-5 md:pb-3">
                      Luôn luôn{' '}
                      <span className="p-2 bg-primary-color text-white rounded-[4px]">
                        Đúng giá
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="font-black text-[30px] md:text-[70px] text-white uppercase">Bảo Hiểm Xe</div>
                  <div className="font-bold text-[22px] md:text-[55px] text-white uppercase">Bảo hiểm của thế kỉ 21.</div>
                  <div className="font-bold text-[16px] md:text-[30px] text-gray-100"><span className="uppercase">nhanh gọn - tự động - đúng giá.</span></div> */}
                <div className="text-[25px]">
                  <AnimationOnScroll
                    animateOnce={true}
                    animateIn="animate__fadeInLeft"
                  >
                    <div className="mt-4 flex flex-wrap h-full">
                      {/* <input name="email" type="email" value={email} onChange={handleEmailInputOnChange} className="h-[60px] w-full md:w-1/2 mr-5 mb-4 md:mb-0 border border-gray-500 rounded px-4 font-bold" placeholder="Nhập email của bạn vào đây (Không bắt buộc)"></input> */}
                      <button
                        className="bg-linear-secondary box-shadow-3d text-white h-[60px] w-[250px] md:h-[75px] md:w-[300px] xl:h-[100px] xl:w-[400px] text-[25px] font-bold  lg:text-[40px] hover:opacity-90 flex items-center justify-center rounded-[4px] uppercase
                        animation-bound-out
                      "
                        onClick={() => setModalCarBikeShowing(true)}
                      >
                        Mua ngay{' '}
                        <i
                          class="fa-solid fa-cart-shopping"
                          style={{
                            marginLeft: '10px',
                          }}
                        ></i>
                      </button>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </AnimationOnScroll>
            {/* </div> */}
          </div>

          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
            <div className="bg-white py-16">
              <div className="flex items-center justify-center text-[15px] md:text-[35px] uppercase text-gray-800">
                <b className="mx-4">
                  <i className="fa-solid fa-car-side text-primary-color"></i> Xe
                  Ô tô
                </b>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={() =>
                      setBenefitShowing(
                        benefitShowing === 'car' ? 'bike' : 'car',
                      )
                    }
                  />
                  <span className="slider round"></span>
                </label>
                <b className="mx-4">
                  Xe máy{' '}
                  <i className="fa-solid fa-person-biking text-primary-color"></i>
                </b>
              </div>

              <div id="quyen-loi">
                <div
                  id="quyen-loi-xe-o-to"
                  className={`font-medium text-[15px] md:text-[22px] ${
                    benefitShowing === 'bike'
                      ? 'hidden'
                      : 'animate__animated animate__bounceIn'
                  }`}
                >
                  <div className="flex justify-center mt-6">
                    <div className="h-16 w-16 md:h-20 md:w-20 bg-primary-color rounded-full mr-5 flex items-center justify-center text-[15px] md:text-[20px] border-[15px] border-secondary-color text-white">
                      <i className="fa-solid fa-coins"></i>
                    </div>
                    <div className="bg-primary-color text-white px-5 py-3 w-[60%] rounded-[4px] box-shadow-3d">
                      Bồi thường đến{' '}
                      <b className="text-secondary-color">
                        150 triệu đồng/người/vụ.
                      </b>{' '}
                      về thiệt hại về sức khỏe, tính mạng cho người bị nạn.
                      <br></br>
                      Bồi thường thiệt hại đến{' '}
                      <b className="text-secondary-color">
                        100 triệu đồng/người/vụ.
                      </b>{' '}
                      về tài sản cho người bị nạn.
                      <br></br>
                      {/* <Link to={"/"} className="underline text-secondary-color">Và nhiều quyền lợi bảo hiểm khác nữa, nhấp vào đây!</Link> */}
                    </div>
                  </div>

                  <div className="flex justify-center mt-6">
                    <div className="h-16 w-16 md:h-20 md:w-20 bg-primary-color rounded-full mr-5 flex items-center justify-center text-[15px] md:text-[20px] border-[15px] border-secondary-color text-white">
                      <i className="fa-solid fa-hand-holding-dollar"></i>
                    </div>
                    <div className="bg-primary-color text-white px-5 py-3 w-[60%] rounded-[4px] box-shadow-3d">
                      Quy trình báo bồi thường đơn giản. Chỉ cần báo hotline{' '}
                      <a
                        href="tel:1900688898"
                        className="text-[#c9c7ff ] font-bold underline"
                      >
                        1900 068 898
                      </a>
                      , nhân viên giám định sẽ liên hệ lại ngay. Nhanh chóng
                      duyệt chi phí bồi thường tổn thất.
                    </div>
                  </div>

                  <div className="flex justify-center mt-6">
                    <div className="h-16 w-16 md:h-20 md:w-20 bg-primary-color rounded-full mr-5 flex items-center justify-center text-[15px] md:text-[20px] border-[15px] border-secondary-color text-white">
                      <i className="fa-solid fa-bolt"></i>
                    </div>
                    <div className="bg-primary-color text-white px-5 py-3 w-[60%] rounded-[4px] box-shadow-3d">
                      Nhận giấy chứng nhận điện tử hợp pháp ngay sau khi đăng
                      ký, không cần mang thẻ giấy.
                      <br></br>
                      <a
                        href={'#insurance-sample'}
                        className="underline text-secondary-color"
                      >
                        Xem giấy chứng nhận mẫu
                      </a>
                    </div>
                  </div>

                  <div className="flex justify-center mt-6">
                    <div className="h-16 w-16 md:h-20 md:w-20 bg-primary-color rounded-full mr-5 flex items-center justify-center text-[15px] md:text-[20px] border-[15px] border-secondary-color text-white">
                      <i className="fa-solid fa-money-check-dollar"></i>
                    </div>
                    <div className="bg-primary-color text-white px-5 py-3 w-[60%] rounded-[4px] box-shadow-3d">
                      Cam kết luôn luôn đúng với{' '}
                      <span className="text-secondary-color">
                        giá niêm yết của nhà nước
                      </span>
                      . Thanh toán online đa dạng, nhanh chóng, thủ tục đơn giản
                      - đặc biệt độc đáo với chức năng tự động điền giấy tờ sử
                      dụng công nghệ trí tuệ nhân tạo AI.
                      <br></br>
                      {/* <Link to={'/'} className="underline text-secondary-color">Tìm hiểu các thanh toán được chấp nhận</Link> */}
                    </div>
                  </div>
                </div>
                <div
                  id="quyen-loi-xe-may"
                  className={`font-medium text-[15px] md:text-[22px] ${
                    benefitShowing === 'car'
                      ? 'hidden'
                      : 'animate__animated animate__bounceIn'
                  }`}
                >
                  <div className="flex justify-center mt-6">
                    <div className="h-16 w-16 md:h-20 md:w-20 bg-primary-color rounded-full mr-5 flex items-center justify-center text-[15px] md:text-[20px] border-[15px] border-secondary-color text-white">
                      <i className="fa-solid fa-coins"></i>
                    </div>
                    <div className="bg-primary-color text-white px-5 py-3 w-[60%] rounded-[4px] box-shadow-3d">
                      Bồi thường đến{' '}
                      <b className="text-secondary-color">
                        150 triệu đồng/người/vụ.
                      </b>{' '}
                      về thiệt hại về sức khỏe, tính mạng cho người bị nạn.
                      <br></br>
                      Bồi thường thiệt hại đến{' '}
                      <b className="text-secondary-color">
                        50 triệu đồng/người/vụ.
                      </b>{' '}
                      về tài sản cho người bị nạn.
                      <br></br>
                      {/* <Link to={"/"} className="underline text-secondary-color">Và nhiều quyền lợi bảo hiểm khác nữa, nhấp vào đây!</Link> */}
                    </div>
                  </div>

                  <div className="flex justify-center mt-6">
                    <div className="h-16 w-16 md:h-20 md:w-20 bg-primary-color rounded-full mr-5 flex items-center justify-center text-[15px] md:text-[20px] border-[15px] border-secondary-color text-white">
                      <i className="fa-solid fa-hand-holding-dollar"></i>
                    </div>
                    <div className="bg-primary-color text-white px-5 py-3 w-[60%] rounded-[4px] box-shadow-3d">
                      Quy trình báo bồi thường đơn giản. Chỉ cần báo hotline{' '}
                      <a
                        href="tel:1900688898"
                        className="text-[#c9c7ff ] font-bold underline"
                      >
                        1900 068 898
                      </a>
                      , nhân viên giám định sẽ liên hệ lại ngay. Nhanh chóng
                      duyệt chi phí bồi thường tổn thất.
                    </div>
                  </div>

                  <div className="flex justify-center mt-6">
                    <div className="h-16 w-16 md:h-20 md:w-20 bg-primary-color rounded-full mr-5 flex items-center justify-center text-[15px] md:text-[20px] border-[15px] border-secondary-color text-white">
                      <i className="fa-solid fa-bolt"></i>
                    </div>
                    <div className="bg-primary-color text-white px-5 py-3 w-[60%] rounded-[4px] box-shadow-3d">
                      Nhận giấy chứng nhận điện tử hợp pháp ngay sau khi đăng
                      ký, không cần mang thẻ giấy.
                      <br></br>
                      <a
                        href={'#insurance-sample'}
                        className="underline text-secondary-color"
                      >
                        Xem giấy chứng nhận mẫu
                      </a>
                    </div>
                  </div>

                  <div className="flex justify-center mt-6">
                    <div className="h-16 w-16 md:h-20 md:w-20 bg-primary-color rounded-full mr-5 flex items-center justify-center text-[15px] md:text-[20px] border-[15px] border-secondary-color text-white">
                      <i className="fa-solid fa-money-check-dollar"></i>
                    </div>
                    <div className="bg-primary-color text-white px-5 py-3 w-[60%] rounded-[4px] box-shadow-3d">
                      Cam kết luôn luôn đúng với{' '}
                      <span className="text-secondary-color">
                        giá niêm yết của nhà nước
                      </span>
                      . Thanh toán online đa dạng, nhanh chóng, thủ tục đơn giản
                      - đặc biệt độc đáo với chức năng tự động điền giấy tờ sử
                      dụng công nghệ trí tuệ nhân tạo AI.
                      <br></br>
                      {/* <Link to={'/'} className="underline text-secondary-color">Tìm hiểu các thanh toán được chấp nhận</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll
            animateOnce={true}
            animateIn="animate__fadeInRight"
          >
            <div
              className="text-gray-900 bg-cover bg-center"
              style={{ backgroundImage: 'url("/images/bg-home-2.jpg")' }}
            >
              <div className="flex items-center justify-center py-12">
                <div className="font-montserrat">
                  <div className="font-bold text-[1.4rem] lg:text-[2rem] text-center mb-8 uppercase">
                    Các đơn vị tham gia
                  </div>
                  <div className="flex flex-wrap justify-center">
                    {/* <div className="px-5 flex justify-center text-[1rem] lg:text-[1.5rem] text-center font-bold mb-5">
                      <img
                        alt="hdi"
                        className="bg-white w-16 h-16 lg:w-32 lg:h-32 mx-auto border-2"
                        src="/images/hdi.webp"
                      ></img>
                    </div> */}
                    <div className="px-5 flex justify-center text-[1rem] lg:text-[1.5rem] text-center font-bold mb-5">
                      <img
                        alt="onepay"
                        className="bg-white w-16 h-16 lg:w-32 lg:h-32 mx-auto border-2"
                        src="/images/onepay.webp"
                      ></img>
                    </div>
                    <div className="px-5 flex justify-center text-[1rem] lg:text-[1.5rem] text-center font-bold mb-5">
                      <img
                        alt="zalopay"
                        className="bg-white w-16 h-16 lg:w-32 lg:h-32 mx-auto border-2"
                        src="/images/zalopay.png"
                      ></img>
                    </div>
                    {/* <div className="px-5 flex justify-center text-[1rem] lg:text-[1.5rem] text-center font-bold mb-5">
                      <img alt="zalopay" className="bg-white w-16 h-16 lg:w-32 lg:h-32 mx-auto border-2" src="/images/vni.png"></img>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
            <div
              className="text-white bg-cover bg-center"
              style={{ backgroundImage: 'url("/images/bg-home-2.webp")' }}
            >
              <div className="flex items-center justify-center py-28 bg-[#003985] bg-opacity-80 bg-blur">
                <div className="font-montserrat">
                  <div className="font-bold text-[1.4rem] lg:text-[2rem] text-center mb-8">
                    LỢI ÍCH KHI SỬ DỤNG <span className="">BAOHIEMXE.AI</span>
                  </div>
                  <div className="flex justify-center mt-5">
                    <div className="mx-2 md:mx-5 text-[1rem] lg:text-[1.5rem] text-center font-bold">
                      <img
                        alt="payment-online-logo"
                        className="h-16 w-16 lg:h-32 lg:w-32 mx-auto mb-5"
                        src="/images/payment-online.webp"
                      ></img>
                      <div>Thanh toán online tiện lợi</div>
                    </div>
                    <div className="mx-2 md:mx-5 text-[1rem] lg:text-[1.5rem] text-center font-bold">
                      <img
                        alt="image-capture-logo"
                        className="h-16 w-16 lg:h-32 lg:w-32 mx-auto mb-5"
                        src="/images/image-capture.webp"
                      ></img>
                      <div>Tự động điền thông minh AI</div>
                    </div>
                    <div className="mx-2 md:mx-5 text-[1rem] lg:text-[1.5rem] text-center font-bold">
                      <img
                        alt="save-money-logo"
                        className="h-16 w-16 lg:h-32 lg:w-32 mx-auto mb-5"
                        src="/images/save-money.webp"
                      ></img>
                      <div>Đúng giá nhà nước niêm yết</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll
            animateOnce={true}
            animateIn="animate__fadeInRight"
          >
            <div
              id="insurance-sample"
              className="bg-gray-900 px-4 flex items-center"
            >
              <img className="max-w-[50%] pt-16" src="/images/demo.webp"></img>
              <div
                className="text-white pl-5 md:pl-10 xl:pl-16 text-[1.7rem] md:text-[3rem] xl:text-[4rem] hover:opacity-90 hover:cursor-pointer"
                onClick={() => setModalCarBikeShowing(true)}
              >
                Nhận{' '}
                <b className="text-secondary-color">giấy chứng nhận bảo hiểm</b>{' '}
                ngay sau khi đăng ký{' '}
                <i className="fa-solid fa-rocket hover:animate-bounce hover:cursor-pointer"></i>
              </div>
            </div>
          </AnimationOnScroll>
          {/* <div className="h-24 w-24 rounded-full bg-primary-color fixed bottom-24 right-5"></div> */}
        </div>
      </HomeLayout>
    </AnimationOnScroll>
  )
}
