import React from 'react'
import FAQBlock from './FAQBlock'
import { Link } from "react-router-dom";

export default function FAQSection() {
  return (
    <div className="mb-5">
      <div className="px-5">
        <div className="mb-5">
          <h2 className="text-white font-medium text-xl p-3 bg-primary-color rounded-[4px]">
            BỒI THƯỜNG BẢO HIỂM
          </h2>
        </div>

        <FAQBlock title={"Gọi ai - Làm gì khi gặp tai nạn"}>
          <ul className="list-disc pl-5">
            <li>Cấp cứu - 115</li>
            <li>Công an giao thông - 113</li>
            <li>Bảo hiểm HDI - 1900 068898</li>
          </ul>
        </FAQBlock>

        <FAQBlock
          title={"Trách nhiệm bồi thường của Công ty Bảo hiểm (CTBH)"}
        >
          <div>
            Khi xảy ra tai nạn thuộc trách nhiệm bảo hiểm, CTBH phải bồi
            thường cho người bị nạn (bên thứ 3) hoặc trả lại tiền bồi thường
            cho người mua bảo hiểm (chủ xe cơ giới) trong trường hợp chủ xe
            đã ứng tiền bồi thường cho người bị nạn.
          </div>
        </FAQBlock>
        <FAQBlock
          title={
            "Trách nhiệm thuộc phạm vi bồi thường (trách nhiệm bảo hiểm) nghĩa là gì?"
          }
        >
          <div>
            Là lỗi do chính chủ xe cơ giới gây ra và được xác nhận bằng Biên
            bản Công an.
          </div>
        </FAQBlock>
        <FAQBlock title={"Thời hạn chi trả?"}>
          <div>
            Đối với thiệt hại về người và sức khỏe, CTBH phảm tạm ứng trong
            vòng 3 ngày kể từ ngày nhận thông báo bồi thường của chủ xe,
            người bị nạn.
          </div>
          <div>Cụ thể:</div>
          <div>A. Đã xác định lỗi (có Biên bản Công An):</div>
          <ul className="list-disc pl-5">
            <li>Tử vong đền 70% mức bồi thường/người/vụ</li>
            <li>Tổn thương bộ phận đền 50% mức bồi thường bảo hiểm</li>
          </ul>
          <div>
            Vd: Anh X đụng chết Chị Y, lỗi do anh X gây ra và công an đã xác
            nhận bằng biên bản, Công ty Bảo Hiểm sẽ phải tạm ứng 105 triệu
            (70% x 150 triệu) cho người nhà Chị Y.
          </div>
          <div>B. Chưa xác định được lỗi:</div>
          <ul className="list-disc pl-5">
            <li>Tử vong đền 30% mức trách nhiệm/người/vụ</li>
            <li>
              Tổn thương bộ phận đền 10% mức trách nhiệm bảo hiểm/người/vụ
            </li>
          </ul>
          <div>
            Vd: Anh X đụng Chị Y, làm cho Chị Y bị liệt hoàn toàn nửa người,
            mặc dù chưa xác định được vụ tai nạn thuộc phạm vi bồi thường
            thiệt hại, theo quy định Công Ty Bảo Hiểm sẽ phải tạm ứng 12,75
            triệu (10% x 85% x 150 triệu) cho Chị Y.
          </div>
          <p className="text-black">
            <b className="text-red-700">Lưu ý:</b> Tỷ lệ 85% được quy định
            theo Phụ lục 01 Nghị định 03/2021/NĐ-CP.
          </p>
        </FAQBlock>
        <FAQBlock title={"Mức bồi thường bảo hiểm?"}>
          <div>Quy định theo Phụ lục 01 của Nghị định 03/2021/NĐ-CP.</div>
        </FAQBlock>
        <FAQBlock title={"Hồ sơ bồi thường bảo hiểm"}>
          <div>Gồm 5 mục cơ bản:</div>
          <ul className="list-disc pl-5">
            <li>Tài liệu liên quan đến xe và người lái xe</li>
            <li>Tài liệu chứng minh thương tật</li>
            <li>Tài liệu chứng minh thiệt hại với tài sản</li>
            <li>Biên bản giám địnhb của CTBH</li>
          </ul>
          <div>
            <b className="text-red-700">Chi tiết:</b> Tham khảo Điều 15 của
            Nghị định 03/2021/NĐ-CP.
          </div>
        </FAQBlock>
        <FAQBlock title={"Thời hạn yêu cầu thanh toán bồi thường"}>
          <div>
            Tối đa 1 năm kể từ ngày xảy ra tai nạn hoặc chậm trễ do nguyên
            nhân khách quan và bất khả kháng theo Luật quy định.
          </div>
          <div>Chi tiết: Điều 16 của Nghị định 03/2021/NĐ-CP.</div>
          <div className="text-[15px]">Xem thêm:</div>
          <div className="text-blue-800">
            Nghị định 03/2021/NĐ-CP - Phụ lục 01
          </div>
          <div className="text-blue-800">
            Thông tư 04/2021/TT-BTC - Phụ lục 01
          </div>
        </FAQBlock>
        <div className="bg-slate-200 mb-5">
          <h2 className="text-white font-medium text-xl p-3 bg-primary-color rounded-[4px]">
            NHỮNG VẤN ĐỀ KHÁC
          </h2>
        </div>
        <FAQBlock
          title={
            "Giấy chứng nhận (GCN) điện tử Công an không chịu thì sao?"
          }
        >
          <ul className="list-disc pl-5">
            <li>
              Giấy chứng nhận điện tử có hiệu lực theo{" "}
              <b className="text-blue-800">Nghị định 03/2021/NĐ-CP</b> và{" "}
              <b className="text-blue-800">Thông tư 04/2121/TT-BTC</b> ban
              hành cùng ngày 15/01/2021.
            </li>
            <li>
              Hãy cố gắng thuyết phục đến cùng, đừng nộp tiền oan nhé!
            </li>
          </ul>
        </FAQBlock>
        <FAQBlock title={"Mức phạt cho xe không có bảo hiểm bắt buộc"}>
          <ul className="list-disc pl-5">
            <li>
              Xe máy và xe tương tự sẽ bị phạt từ 100.000 - 200.000 đồng.
            </li>
            <li>
              Xe ô tô và xe tương tự sẽ bị phạt từ 400.000 - 600.000 đồng.
            </li>
          </ul>
          <div>
            <i>Theo Nghị định 100/2019/NĐ-CP.</i>
          </div>
        </FAQBlock>
        <FAQBlock title={"Mức phí bảo hiểm bắt buộc có khác nhau không?"}>
          <div>
            Bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới chỉ có
            một mức giá niêm yết theo
            <b className="text-blue-800">
              {" "}
              Phụ lục 01 Thông tu 04/2021/TT-BTC
            </b>
            . Vì vậy, sẽ không có chuyện mức phí khác nhau giữa các công ty
            bảo hiểm.
          </div>
        </FAQBlock>
      </div>
    </div>
  )
}
