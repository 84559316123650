import {useEffect} from "react";

export default function Download() {
  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.giong";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = "https://apps.apple.com/us/app/bagang/id1568382221";
    } 
  }, []);
  return (
    <div className="h-screen w-full fixed top-0 left-0 flex items-center justify-center">
      <div className="text-center">
        <img alt="logo" src="/images/logo.webp" className="rounded-full mx-auto"></img>
        <div className="text-[20px] text-[30px] font-bold">Tải ứng dụng Lục Bảo ngay</div>
        <div className="flex flex-wrap justify-center mt-5">
          <div className="w-full md:w-fit md:mr-5">
            <a href="https://apps.apple.com/us/app/bagang/id1568382221" className="hover:opacity-80">
              <img
                alt="badge-app-store"
                className="w-40 mx-auto mb-2"
                src="/images/badge-app-store.webp"
              ></img>
            </a>
          </div>
          <div className="w-full md:w-fit md:ml-5">
            <a href="https://play.google.com/store/apps/details?id=com.giong" className="hover:opacity-80">
              <img
                alt="badge-google-play"
                className="w-40 mx-auto mb-2"
                src="/images/badge-google-play.webp"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
