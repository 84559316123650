import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import SearchResultMainLayout from "./SearchResult/SearchResultMainLayout";
import ViewDetailLayout from "./SearchResult/ViewDetail/ViewDetailLayout";
import SearchSideBarLayout from "./SearchResult/SearchSideBar/SearchSideBarLayout";
import LittleHeader from "./SearchResult/LitlleHeader/LittleHeader";
import ListResults from "./SearchResult/ListResults/ListResults";
import Searcher from "./SearchResult/Searcher/Searcher";
import Axios from "../functions/axios";

export default function SearchResult() {
  const [listResult, setListResult] = useState([]); // Danh sách kết quả tìm kiếm
  const [totalSearchResult, setTotalSearchResult] = useState(0); // Tổng số kết quả tìm kiếm
  const [selectedItem, setSelectedItem] = useState(null); // Item được chọn để hiển thị chi tiết

  // Dùng hook này để lấy keyword từ url -> hook này sẽ trả về một object chứa các thông tin của url
  const location = useLocation();
  // Use effect này để lấy keyword từ url -> từ đó tìm kiếm kết quả tương ứng
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search); // location.search là phần query của url
    const key = searchParams.get("keyword"); // Lấy keyword từ query thông qua key 'keyword'
    // Perform any necessary actions with the keyword parameter
    // Verify xem key có phải là số hết hay không nếu không phải số hết thì xóa chữ đi giữ lại số
    // if (key) {
    //   const regex = new RegExp('[^0-9]')
    //   const newKey = key.replace(regex, '')
    //   searchParams.set('keyword', newKey)
    //   window.history.replaceState(
    //     {},
    //     '',
    //     `${location.pathname}?${searchParams}`,
    //   )
    //   // Nếu key không phải là số hết thì sẽ redirect tới trang kết quả tìm kiếm với keyword mới
    //   if (newKey !== key) {
    //     window.location.href = `${location.pathname}?${searchParams}`
    //   } else {
    //     // Nếu key là số hết thì sẽ gọi api tìm kiếm
    //     // Parse key thành int
    //     const keyInt = parseInt(newKey)
    //     // Gọi api tìm kiếm
    //     console.log(keyInt)
    //     let url = `/api/Insurance/GetGCN?searching=0${keyInt}`
    //     Axios.get(url)
    //       .then((res) => {
    //         console.log('res', res.data)
    //         //Status 200
    //         if (res.status === 200) {
    //           setListResult(res.data.data)
    //           setTotalSearchResult(res.data.totalPage)
    //         }
    //       })
    //       .catch((err) => {})
    //   }
    // }
    if (key) {
      let url = `/api/Insurance/GetGCN?searching=${key}`;
      Axios.get(url)
        .then((res) => {
          //Status 200
          if (res.status === 200) {
            setListResult(res.data.data);
            setTotalSearchResult(res.data.totalPage);
          }
        })
        .catch((err) => {});
    }
  }, [location]);

  return (
    <SearchResultMainLayout>
      {/*Giao diện sidebar*/}
      <SearchSideBarLayout>
        {/*Giao diện sidebar này có 3 phần:
        1. Phần header mini
        2. Phần chứa ô search
        3. Phần list kết quả tìm kiếm
        4. Phần list các filter để lọc kết quả tìm kiếm (optional)
        */}
        {/*Phần header mini*/}
        <LittleHeader />
        {/*Phần chứa ô search (Searcher)*/}
        <Searcher totalSearchResult={totalSearchResult} />
        {/*Giao diện list kết quả tìm kiếm*/}
        <ListResults
          listResult={listResult}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </SearchSideBarLayout>
      {/*Giao diện chi tiết kết quả của từng item*/}
      <ViewDetailLayout
        selectedItem={selectedItem}
        listResult={listResult}
      ></ViewDetailLayout>
    </SearchResultMainLayout>
  );
}
