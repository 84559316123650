import React from "react";

export default function AppLabel() {
  if (process.env.REACT_APP_API_URL === "https://dev-api.bagang.ai")
    return (
      <button className="fixed bottom-5 right-5 bg-black z-[10000] h-16 w-16 bg-opacity-80 text-red-500 font-bold text-[2.5rem]">
        <i
          className="fa-brands fa-dev text-yellow-600"
          onClick={() => alert("Đây là bản dev")}
        ></i>
      </button>
    );
  return null;
}
