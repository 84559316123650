import React from "react";

export default function TitleBanner({ title }) {
  return (
    <div className="">
      <div
        className="w-full p-2 px-4 flex items-center"
        // style={{ backgroundImage: "url('/images/bg-discover.webp')" }}
      >
        <h2 className="font-bold text-primary-color xs:text-[20px] md:text-[22px] font-bold text-primary-color uppercase">
          {title}
        </h2>
      </div>
    </div>
  );
}
