import { useState, useEffect } from "react";
import AuthLayout from "../layouts/AuthLayout";
import AuthInput from "../components/Input/AuthInput";
import Loading from "../components/Loading/Loading";
import Axios from "../functions/axios";

export default function ActivateAccount() {
  const queryParams = new URLSearchParams(window.location.search);
  const [error, setError] = useState({ password: "", confirmPassword: "" });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const email = queryParams.get('email');
  const token = queryParams.get('token');
  // const { decodedToken, isExpired } = useJwt(token);


  useEffect(() => {
  }, [])

  const validateInput = () => {
    let isValid = true;
    let error = { password: "", confirmPassword: "" };

    if (password === "") {
      error.password = "Vui lòng nhập mật khẩu";
      isValid = false;
    }

    if (confirmPassword === "") {
      error.confirmPassword = "Vui lòng nhập lại mật khẩu";
      isValid = false;
    }

    if (password !== confirmPassword) {
      error.confirmPassword = "Mật khẩu không khớp";
      isValid = false;
    }

    setError(error);
    return isValid;  
  };

  const handleSubmitRegister = (e) => {
    e.preventDefault();
    if (!validateInput()) return;
    setLoading(true);
    Axios.post('/api/UserRole/ConfirmRegisterUser', {email, password, token}).then(() => {
      alert('Đăng ký thành công');
      window.location.href = '/login';
    })
    .catch(() => alert('Có lỗi xảy ra vui lòng thử lại sau'))
    .finally(() => setLoading(false))
  };

  useEffect(() => { }, []);
  return (
    <AuthLayout>
      <Loading loading={loading}/>
      <form className="p-5 text-center md:w-1/2 mx-auto">
        <h1 className="font-bold text-[25px] xs:text-[35px]">Kích hoạt tài khoản</h1>
        <div className="font-bold mb-5 text-stone-600">
          Tạo mật khẩu cho email <b>{email}</b>
        </div>
        <div className="mb-6">
          <AuthInput
            id="password"
            name="password"
            type="password"
            value={password}
            placeholder="Nhập mật khẩu"
            onChange={(e) => setPassword(e.target.value)}
            maxLength={50}
            error={error}
            required
          />
        </div>

        <div className="mb-6">
          <AuthInput
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            placeholder="Nhập lại mật khẩu"
            onChange={(e) => setConfirmPassword(e.target.value)}
            maxLength={50}
            error={error}
            required
          />
        </div>
        <div className="text-right">
          <button type="submit" onClick={handleSubmitRegister}> 
            <div className="flex items-center">
              Tiếp tục{" "}
              <i className="fa-solid fa-chevron-right ml-2 text-[12px]"></i>
            </div>
          </button>
        </div>
      </form>
    </AuthLayout>
  );
}
