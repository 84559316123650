import React, { useEffect, useState } from 'react';

const ZaloShareButton = ({
  dataHref = '',
}) => {
  const [zaloSDKLoaded, setZaloSDKLoaded] = useState(false);
  const [renderUrl, setRenderUrl] = useState('');
  useEffect(() => {
    const loadZaloSDK = () => {
      if (!window.ZaloSocialSDK) {
        const script = document.createElement('script');
        script.src = 'https://sp.zalo.me/plugins/sdk.js';
        script.async = true;
        script.onload = () => setZaloSDKLoaded(true);
        document.body.appendChild(script);
      } else {
        setZaloSDKLoaded(true);
      }
    };

    loadZaloSDK();
  }, []);

  useEffect(() => {
    if (zaloSDKLoaded) {
      const href = window.location.href; // or set the desired URL you want to share
      const zaloShareButton = document.querySelector('.zalo-share-button');

      if (zaloShareButton) {
        zaloShareButton.setAttribute('data-href', href);
      }

      if (window.ZaloSocialSDK && typeof window.ZaloSocialSDK.reload === 'function') {
        window.ZaloSocialSDK.reload();
      }
    }
  }, [zaloSDKLoaded]);
  console.log("zaloSDKLoaded", zaloSDKLoaded, dataHref)

  useEffect(() => {
    if (zaloSDKLoaded) {
      setRenderUrl(dataHref);
    }
  }, [dataHref]);
  return (
    <div>
      {zaloSDKLoaded && (
        <div
          className="zalo-share-button"
          data-href="https://dev-hyperservices.hdinsurance.com.vn/f/c8ee5c4718ca47efb90a2fa52ba21cc5"
          data-oaid="3518713603628693068"
          data-layout="1"
          data-color="white"
          data-share-type="4"
          data-customize="false"
        ></div>
      )}
    </div>
  );
};

export default ZaloShareButton;
