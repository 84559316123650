import React from 'react'
import SearchOrderBox from '../../../components/Nav/SearchOrderBox/SearchOrderBox'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'

export default function Searcher({ totalSearchResult = 0 }) {
  const [keyword, setKeyword] = useState('')
  // Dùng hook này để lấy keyword từ url -> hook này sẽ trả về một object chứa các thông tin của url
  const location = useLocation()
  // Use effect này để lấy keyword từ url -> từ đó tìm kiếm kết quả tương ứng
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search) // location.search là phần query của url
    const key = searchParams.get('keyword') // Lấy keyword từ query thông qua key 'keyword'
    // Perform any necessary actions with the keyword parameter
    setKeyword(key)
    console.log(key)
  }, [location])
  return (
    <div
      className="
            w-full
            h-auto
            "
    >
      {/* Đầu tiên là ô input tìm kiếm */}
      <div
        className="
                w-full
                h-auto
                bg-primary-color
                flex
                flex-col
                items-center
                justify-center
                px-2
                py-4
        "
      >
        <SearchOrderBox
          customeMoreClassName="
                items-center
            "
          value={keyword}
        />
      </div>

      {/* Tiếp theo là 1 cái title */}
      <div
        className="
                w-full
                h-auto
                flex
                flex-col
                px-2
                py-4
                text-center
                bg-[#F5F5F5]
      "
      >
        <span
          className="
                font-bold
                text-xl
                text-primary-color
                "
        >
          Kết quả tìm kiếm
        </span>
        <span
          className="
                font-medium
                text-sm
                text-primary-color
                "
        >
          Có {totalSearchResult} kết quả được tìm thấy
        </span>
      </div>
    </div>
  )
}
