import "../../scss/loading.scss";

export default function Loading({ loading, opacity = 1 }) {
  if (!loading) return '';

  return (
    <>
      <div style={{ opacity: opacity }} className="fixed top-0 left-0 right-0 bottom-0 z-[100000] flex items-center justify-center h-screen w-full bg-black bg-opacity-30">
        <img
          alt="loading"
          className="rounded-full animate-bounce h-12 w-12"
          src="/images/loading-logo.webp"
        ></img>
        <img
          alt="loading"
          className="rounded-full animate-bounce animation-delay-200 h-12 w-12"
          src="/images/loading-logo.webp"
        ></img>
        <img
          alt="loading"
          className="rounded-full animate-bounce animation-delay-400 h-12 w-12"
          src="/images/loading-logo.webp"
        ></img>
      </div>
    </>
  );
}