import { useState, useEffect } from "react";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { isValidEmail } from '../../functions';

export default function EmailInput({
    name,
    field,
    email,
    onChange,
    placeholder,
    required,
    maxLength = 50,
    error = "",
    type = "text",
    note,
}) {
    const [loading, setLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(null);


    useEffect(() => {
        if (email.length > 3) {
            setValidEmail(isValidEmail(email));
        } else {
            setValidEmail(null);
        }
    }, [email]);
    return (
        <div className="mb-4">
            <label className="font-bold text-[20px]" htmlFor={name}>
                {field}
            </label>
            {required && <span className="text-red-600 text-[20px]">*</span>}
            {note && <span className="text-gray-400 ml-1">(không bắt buộc)</span>}
            <div className="relative mt-2">
                <input
                    id={name}
                    value={email}
                    onChange={onChange}
                    type={type}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    className="w-full rounded-[4px] px-2 border-b-2 border-[#004caf] placeholder-shown:border-gray-500 focus:outline-none h-[40px]  text-lg  "
                ></input>
                <div className="absolute right-3 top-[50%] translate-y-[-50%]">
                    {validEmail === true ? (
                        <>
                            {loading ? (
                                <LoadingSpinner loading />
                            ) : (
                                <i className="fa-solid fa-circle-check text-secondary-color"></i>
                            )}
                        </>
                    ) : (
                        <>
                            {validEmail === false ? (
                                <i className="fa-solid fa-circle-xmark text-red-600"></i>
                            ) : ''}
                        </>
                    )}
                </div>
            </div>
            {/* {validEmail === true &&
                <div className="mt-1">
                    <div className="text-yellow-600 text-sm"><b>Vui lòng kiểm tra thông tin chính xác</b>, <b>hợp đồng bảo hiểm</b> sẽ được gửi cho bạn thông qua <b>email</b> này <b>{email}</b></div>
                </div>} */}
            {error && <p className="text-red-600">{error}</p>}
        </div>
    );
}
