import { useState } from "react";
import CommonModal from "../Modal/CommonModal";

export default function FeedbackButton() {
    return;
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [rating, setRating] = useState(0);

    console.log(rating);
    const handleRatingOnChange = (e) => {
        setRating(Number(e.target.value));
        setShowFeedbackModal(false);
    };

    return (
        <>
            <button type="button" onClick={() => setShowFeedbackModal(true)} name="feedback" aria-label="feedback" className="w-[125px] h-[35px] fixed bottom-[30%] text-white rotate-[90deg] -right-[45px] bg-linear-primary rounded-b-[4px] hover:opacity-80">
                Feedback
            </button>
            <CommonModal show={showFeedbackModal} onClose={() => setShowFeedbackModal(false)}>
                <div className="rating rating-lg">
                    <input type="radio" name="rating-8" className="mask mask-star-2 bg-blue-500" value={1} checked={rating === 1} onClick={handleRatingOnChange}/>
                    <input type="radio" name="rating-8" className="mask mask-star-2 bg-blue-500" value={2} checked={rating === 2} onClick={handleRatingOnChange}/>
                    <input type="radio" name="rating-8" className="mask mask-star-2 bg-blue-500" defaultChecked value={3} checked={rating === 3} onClick={handleRatingOnChange}/>
                    <input type="radio" name="rating-8" className="mask mask-star-2 bg-blue-500" value={4} checked={rating === 4} onClick={handleRatingOnChange}/>
                    <input type="radio" name="rating-8" className="mask mask-star-2 bg-blue-500" value={5} checked={rating === 5} onClick={handleRatingOnChange}/>
                </div>
            </CommonModal>
        </>
    );
}
