import React, { useEffect } from 'react'
import CommonInput from '../../Input/CommonInput'
import SearchBtn from '../../Button/Search'
import { useState } from 'react'

export default function SearchOrderBox({
  value = '',
  customeMoreClassName = '',
  showSearchInputOnly = false,
}) {
  useEffect(() => {
    setText(value)
  }, [value])
  const [text, setText] = useState('')
  const [showBtn, setShowBtn] = useState(false)
  useEffect(() => {
    if (text.length > 0) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }, [text])
  // Dêtect enter key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        window.location.href = `/search-result?keyword=${text}`
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [text])
  return (
    <div
      href="tel:1900068898"
      className={`placeholder:w-full lg:w-max lg:border-none  h-min flex flex-col items-start justify-center
        bg-[#003985]
        lg:bg-transparent
        lg:py-4 
        lg:px-4
        ${customeMoreClassName || 'px-4 py-4'}
        `}
        
    >
      <span
        className="
        font-bold lg:text-xs uppercase text-white mb-2 text-center w-full lg:w-auto
        text-lg

        "
        style={{
          display: showSearchInputOnly ? 'none' : 'block',
        }}
      >
        Tra cứu bảo hiểm
      </span>
      <div
        className="
        flex 
        items-center 
        justify-between
        lg:flex-row
        flex-col
        w-full
        h-fit
        "
      >
        <div
          className="h-full flex flex-col justify-center items-center
          lg:w-[20rem]
          w-full
        "
        >
          <CommonInput
            placeholder={'SĐT 0908xxxxxx / Biển số 59D1 xxx xx'}
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
        </div>
        <div
          className={`
           
            
            ${
              showBtn ? 'flex' : 'hidden'
            } flex-col justify-center items-center`}
        >
          <SearchBtn
            iconOnly={true}
            onClick={() => {
              window.location.href = `/search-result?keyword=${text}`
            }}
          />
        </div>
      </div>
    </div>
  )
}
