import React from "react";
import Footer from "../components/Footer/Footer";
import HomeNavBar from "../components/Nav/HomeNavBar";

export default function MainLayout({ children }) {
  return (
    <>
      <HomeNavBar />
      <main >{children}</main>
      {/* <a href="tel:1900068898" type="button" aria-label="call-fixed" className="fixed h-16 w-16 rounded-full bg-linear-secondary bottom-5 left-2 z-20 flex items-center justify-center">
        <i className="fa-solid fa-phone text-[25px] text-white animation-shake"></i>
      </a> */}
      <Footer />
    </>
  );
}
