import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import PaymentOnePay from "./PaymentOnePay";
import PaymentZalo from "./PaymentZalo";

export default function PaymentCompleted() {
  const [searchParams, setSearchParams] = useSearchParams("");
  const [paymentMethod, setPaymentMethod] = useState(0);


  useEffect(() => {
    // see all payment methods in the docs
    const paymentMethod = searchParams.get("paymentMethod");
    // vnpay
    if (paymentMethod === "10") {
      setPaymentMethod(10);
    } 
    if (paymentMethod === "2") {
      setPaymentMethod(2);
    }
    if (paymentMethod === "12") {
      setPaymentMethod(12);
    }

  }, [])

  // if (paymentMethod !== 10 ) {

  return (
    <MainLayout>
      {paymentMethod === 10 && <PaymentOnePay/>}
      {/* {paymentMethod === 2 && <PaymentMomo/>} */}
      {paymentMethod === 12 && <PaymentZalo/>}
    </MainLayout>
  );
}
