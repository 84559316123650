import { useState } from "react";
import PaymentOption from "./PaymentOption";
// import { PaymentOptionCK } from "./PaymentOption";

export default function PaymentMethodSection({
  paymentMethod,
  handlePaymentMethod,
}) {
  return (
    <div>
      <div className="mx-5 mb-5 rounded-[4px]">
        <div className="border-2 mb-2 rounded-[4px]">
          <PaymentOption
            onClick={() => handlePaymentMethod(10)}
            title={"Thanh toán qua ATM/Visa/MasterCard/JCB"}
            value={10}
            currentValue={paymentMethod}
            imgSrc={"/images/onepay.webp"}
          />
        </div>

        <div className="border-2 mb-2 rounded-[4px]">
          <PaymentOption
            onClick={() => handlePaymentMethod(12)}
            title={"Thanh toán qua ví điện tử ZaloPay"}
            value={12}
            currentValue={paymentMethod}
            imgSrc={"/images/zalopay.png"}
          />
        </div>

      </div>
    </div>
  );
}
