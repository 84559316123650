import './NavbarStyles.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaTimes, FaBars } from 'react-icons/fa'
import CommonInput from '../Input/CommonInput'
import SearchBtn from '../Button/Search'
import SearchOrderBox from './SearchOrderBox/SearchOrderBox'
import ZaloShareButton from '../ZaloShare/ZaloShareButton'


const Navbar = ({ isHome = false }) => {
  isHome = false
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
  const [isScrolledFarFromTop, setIsScrolledFarFromTop] = useState(false)

  // Khi lướt xuống khỏi top thì sẽ đổi màu navbar. Chức năng này tạm thời không xài nữa
  // useEffect(() => {
  //     if (isHome === false) return;
  //     const handleScroll = () => {
  //         const isScrolledFarFromTop = window.scrollY > 50;
  //         setIsScrolledFarFromTop(isScrolledFarFromTop);
  //     };
  //     window.addEventListener("scroll", handleScroll);
  //     return () => window.removeEventListener("scroll", handleScroll);
  // }, [])

  return (
    <>
      <nav
        className={`h-[100px] w-full flex items-center px-5 md:px-16 ${
          isScrolledFarFromTop || isHome === false || click
            ? 'bg-primary-color'
            : 'bg-transparent'
        }`}
      >
        <div className="navbar-right flex items-center">
          <Link to={'/'}>
            <img
              alt="banner"
              src="/images/banner.webp"
              className="w-[220px]"
            ></img>
          </Link>
        </div>
        <div
          className={click ? 'navbar-content active z-30' : 'navbar-content'}
        >
          <div
            className={`w-full h-screen lg:h-fit lg:rounded-[10px] bg-black bg-opacity-70 bg-blur lg:bg-transparent`}
            onClick={handleClick}
          >
            <div
              className="lg:flex items-center justify-around p-5 lg:p-0 lg:bg-transparent text-xl"
              onClick={(e) => e.stopPropagation()}
            >
              <Link
                to={'/'}
                className="lg:hidden w-full lg:w-max lg:mx-2 lg:border-none py-2 lg:py-0 h-min hover:underline hover:text-white flex items-center justify-center border-b-2 border-white text-white"
              >
                <div className="mx-4 font-bold text-[18px] uppercase">
                  Trang Chủ
                </div>
              </Link>

              <Link
                to={'/create/car'}
                className="lg:hidden w-full lg:w-max lg:mx-2 lg:border-none py-2 lg:py-0 h-min hover:underline hover:text-white flex items-center justify-center border-b-2 border-white text-white"
              >
                <div className="mx-4 font-bold text-[18px] uppercase">
                  Bảo hiểm Xe Ô Tô
                </div>
              </Link>
              <Link
                to={'/create/motorbike'}
                className="lg:hidden w-full lg:w-max lg:mx-2 lg:border-none py-2 lg:py-0 h-min hover:underline hover:text-white flex items-center justify-center border-b-2 border-white text-white"
              >
                <div className="mx-4 font-bold text-[18px] uppercase">
                  Bảo hiểm Xe Máy
                </div>
              </Link>
              <Link
                to={'/faq'}
                className="lg:hidden w-full lg:w-max lg:mx-2 lg:border-none py-2 lg:py-0 h-min hover:underline hover:text-white flex items-center justify-center border-b-2 border-white text-white"
              >
                <div className="mx-4 font-bold text-[18px] uppercase">
                  CÂU HỎI THƯỜNG GẶP
                </div>
              </Link>
              {/*Chỗ để search hóa đơn nằm ở đây*/}
              <SearchOrderBox />
              {/* <Link
                                to={"/login"}
                                className="whitespace-nowrap flex items-center justify-center my-3 p-2 bg-white text-white bg-linear-primary lg:ml-4 rounded-[10px] hover:opacity-90 flex items-center justify-center"
                            >
                                <div className="mx-2 font-bold text-[18px] uppercase">
                                    ĐĂNG NHẬP
                                </div>
                            </Link> */}
            </div>
          </div>
        </div>

        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={20} style={{ color: '#fff' }} />
          ) : (
            <FaBars size={20} style={{ color: '#fff' }} />
          )}
        </div>
      </nav>
      {/* {!isHome ? <div className="h-[85px]"></div> : null} */}
    </>
  )
}

export default Navbar
