import { useState } from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import AuthInput from "../components/Input/AuthInput";
import Axios from "../functions/axios";
import Loading from "../components/Loading/Loading";
import { isValidEmail } from "../functions";

export default function Login() {
    const [error, setError] = useState({ email: '', password: '' });
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const validateInput = () => {
        let isValid = true;
        let error = { email: '', password: '' };

        if (email === '') {
            error.email = 'Vui lòng nhập email';
            isValid = false;
        } else if (!isValidEmail(email)) {
            error.email = 'Email không hợp lệ';
            isValid = false;
        };

        if (password === '') {
            error.password = 'Vui lòng nhập mật khẩu';
            isValid = false;
        };

        setError(error);
        return isValid;
    };

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        if (!validateInput()) return;
        setLoading(true);
        Axios.post('/api/UserRole/Login', { username: email, password })
        .then(() => alert('Đăng nhập thành công'))
        .catch(() => alert('Có lỗi xảy ra vui lòng thử lại sau'))
        .finally(() => setLoading(false));
    };

    return (
        <AuthLayout>
            <Loading loading={loading}/>
            <section className="absolute top-0 h-full overflow-hidden w-full">
                <div className="px-6 h-full text-gray-800">
                    <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
                        <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
                            <form>
                                <div className="mb-6">
                                    <div className="text-[28px] font-bold">Đăng nhập</div>
                                    <div className='font-bold'>Đăng nhập để dễ dàng quản lý bảo hiểm của bạn và tận hưởng sự hỗ trợ tốt nhất từ baohiemxe.ai !</div>
                                </div>
                                <div className="mb-6">
                                    <AuthInput
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        placeholder="Nhập địa chỉ email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        maxLength={50}
                                        error={error}
                                        required
                                    />
                                </div>

                                <div className="mb-6">
                                    <AuthInput
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={password}
                                        placeholder="Nhập mật khẩu"
                                        onChange={(e) => setPassword(e.target.value)}
                                        maxLength={50}
                                        error={error}
                                        required
                                    />
                                </div>

                                {/* <div className="flex justify-between items-center mb-6">
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none h-[40px]  text-lg  transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            id="exampleCheck2"
                                        />
                                        <label
                                            className="form-check-label inline-block text-gray-800"
                                            for="exampleCheck2"
                                        >
                                            Ghi nhớ tui
                                        </label>
                                    </div>
                                    <a href="#!" className="text-gray-800">
                                        Quên mật khẩu
                                    </a>
                                </div> */}

                                <div className="text-center lg:text-left flex">
                                    <p className="text-sm font-semibold mb-0">
                                        Chưa có tài khoản?
                                        <Link
                                            to={"/register"}
                                            className="ml-1 text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out underline"
                                        >
                                            Đăng ký tại đây
                                        </Link>
                                    </p>
                                    <div className="ml-auto text-right font-bold">
                                        <button onClick={handleSubmitLogin} type="submit">
                                            <div className="flex items-center">
                                                Đăng nhập
                                                <i className="fa-solid fa-chevron-right ml-2 text-[12px]"></i>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </AuthLayout>
    );
}
