export class accessTrade {
  constructor() {
    this.cookie = document.cookie
    this.cookieNames = ['_aff_network', '_aff_sid']
    this.cookieValues = []
  }

  checkCookieOnBrowser() {
    // TODO: check cookie if cookie names are exist
    for (let cookie of this.cookieNames)
      if (!this.cookie.includes(cookie)) return false
    return true
  }

  clearCookieOnBrowser() {
    for (let cookie of this.cookieNames) {
      document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;` // To remove cookie we can set the expiration date to a passed date
    }
  }

  clearCookieValues() {
    this.cookieValues = []
  }
  writeCookieValues() {
    for (let cookie of this.cookieNames) {
      let cookieValue = this.getCookieValueFromBrowser(cookie)
      this.cookieValues.push(cookieValue)
    }
  }

  getCookieValueFromBrowser(cookieName) {
    let cookie = document.cookie
    let cookieValue = cookie.split(`${cookieName}=`)[1].split(';')[0]
    return cookieValue
  }

  getCookieValues() {
    return this.cookieValues
  }
}
