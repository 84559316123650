import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {title: '', content: '', rightButtonText: '', rightButtonFunction: () => {}}; 
// rightButtonFunction is a function that will be called when the right button of the modal is clicked

export const ModalSlice = createSlice({
    name: 'Modal',
    initialState,
    reducers: {
        addSuccessModal: (state, action) => {
            const { title, content, rightButtonText, rightButtonFunction } = action.payload;
            return {title, content, rightButtonText, rightButtonFunction, type: 'success'};
        },
        addErrorModal: (state, action) => {
            const { title, content, rightButtonText, rightButtonFunction } = action.payload;
            return {title, content, rightButtonText, rightButtonFunction, type: 'error'};
        },
        addWarningModal: (state, action) => {
            const { title, content, rightButtonText, rightButtonFunction } = action.payload;
            return {title, content, rightButtonText, rightButtonFunction, type: 'warning'};
        },
        removeModal: (state, action) => {
            return initialState;
        },
    },
})

// Action creators are generated for each case reducer function
export const { addSuccessModal, addErrorModal, addWarningModal, removeModal } = ModalSlice.actions

export default ModalSlice.reducer