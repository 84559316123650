import "./NavbarStyles.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {FaPhone, FaInfo, FaTimes, FaBars} from "react-icons/fa";
import {GiEarthAmerica} from "react-icons/gi"
import Navbar from "./Navbar";


export default function HomeNavBar(){
    return <Navbar isHome={true}/>
};

