import React from "react";

export default function CommonInput({
  name,
  field,
  value,
  onChange,
  placeholder,
  required,
  maxLength = 50,
  error = '',
  type = "text",
  note,
}) {
  return (
    <div
      className="w-full"
    >
      <label className="font-bold text-[20px]" htmlFor={name}>
        {field}
      </label>
      {required && <span className="text-red-600 text-[20px]">*</span>}
      {note && <span className="text-gray-400 ml-1">(không bắt buộc)</span>}
      <input
        id={name}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        className="w-full rounded-[4px] px-2 border-b-2 border-[#004caf] placeholder-shown:border-gray-500 focus:outline-none h-[40px] text-lg placeholder:text-sm"
      ></input>
      {error && <p className="text-red-600">{error}</p>}
    </div>
  );
}
