import React from "react";
import MainLayout from "../layouts/MainLayout";

export default function RequestClaim() {
  return (
    <MainLayout>
      <div className="py-2">
        <div className="m-5 bg-white h-96"></div>
      </div>
    </MainLayout>
  );
}
