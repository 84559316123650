import React from 'react'
import FeedbackButton from '../components/Feedback/FeedbackButton';
import Navbar from "../components/Nav/Navbar";

export default function ShopLayout({ children }) {
  return (
    <>
      <Navbar />
      <main className="" >{children}</main>
      <FeedbackButton/>
    </>
  )
}
