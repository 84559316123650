import { configureStore } from '@reduxjs/toolkit'
import messageReducer from '../reducers/message/messageSlice'
import dialogModalReducer from '../reducers/modal/dialogModalSlice'

export const store = configureStore({
  reducer: {
    messages: messageReducer,
    dialogModal: dialogModalReducer,
  },
})

