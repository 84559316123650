import React from "react";
import MainLayout from "../layouts/MainLayout";

const ReimbursePolicy = () => {
    return (
        <MainLayout>
            <div className="bg-white m-auto w-[90%]">
                <div className="p-7">
                    <div className="text-center border-b border-solid border-slate-300 pb-[15px] mb-5 lg:flex lg:justify-between lg:items-center">
                        <h2 className="text-[20px] font-bold">Chính sách hoàn hủy</h2>
                        <div className="flex justify-center pt-3 lg:pt-0"><a href="/" className="text-gray-400 hover:text-black text-[15px]">Trang chủ</a><span className="mx-2">/</span><h3 className="font-semibold text-[15px]">Chính sách hoàn hủy</h3></div>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-center font-semibold text-[20px]">NHỮNG QUY ĐỊNH CHUNG</h2>
                        <ul className="text-[15px] pt-2 pl-5 pb-2"> * Bagang chỉ chấm dứt hợp đồng bảo hiểm và hoàn hủy trong các trường hợp sau: được quy định tại khoản  điều  Nghị định 03/2021/NĐ-CP
                            <li className="pb-2">- Trường hợp 1: bên mua bảo hiểm, người được bảo hiểm bị thu hồi giấy chứng nhận đăng ký xe, biển số xe.</li>
                            <li className="pl-3 pb-2">Người mua bảo hiểm, người được bảo hiểm sẽ tiến hành thông báo với Bagang (đại lý bảo hiểm) về việc hoàn hủy trong trường hợp trên. Trong thời hạn 5 ngày làm việc, kể từ ngày nhận được thông báo, Bagang (đại lý bảo hiểm) sẽ làm các thủ tục hoàn lại phí bảo hiểm cho bên mua bảo hiểm, người được bảo hiểm tương ứng với thời gian còn lại của hợp đồng bảo hiểm.</li>
                            <li className="pb-2">- Trường hợp 2: bên mua bảo hiểm, người được bảo hiểm không thông báo về việc chấm dứt hợp đồng bảo hiểm, nhưng Bagang (đại lý bảo hiểm) có những bằng chứng cụ thể về việc xe cơ giới thuộc trường hợp chấm dứt hợp đồng bảo hiểm.</li>
                            <li className="pl-3 pb-2">Bagang sẽ gửi thông báo đến người mua bảo hiểm, người được bảo hiểm đối.
                                Sau 15 ngày kể từ ngày gửi thông báo mà bên mua bảo hiểm, người được bảo hiểm không làm các thủ tục chấm dứt hợp đồng bảo hiểm thì hợp đồng bảo hiểm mặc nhiên được chấm dứt.
                                Bagang (đai lý bảo hiểm) sẽ tiến hành làm thủ tục hoàn phí khi hợp đồng được chấm dứt. Trong thời hạn trên nếu có xảy ra sự kiện bồi thường thì Bagang (đại lý bảo hiểm) vẫn sẽ chịu trách nhiệm bồi thường cho người được bảo hiểm.</li>
                            <li className="pb-2">* Bagang (đại lý bảo hiểm) sẽ không áp dụng chính sách hoàn hủy đối với trường hợp đã xảy ra sự kiện bảo hiểm có phát sinh bồi thường trước khi hợp đồng bảo hiểm bị chấm dứt.</li>
                            {/* <li className="pb-2">* Nếu bạn muốn hủy (chấm dứt) hợp đồng bảo hiểm thì phải cung cấp đầy đủ giấy tờ minh chứng thuộc một trong hai trường hợp trên và liên hệ trực tiếp với Bagang tại email bagang@bagang.ai hoặc số điện thoại 028 3622 2084.</li> */}
                            {/* <li className="pb-2">* Nếu trong quá trình mua có phát sinh sự cố không mong muốn như là đã thanh toán thành công có gửi xác nhận giao dịch thành công nhưng chưa nhận được hợp đồng bảo hiểm thì xin vui lòng liên hệ ngay cho Bagang tại email bagang@bagang.ai hoặc số điện thoại 028 3622 2084 để Bagang giải quyết sự cố và cấp lại hợp đồng cho bạn.</li> */}
                            <li className="pb-2">* Nếu bạn muốn hủy (chấm dứt) hợp đồng bảo hiểm thì phải cung cấp đầy đủ giấy tờ minh chứng thuộc một trong hai trường hợp trên và liên hệ trực tiếp với Bagang tại email bagang@bagang.ai</li>
                            <li className="pb-2">* Nếu trong quá trình mua có phát sinh sự cố không mong muốn như là đã thanh toán thành công có gửi xác nhận giao dịch thành công nhưng chưa nhận được hợp đồng bảo hiểm thì xin vui lòng liên hệ ngay cho Bagang tại email bagang@bagang.ai để Bagang giải quyết sự cố và cấp lại hợp đồng cho bạn.</li>
                      
                        </ul>
                    </div>

                </div>

            </div>
        </MainLayout>
    )
}

export default ReimbursePolicy