import "./PriceMotoSelectlock.css";
import React from "react";
import { HiShieldCheck } from "react-icons/hi"


const PriceMotoSelectlock = ({
  currentType,
  image,
  type,
  typeCode,
  title,
  price,
  handleSetType,
  handleSetTypeCode,
}) => {
  // var [active, setActive] = useState(1);
  // const handleActive = () => {setActive(id)};
  // const activeHandler = (keyID) => {
  //     onChange(keyID)
  // };

  const handleSelectType = () => {
    handleSetType(type);
    handleSetTypeCode(typeCode);
  };

  return (
    <button className="border-2 border-primary-color w-full rounded-[4px]" onClick={() => handleSelectType()}>
      <div className={`flex flex-wrap py-2 border-b-2 border-primary-color
    items-center text-center content-center hover:opacity-90 hover:cursor-pointer ${currentType === type ? "text-gray-100 bg-primary-color" : "text-gray-900 border-gray-300"}`}>
        <img src={image} className="h-[40px] xs:h-[60px] md:h-[80px] w-[40px] xs:w-[60px] md:w-[80px] mx-auto"/>
        <div className="w-full flex flex-wrap items-center justify-center relative ">
          <div className={`text-[16px] md:text-[18px] font-semibold w-full hover:cursor-pointer`} dangerouslySetInnerHTML={{ __html: title }} ></div>
        </div>
      </div>
      <div className="w-full font-bold text-center pt-2">
        <div className={`${currentType === type ? "text-primary-color" : "text-gray-600"}`}>{Number(price).toFixed(3)}&#8363;</div>
        <div className={`${currentType === type ? "text-primary-color" : "text-gray-600"}`}>(chưa có VAT)</div>
      </div>
    </button>
  );
};

export default PriceMotoSelectlock;
