import React from "react";
import MainLayout from "../layouts/MainLayout";

const styles = {
    content: "pt-1 text-[15px] text-[#000]"
}

const Policy = () => {
    return (
        <MainLayout>
            <div className="bg-white w-full px-5 md:px-10">
                <div className="">
                    <div className="text-left border-b border-solid border-slate-300 py-[15px] mb-5 lg:flex lg:justify-between lg:items-center">
                        <h2 className="text-[20px] font-bold">Chính sách và điều khoản</h2>
                        <div className="flex pt-3 lg:pt-0"><a href="/" className="text-gray-400 hover:text-black text-[15px]">Trang chủ</a><span className="mx-2">/</span><h3 className="font-semibold text-[15px]">Chính sách và điều khoản</h3></div>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold uppercase">Điều khoản sử dụng</h2>
                        <em className="">Xin vui lòng dành thời gian để tìm hiểu các điều khoản, thoả thuận dưới đây trước khi sử dụng, mua sản phẩm trên trang web và ứng dụng liên quan</em>
                        <p className={styles.content}>Trang <strong>“Baohiemxe.ai”</strong> và các ứng dụng liên quan (nếu có) được phát triển, sở hữu và quản lý bởi Công ty Cổ phần Công nghệ Bagang. Bằng cách sử dụng và/hoặc truy cập trang web này cũng như các ứng dụng liên quan, Bạn xác nhận rằng Bạn đã đọc và hiểu, đồng thời đồng ý với Điều khoản sử dụng (“Điều khoản”) được đề cập tại đây. </p>
                        <p className={styles.content}><strong>Nếu Bạn không đồng ý </strong>bị ràng buộc bởi các Điều khoản - Thoả thuận này, xin vui lòng dừng sử dụng và hãy tắt hoặc chuyển trang. Chúng tôi rất lấy làm tiếc và rất cảm ơn Bạn đã quan tâm đến “Baohiemxe.ai”. Việc này hoàn toàn là để bảo vệ quyền lợi của Người dùng, không ai khác đó chính là Bạn.</p>
                        <p className={styles.content}><strong>Nếu Bạn đồng ý</strong>, các Điều khoản này tạo thành một Thỏa thuận pháp lý ràng buộc giữa Bạn với tư cách là Người dùng Cá nhân và/hoặc Người dùng Tổ chức (“Bạn” hoặc “Người dùng”) và BAGang. </p>
                        <ul className="">Trước khi đi vào chi tiết của các Điều khoản - Thoả thuận:
                            <li className="">-	Đối với Người dùng quan tâm đến sản phẩm bảo hiểm dành cho xe gắn máy trên 50cc và xe ô tô các loại, Bạn phải ít nhất đủ mười tám (18) tuổi để sử dụng, mua các sản phẩm bảo hiểm trên trang web này.</li>
                            <li className="">-	Đối với Người dùng quan tâm đến sản phẩm bảo hiểm dành cho xe gắn máy hoặc các phương tiện tương tự có dung tích không vượt quá 50cc, Bạn phải ít nhất đủ mười sáu (16) tuổi trở lên.</li>
                        </ul>
                        <p className={styles.content}>Việc này nhằm đảm bảo tất cả Người tham gia giao thông chấp hành đúng theo quy định tại khoản 1 điều 60 của Luật giao thông đường bộ 2008.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">PHẠM VI DỊCH VỤ</h2>
                        <p className={styles.content}>Tại<strong> “Baohiemxe.ai”</strong>, Bagang tập trung cung cấp 2 (hai) sản phẩm cơ bản, đó là bảo hiểm bắt buộc trách nhiệm dân sự (BB TNDS) cho xe gắn máy và xe ô tô.</p>
                        <p className={styles.content}> Xin hãy lưu ý rằng Bạn có thể mua bảo hiểm ở “Baohiemxe.ai” nhưng Chúng tôi không phải là Nhà cung cấp bảo hiểm gốc. Đơn đăng ký bảo hiểm của Bạn và hợp đồng bảo hiểm sẽ được xem xét và đồng ý bởi các Công ty bảo hiểm gốc – Đối tác liên kết của BAGang.</p>
                        <p className={styles.content}>Trong trường hợp, Nhà cung cấp bảo hiểm gốc từ chối đơn đăng ký bảo hiểm của Bạn, Chúng tôi sẽ hoàn trả lại toàn bộ số tiền mà Bạn đã thanh toán kèm theo lý do từ chối.</p>
                        <p className={styles.content}>“Baohiemxe.ai” cung cấp đến Bạn thông tin dễ hiểu, đơn giản và trải nghiệm tốt nhất dựa trên các nội dung được tinh gọn từ quy định của Pháp luật hiện hành dành cho bảo hiểm BB TNDS của chủ xe cơ giới.</p>
                        <p className={styles.content}>Tất cả giá sản phẩm được niêm yết trên website hoàn toàn dựa trên quy định của Nhà nước. Nghĩa là, Chúng tôi bán đúng một giá niêm yết cho từng dòng/loại xe cụ thể theo quy định của Pháp luật. Nên bạn hoàn toàn có thể yên tâm việc mua đúng giá, không lo bị hớ.</p>
                        <ul className="">Chi tiết vui lòng xem:
                            <li className=" list-disc font-bold">Nghị định 03/2021/NĐ-CP của Chính Phủ;</li>
                            <li className=" list-disc font-bold">Thông tư 04/2021/TT-BTC của Bộ Tài chính.</li>
                        </ul>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">THANH TOÁN</h2>
                        <p className={styles.content}>Trừ trường hợp phí bảo hiểm của sản phẩm Bạn quan tâm niêm yết tại “Baohiemxe.ai” được Pháp luật yêu cầu và Công ty Bảo hiểm gốc sửa đổi, số tiền thanh toán được nêu tại trang thanh toán hoặc trong Email và/hoặc Zalo (theo số điện thoại Bạn đã đăng ký) xác nhận mà Chúng tôi đã gửi cho Bạn sẽ là số tiền cuối cùng và bao gồm tất cả các loại thuế và phí dịch vụ hiện hành.</p>
                        <p className={styles.content}>Bạn cần phải thanh toán trong thời hạn quy định. Chúng tôi có toàn quyền hủy đơn đăng ký hoặc giao dịch mua của Bạn nếu việc thanh toán không được thực hiện trong thời hạn đã nêu.</p>
                        <p className={styles.content}>Phương thức thanh toán bao gồm nhưng không giới hạn ở chuyển khoản Ngân hàng. Đối với thanh toán bằng chuyển khoản, Bạn có thể phải cung cấp biên lai chuyển khoản để làm chứng minh thanh toán. </p>
                        <p className={styles.content}>Đối với các hình thức thanh toán khác Bạn phải tuân theo các quy trình do “Baohiemxe.ai” và / hoặc các Nhà cung cấp dịch vụ thanh toán có liên quan yêu cầu.</p>
                        <p className={styles.content}>Sau khi Bạn đã thanh toán thành công tại “Baohiemxe.ai”, Giấy chứng nhận bảo hiểm điện tử sẽ được cấp ngay lập tức theo mẫu đã đăng ký với đầy đủ các thông tin theo yêu cầu của Pháp luật. </p>
                        <p className=" pt-1 text-[15px] text-[#000] font-bold">Giấy chứng nhận điện tử này hoàn toàn hợp pháp và có giá trị tương đương với giấy chứng nhận vật lý (Ấn chỉ bảo hiểm) màu vàng mà Chúng ta thường thấy.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">HOÀN HUỶ</h2>
                        <p className={styles.content}>Nếu giao dịch của Bạn bị huỷ do Chúng tôi hoặc do Bên Nhà Cung cấp từ chối hoặc bản thân Bạn huỷ trước khi Giấy chứng nhận và/hoặc Hợp đồng bảo hiểm gửi đến Email và/hoặc Zalo (theo số điện thoại Bạn đã đăng ký) của Bạn. Chúng tôi sẽ hoàn trả lại cho Bạn toàn bộ số tiền mà Bạn đã thanh toán cho Chúng tôi (nếu có).</p>
                        <p className={styles.content}>Nếu Bạn muốn huỷ giao dịch của Bạn sau khi nhận được Giấy chứng nhận và/hoặc Hợp đồng bảo hiểm, phí huỷ giao dịch có thể được áp dụng và số tiền được hoàn sẽ được xem xét lại dựa trên các điều khoản và điều kiện của Hợp đồng bảo hiểm, dựa theo đúng quy định của Nghị định 03/2021/NĐ-CP của Chính Phủ, Thông tư 04/2021/TT-BTC của Bộ Tài chính.</p>
                        <p className={styles.content}>Trong tất cả trường hợp, việc hoàn tiền sẽ được thực hiện bằng chính phương thức thanh toán mà Người dùng đã sử dụng để thanh toán cho các giao dịch mua của mình với “Baohiemxe.ai”. </p>
                        <p className={styles.content}>Ví dụ: nếu Bạn thanh toán cho giao dịch mua của mình bằng chuyển khoản, Chúng tôi sẽ chỉ hoàn tiền lại qua cùng một số tài khoản đã được dùng để thanh toán, Chúng tôi sẽ không hoàn lại tiền cho Bạn qua chuyển khoản tới một ngân hàng khác hoặc bất kỳ phương thức nào khác. </p>
                        <p className={styles.content}>Tuy nhiên, trong một số trường hợp, Chúng tôi sẽ chủ động xử lý linh hoạt khi Người dùng có thể chứng minh được mình là sở hữu hợp pháp của tài khoản và/hoặc các phương thức thanh toán online khác, được sử dụng để thanh toán và sau khi yêu cầu hoàn tiền.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">NỘI DUNG NGƯỜI DÙNG</h2>
                        <p className={styles.content}>Để có thể cung cấp các dịch vụ tốt nhất và thuận tiện nhất có thể cho Người dùng, Chúng tôi có thể phải yêu cầu Bạn gửi các nội dung như văn bản, nội dung nghe - nhìn và nội dung đa phương tiện khác ("Nội dung Người dùng"). Bằng cách cung cấp Nội dung Người dùng cho “Baohiemxe.ai”, Bạn chấp nhận cho “Baohiemxe.ai” quyền sử dụng Nội dung Người dùng.
                            Bằng cách tải lên Nội dung Người dùng, Bạn đồng ý cho “Baohiemxe.ai” quyền hiển thị, sử dụng và phân phối các Nội dung Người dùng của Bạn và sửa đổi (nếu có, vì mục đích kỹ thuật) và tái tạo Nội dung Người dùng đó (nếu có, vì mục đích kỹ thuật) để cho phép “Baohiemxe.ai” vận hành và cung cấp các Dịch vụ phục vụ cho đúng nhu cầu của Người dùng.</p>
                        <p className={styles.content}>Bạn đồng ý rằng các quyền và giấy phép này là hoàn toàn miễn phí bản quyền và không thể thu hồi, đồng thời bao gồm quyền để “Baohiemxe.ai” cung cấp Nội dung Người dùng này và chuyển những quyền này cho Bên liên quan mà “Baohiemxe.ai” có các mối quan hệ hợp đồng liên quan đến điều khoản của Dịch vụ “Baohiemxe.ai” (nếu có),
                            chỉ với mục đích cung cấp các dịch vụ theo yêu cầu của Bạn và để cho phép truy cập hoặc tiết lộ Nội dung Người dùng của Bạn cho các Bên thứ Ba nếu “Baohiemxe.ai” xác định việc truy cập đó là cần thiết để tuân thủ các nghĩa vụ Pháp lý của mình.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">BẢN QUYỀN ỨNG DỤNG</h2>
                        <p className={styles.content}>Theo các Điều khoản này và các chính sách của Chúng tôi (bao gồm các chính sách cung cấp cho Bạn với Dịch vụ), Chúng tôi cấp cho Bạn quyền hạn chế, không thể chuyển nhượng, không độc quyền và có thể thu hồi để truy cập và sử dụng Dịch vụ của Chúng tôi, với điều kiện:</p>
                        <p className={styles.content}>(i) Bạn sẽ không gửi quảng cáo không được yêu cầu hoặc trái phép, thư rác, thư dây chuyền, v.v.; </p>
                        <p className={styles.content}>(ii) Bạn sẽ không sao chép, phân phối hoặc sửa đổi bất kỳ phần nào của Dịch vụ mà không có sự cho phép trước bằng văn bản của Chúng tôi;</p>
                        <p className={styles.content}>(iii) Bạn sẽ không truyền tải bất kỳ Nội dung nào có chứa vi rút phần mềm hoặc mã máy tính, tệp hoặc chương trình có hại khác;</p>
                        <p className={styles.content}>(iv) Bạn sẽ không làm gián đoạn các máy chủ hoặc mạng được kết nối với Dịch vụ;</p>
                        <p className={styles.content}>(v) Bạn phải tuân thủ các Điều khoản này.</p>
                        <p className={styles.content}>Việc sử dụng Dịch vụ của Chúng tôi không mang lại cho Bạn quyền sở hữu bất kỳ quyền sở hữu trí tuệ nào hoặc Nội dung Bạn truy cập (ngoài Nội dung Người dùng của Bạn), những quyền này sẽ vẫn thuộc về Chúng tôi và các Nhà cấp phép tương ứng của Chúng tôi.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">SỞ HỮU TRÍ TUỆ VÀ NHÃN HIỆU</h2>
                        <p className={styles.content}>Tất cả các nhãn hiệu, nhãn hiệu dịch vụ, tên thương mại, biểu tượng và tên miền trên “Baohiemxe.ai” này là tài sản của CTCP Công nghệ Bagang và những Bên liên quan (nếu có).</p>
                        <p className={styles.content}>Không có nội dung nào trên các “Baohiemxe.ai” nên được hiểu là cấp, theo ngụ ý hoặc nói theo nhiều cách khác, bất kỳ giấy phép hoặc quyền sử dụng bất kỳ nhãn hiệu, nhãn hiệu dịch vụ, tên thương mại, biểu tượng và tên miền nào được hiển thị trên “Baohiemxe.ai” mà không có sự cho phép bằng văn bản của “Baohiemxe.ai” hoặc Bên thứ Ba sở hữu các nhãn hiệu/ tên được hiển thị trên “Baohiemxe.ai”.</p>
                        <p className={styles.content}>Việc Bạn sử dụng các nhãn hiệu, nhãn hiệu dịch vụ, tên thương mại, biểu tượng và tên miền được hiển thị trên “Baohiemxe.ai” hoặc bất kỳ nội dung nào khác trên “Baohiemxe.ai”, ngoại trừ được cung cấp ở đây, đều bị nghiêm cấm.</p>
                        <p className={styles.content}>Hình ảnh hiển thị trên “Baohiemxe.ai” này là tài sản của Chúng tôi.</p>
                        <p className={styles.content}>Việc sử dụng những hình ảnh này của Bạn hoặc bất kỳ ai khác được Bạn ủy quyền đều bị cấm trừ khi được cho phép cụ thể. Bất kỳ việc sử dụng trái phép hình ảnh nào đều có thể vi phạm luật bản quyền, luật nhãn hiệu, luật bảo mật và công khai cũng như các quy định và quy chế truyền thông.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">SỬ DỤNG SAI MỤC ĐÍCH</h2>
                        <p className={styles.content}>Không sử dụng sai mục đích Dịch vụ của Chúng tôi. Bạn đồng ý không sử dụng Dịch vụ hoặc bất kỳ khía cạnh hoặc tính năng nào của Dịch vụ cho bất kỳ mục đích bất hợp pháp nào hoặc theo bất kỳ cách nào có thể gây tổn hại, thiệt hại hoặc làm mất uy tín của bất kỳ Bên nào khác. Không giới hạn ở câu trước, Bạn đồng ý rằng Bạn sẽ:</p>
                        <p className={styles.content}>(i)	Xem xét và tuân thủ các Điều khoản này và Chính sách Bảo mật;</p>
                        <p className={styles.content}>(ii)	Tuân thủ tất cả các Luật hiện hành có liên quan trong phạm vi sản phẩm sử dụng;</p>
                        <p className={styles.content}>(iii)	Cung cấp thông tin chính xác cho Chúng tôi và cập nhật thông tin đó khi cần thiết; </p>
                        <p className={styles.content}>(iv)	Trung thực và thiện chí.</p>
                        <p className={styles.content}>Bằng cách sử dụng Dịch vụ, Bạn đồng ý không thực hiện việc:</p>
                        <p className={styles.content}>(i)	Sử dụng hoặc lạm dụng bằng lời nói, quấy rối, đe dọa, bôi nhọ, bắt nạt, sử dụng ngôn từ kích động thù địch hoặc gây hại cho bất kỳ nhân viên hoặc đại lý nào của Công ty;</p>
                        <p className={styles.content}>(ii)	Sao chép, cấp phép, cấp phép phụ, xuất bản, phát sóng, truyền, phân phối, thực hiện, hiển thị, bán, đổi thương hiệu hoặc chuyển giao thông tin tìm thấy trong Dịch vụ, trừ khi được cho phép trong các Điều khoản này hoặc khi được Chúng tôi cho phép rõ ràng;</p>
                        <p className={styles.content}>(iii)	Thiết kế đối chiếu, dịch ngược, tháo rời, giải mã hoặc cố gắng lấy mã nguồn cho bất kỳ tài sản trí tuệ cơ bản nào được sử dụng để cung cấp Dịch vụ hoặc bất kỳ phần nào trong đó;</p>
                        <p className={styles.content}>(iv)	Sử dụng hoặc sao chép thông tin, nội dung hoặc bất kỳ dữ liệu nào Bạn xem hoặc lấy từ Dịch vụ để cung cấp bất kỳ dịch vụ nào có tính cạnh tranh, theo quyết định riêng của Chúng tôi, với Dịch vụ;</p>
                        <p className={styles.content}>(v)	Xóa mọi thông báo về bản quyền, nhãn hiệu hoặc các quyền sở hữu khác có trong hoặc trên Dịch vụ;</p>
                        <p className={styles.content}>(vi)	Vi phạm hoặc sử dụng thương hiệu, biểu tượng hoặc nhãn hiệu của Chúng tôi trong bất kỳ tên doanh nghiệp, email hoặc URL nào ngoại trừ được Chúng tôi cho phép rõ ràng bằng văn bản;</p>
                        <p className={styles.content}>(vii)	Sử dụng bot hoặc các phương pháp tự động khác để truy cập Dịch vụ, thêm hoặc tải xuống danh bạ, gửi hoặc chuyển hướng tin nhắn hoặc thực hiện các hoạt động tương tự khác thông qua Dịch vụ;</p>
                        <p className={styles.content}>(viii)	Tham gia vào việc "đóng khung", "sao chép" hoặc mô phỏng hình thức hoặc chức năng của trang web baohiemxe.ai và các ứng dụng liên quan;</p>
                        <p className={styles.content}>(ix)	Cản trở hoặc làm gián đoạn các Dịch vụ, bao gồm nhưng không giới hạn ở bất kỳ máy chủ hoặc mạng nào được kết nối với Dịch vụ hoặc phần mềm nền tảng.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">TRÁCH NHIỆM CỦA ĐẠI LÝ BẢO HIỂM BAGANG ĐỐI VỚI NGƯỜI DÙNG</h2>
                        <p className={styles.content}>1. Yêu cầu, đốc thúc Nhà bảo hiểm gốc liên kết của Bagang xử lý bồi thường và/hoặc hỗ trợ chi trả bồi thường khi khách hàng của Baohiemxe.ai không may xảy ra và/hoặc gây ra sự kiện bồi thường (tai nạn);</p>
                        <p className={styles.content}>2. Hỗ trợ hướng dẫn làm các thủ tục bồi thường để bảo đảm quyền lợi của Người mua bảo hiểm tại Baohiemxe.ai;</p>
                        <p className={styles.content}>3. Hỗ trợ giải quyết các sự cố trong quá trình mua bảo hiểm tại trang web Baohiemxe.ai và các ứng dụng có liên quan;</p>
                        <p className={styles.content}>4. Hỗ trợ giải quyết các vấn đề thắc mắc của Người mua bảo hiểm, Người được bảo hiểm đối với các chính sách bảo hiểm, quyền lợi bảo hiểm, các vấn đề có liên quan, v.v.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">QUYỀN VÀ NGHĨA VỤ CỦA NGƯỜI DÙNG ĐỐI VỚI TRANG WEB</h2>
                        <p className={styles.content}>“Baohiemxe.ai” sẽ cấp cho Bạn các quyền hạn chế nhất định (cấu thành “Giấy phép có giới hạn”), không thể chuyển nhượng hoặc chuyển giao, để truy cập và sử dụng trong phạm vi được các Điều khoản này cho phép rõ ràng.
                            Nội dung có sẵn trên “Baohiemxe.ai”, bao gồm mọi thông tin, dữ liệu, văn bản, hình ảnh, âm thanh, đồ họa, video, tin nhắn, bài đánh giá hoặc các tài liệu khác, bao gồm cả cơ sở hạ tầng phần mềm được sử dụng để cung cấp Nội dung (“Nội dung”), thuộc sở hữu hoàn toàn của “Baohiemxe.ai” hoặc
                            các Nhà cung cấp và Nhà cung cấp dịch vụ của “Baohiemxe.ai”, bao gồm nhưng không giới hạn ở Nhà cung cấp (nếu có). Bạn có thể sử dụng “Baohiemxe.ai” để yêu cầu và mua các gói bảo hiểm và Bạn đảm bảo rằng Bạn sẽ không gửi yêu cầu bảo hiểm sai thông tin. Bạn tuyên bố để đảm bảo rằng thông tin thanh toán Bạn cung cấp là chính xác.
                            Bạn cũng đảm bảo cung cấp Email và Zalo (theo số điện thoại đăng ký) địa chỉ gửi thư chính xác cũng như các chi tiết khác về thông tin liên hệ của Bạn.</p>
                        <p className={styles.content}>Bạn đồng ý không sử dụng “Baohiemxe.ai” hoặc Nội dung cho mục đích thương mại hoặc trực tiếp hoặc gián tiếp ngoài mục đích sử dụng cá nhân hoặc cho các mục đích trái pháp luật (bị pháp luật nghiêm cấm) hoặc thực hiện bất kỳ hành vi nào vi phạm các Điều khoản này.
                            Trừ khi có sự đồng ý bằng văn bản của “Baohiemxe.ai”, Bạn đồng ý không sửa đổi, sao chép, phân phối, truyền tải, hiển thị, thực hiện, tái sản xuất, xuất bản, cấp phép, tạo các tác phẩm phái sinh từ, chuyển nhượng, bán hoặc bán lại bất kỳ thông tin, phần mềm, sản phẩm hoặc dịch vụ nào có được liên quan đến “Baohiemxe.ai”. Ngoài ra, Bạn đồng ý không:</p>
                        <p className={styles.content}>1.	Thực hiện bất kỳ hành động nào áp đặt hoặc có thể áp đặt, một gánh nặng vô lý đối với “Baohiemxe.ai” hoặc cơ sở hạ tầng “Baohiemxe.ai”;</p>
                        <p className={styles.content}>2.	Tạo một liên kết chính đến các cho bất kỳ mục đích nào mà không có sự đồng ý bằng văn bản từ “Baohiemxe.ai”;</p>
                        <p className={styles.content}>3.	Bán lại, sử dụng, sao chép, thực hiện giám sát (chẳng hạn như sử dụng hoặc cài đặt chương trình spider hoặc scrape), hiển thị, tải xuống hoặc thực hiện việc tạo dựng nội dung, phần mềm, sản phẩm, dịch vụ nào có sẵn thông qua “Baohiemxe.ai” cho mục đích thương mại hoặc mục tiêu / hoạt động cạnh tranh;</p>
                        <p className={styles.content}>4.	Sao chép “Baohiemxe.ai” (thông qua "khung" hoặc "nhân bản") hoặc thiết lập một phần của Trang trên bất kỳ “Baohiemxe.ai” web nào khác mà không có sự đồng ý trước bằng văn bản;</p>
                        <p className={styles.content}>5.	Gửi hoặc lưu trữ bất kỳ tài liệu nào đến hoặc thông qua “Baohiemxe.ai” vi phạm bất kỳ luật, quy định hoặc quy tắc nào hoặc có thể hỗ trợ các hoạt động bất hợp pháp hoặc tội phạm;</p>
                        <p className={styles.content}>6.	Gửi hoặc lưu trữ bất kỳ tài liệu nào (bao gồm cả việc cung cấp bất kỳ liên kết nào đến tài liệu khác) có tính chất phỉ báng, vu khống, sai sự thật, khiêu dâm, đe dọa, bôi nhọ hoặc bất hợp pháp hoặc có nội dung khác;</p>
                        <p className={styles.content}>7.	Gửi hoặc lưu trữ bất kỳ tài liệu nào có thể vi phạm quyền sở hữu trí tuệ hoặc các quyền khác của một tổ chức hoặc cá nhân cụ thể, bao gồm nhưng không giới hạn ở bản quyền, bằng sáng chế, nhãn hiệu, bí mật kinh doanh, thông tin bí mật hoặc ấn phẩm;</p>
                        <p className={styles.content}>8.	Gửi hoặc lưu trữ bất kỳ tài liệu nào bị cấm bởi luật hiện hành hoặc vi phạm các quyền và nghĩa vụ tồn tại dựa trên mối quan hệ hợp đồng;</p>
                        <p className={styles.content}>9.	Gửi hoặc lưu trữ bất kỳ tài liệu nào có chứa vi rút phần mềm, sâu, trojan hoặc mã máy tính có hại khác;</p>
                        <p className={styles.content}>10.	Giả dạng bất kỳ cá nhân hoặc tổ chức nào cho bất kỳ mục đích nào;</p>
                        <p className={styles.content}>11.	Thao túng hoặc làm sai lệch thông tin với mục đích che giấu nguồn gốc của các tuyên bố được cung cấp;</p>
                        <p className={styles.content}>12.	Sử dụng “Baohiemxe.ai” theo bất kỳ cách nào có thể làm hỏng, vô hiệu hóa, cản trở hoặc cản trở việc sử dụng “Baohiemxe.ai” hoặc những người sử dụng thiết bị máy tính khác, hoặc gây hư hỏng, gián đoạn hoặc hạn chế chức năng của phần mềm, phần cứng hoặc thiết bị viễn thông;</p>
                        <p className={styles.content}>13.	Truy cập trái phép hoặc thực hiện sửa đổi trái phép “Baohiemxe.ai” hoặc “Baohiemxe.ai” web có liên quan khác, các tài khoản khác, hệ thống máy tính, mạng được kết nối với “Baohiemxe.ai” thông qua hack, đánh cắp mật khẩu hoặc các vấn đề tương tự khác;</p>
                        <p className={styles.content}>14.	Lấy hoặc cố gắng lấy bất kỳ tài liệu hoặc thông tin nào bằng những cách không được cung cấp có chủ đích bởi “Baohiemxe.ai” (bao gồm nhưng không giới hạn ở các điểm đến khác do Trang cung cấp). Điều này bao gồm nhưng không giới hạn, lấy hoặc thu thập thông tin về những người khác như địa chỉ email;</p>
                        <p className={styles.content}>15.	Tham gia vào các hành vi hoặc hành động gian lận nhằm thao túng trang kết quả của công cụ tìm kiếm (“SERP”) hoặc thực hiện tối ưu hóa công cụ tìm kiếm (“SEO”). Các phương pháp SEO được coi là phi đạo đức hoặc cấu thành "spamdexing" bao gồm nhưng không giới hạn ở kỹ thuật che giấu, siêu dữ liệu, thẻ tiêu đề, nội dung cóp nhặt, liên kết, google bom, từ khóa tìm kiếm, văn bản ẩn hoặc liên kết ẩn, nhận xét chứa spam và các vấn đề khác;</p>
                        <p className={styles.content}>16.	Bất kỳ hành động nào khác có thể ảnh hưởng xấu hoặc dẫn đến thiệt hại cho “Baohiemxe.ai”, “Baohiemxe.ai” hoặc các chi nhánh và nhân viên của nó, hoặc danh tiếng của “Baohiemxe.ai”;</p>
                        <p className={styles.content}>17.	Sửa đổi hoặc tạo các sản phẩm phái sinh dựa trên “Baohiemxe.ai” và / hoặc Dịch vụ, hoặc thiết kế đối chiếu hoặc truy cập vào phần mềm cơ bản vì bất kỳ lý do gì;</p>
                        <p className={styles.content}>18.	Sử dụng “Baohiemxe.ai” để xây dựng một sản phẩm hoặc dịch vụ cạnh tranh.</p>
                        <p className={styles.content}>Bằng cách mua sản phẩm qua “Baohiemxe.ai”, Bạn tuyên bố và đảm bảo rằng Bạn không phải tuân theo bất kỳ lệnh cấm hoặc hạn chế nào của bất kỳ chương trình trừng phạt nào hoặc phải chịu bất kỳ hình phạt nào theo bất kỳ chế độ chống rửa tiền nào.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">GIỚI HẠN VÀ LOẠI TRỪ TRÁCH NHIỆM PHÁP LÝ</h2>
                        <p className={styles.content}>Trong phạm vi tối đa được pháp luật cho phép. Bất kể những giới hạn được nêu dưới đây, phạm vi bảo hiểm và trách nhiệm của các Nhà bảo hiểm theo hợp đồng bảo hiểm của Bạn chỉ được xác định bởi các điều khoản, điều kiện, loại trừ và giới hạn trong hợp đồng bảo hiểm của Bạn và Luật hiện hành.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">BẠN HIỂU RÕ VÀ ĐỒNG Ý RẰNG</h2>
                        <p className={styles.content}>(a) việc bạn sử dụng dịch vụ và việc mua và sử dụng bất kỳ sản phẩm hoặc dịch vụ nào là rủi ro duy nhất của bạn. Trong mức độ tối ưu được pháp luật cho phép, “baohiemxe.ai” từ chối rõ ràng tất cả các bảo đảm và điều kiện dưới bất kỳ hình thức nào, dù rõ ràng hoặc ngụ ý, bao gồm, nhưng không giới hạn đối với các bảo đảm về tính bán được, sự phù hợp cho một mục đích cụ thể và không xâm phạm.</p>
                        <ul className="">(b) “baohiemxe.ai” không bảo đảm:
                            <li className="">(i) dịch vụ sẽ đáp ứng tất cả các yêu cầu của bạn;</li>
                            <li className="">(ii) dịch vụ sẽ không bị gián đoạn, kịp thời, an toàn hoặc không có lỗi.</li>
                            <li className="">(iii) tất cả các lỗi trong phần mềm hoặc dịch vụ sẽ được khắc phục trong thời gian sớm nhất</li>
                        </ul>
                        <p className={styles.content}>(c) bất kỳ tài liệu nào được tải xuống hoặc cách khác có được thông qua việc sử dụng dịch vụ được do quyết định và rủi ro của chính bạn và bạn tự chịu trách nhiệm về bất kỳ thiệt hại nào đối với máy tính của bạn hoặc thiết bị khác hoặc mất dữ liệu từ việc tải xuống hoặc sử dụng bất kỳ vật chất.</p>
                        <ul className="">(d) không có lời khuyên hoặc thông tin nào, bằng lời nói hoặc bằng văn bản, do bạn có được từ “baohiemxe.ai” hoặc qua hoặc từ dịch vụ sẽ tạo ra bất kỳ bảo đảm nào không được nói rõ ràng trong các điều khoản này. Bạn hiểu rõ và đồng ý rằng “baohiemxe.ai”, các công ty con, người liên quan và cấp phép cho “baohiemxe.ai”, và các nhân viên, nhân viên, đại lý và người kế nhiệm chúng tôi sẽ không chịu trách nhiệm pháp lý cho những thiệt hại trực tiếp, gián tiếp, ngẫu nhiên, đặt biệt, bồi thường hậu quả,
                            Bồi thường mang tính trừng phạt, bao gồm nhưng không giới hạn đối với, thiệt hại do mất lợi nhuận, sự tín nhiệm, mất dữ liệu, hoặc các mất mát vô hình khác (ngay cả nếu “baohiemxe.ai” đã được tư vấn về khả năng của các thiệt hại nó) gây ra từ:
                            <li className="">(i)	Việc sử dụng hoặc khả năng sử dụng dịch vụ;</li>
                            <li className="">(ii)	Việc sử dụng hoặc không thể sử dụng dịch vụ;</li>
                            <li className="">(iii)	Hành động hoặc sự thiếu só của “baohiemxe.ai” liên quan đến thông tin tài khoản của bạn và bất kỳ thay đổi nào hoặc thông báo đã nhận được;</li>
                            <li className="">(iv)	Việc bạn không thể bảo vệ tính bảo mật của bất kỳ mật khẩu hoặc quyền truy cập thông tin tài khoản của bạn;</li>
                            <li className="">(v)	Việc đóng tài khoản của bạn theo các điều khoản của điều khoản này; hoặc là(v)	Việc đóng tài khoản của bạn theo các điều khoản của điều khoản này;</li>
                            <li className="">(vi)	Bất kỳ vấn đề nào khác liên quan đến dịch vụ</li>
                        </ul>
                        <p className={styles.content}>Không có điều nào trong các điều khoản này (bao gồm các điều khoản giới hạn trách nhiệm) nhằm để loại trừ hoặc giới hạn bất kỳ điều kiện, bảo đảm, quyền hoặc trách nhiệm pháp lý nào mà có thể không được loại trừ hoặc giới hạn bởi pháp luật.
                            Một số điều khoản pháp lý không cho phép loại trừ các đảm bảo hoặc điều kiện hoặc giới hạn hoặc loại trừ trách nhiệm pháp lý đối với tổn thất hoặc thiệt hại do các hành vi cố ý, sơ suất, vi phạm hợp đồng hoặc vi phạm các điều khoản hàm ý, hoặc tổn thất ngẫu nhiên hoặc tổn thất bồi thường hậu quả.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">BỒI THƯỜNG</h2>
                        <ul className="">Bạn đồng ý bảo vệ, bồi thường và không gây tổn hại cho Chúng tôi, các chi nhánh của Chúng tôi và chủ sở hữu, cán bộ, giám đốc, nhân viên, đại lý và / hoặc người cấp phép của Chúng tôi khỏi bất kỳ và tất cả các khiếu nại, thiệt hại, nghĩa vụ, tổn thất, và chi phí (bao gồm nhưng không giới hạn ở phí luật sư) phát sinh từ:
                            <li className="">(i)	Việc Bạn sử dụng Dịch vụ;</li>
                            <li className="">(ii)	Bạn vi phạm các Điều khoản này;</li>
                            <li className="">(iii)	Bạn vi phạm bất kỳ quyền nào của bên thứ ba, bao gồm nhưng không giới hạn bản quyền, tài sản, quyền công khai hoặc quyền riêng tư; bao gồm tất cả các hành động được thực hiện trong tài khoản của Bạn. Nghĩa vụ bảo vệ, giữ vô hại và bồi thường này sẽ vẫn tồn tại sau khi chấm dứt các Điều khoản này và việc Bạn sử dụng Dịch vụ.</li>
                        </ul>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">CHẤM DỨT</h2>
                        <p className={styles.content}>Bagang có toàn quyền quyết định thay đổi, hoãn, ngừng hoặc dừng “Baohiemxe.ai” và / hoặc một phần của “Baohiemxe.ai”, bao gồm bất kỳ Dịch vụ nào và / hoặc việc sử dụng “Baohiemxe.ai”, hoặc một phần của “Baohiemxe.ai”, bất kỳ lúc nào vì bất kỳ lý do gì mà không cần thông báo trước cho Bạn.</p>
                        <p className={styles.content}>Trong trường hợp chấm dứt, cả Bạn và “Baohiemxe.ai” vẫn bị ràng buộc bởi các nghĩa vụ trong Điều khoản này, bao gồm nhưng không giới hạn ở các bảo đảm, bồi thường, miễn truy cứu và giới hạn trách nhiệm pháp lý mà Bạn đã đồng ý, liên quan đến bất kỳ dịch vụ nào đã được cung cấp cho Bạn nhưng chưa được cung cấp đầy đủ vào ngày chấm dứt.</p>
                        <p className={styles.content}>“Baohiemxe.ai” sẽ không chịu trách nhiệm với Bạn hoặc bất kỳ bên thứ ba nào về việc chấm dứt hoặc tạm ngừng quyền truy cập của Bạn vào “Baohiemxe.ai”.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">QUY ĐỊNH CHUNG</h2>
                        <p className={styles.content}>Chúng tôi có toàn quyền ngừng hoặc sửa đổi bất kỳ khía cạnh nào của Dịch vụ vào bất kỳ lúc nào. Các Điều khoản này, cùng với Chính sách Bảo mật và bất kỳ thông báo pháp lý nào khác được Chúng tôi công bố về Dịch vụ, sẽ cấu thành toàn bộ thỏa thuận giữa Chúng tôi liên quan đến Dịch vụ và sẽ thay thế tất cả các thỏa thuận trước đó, cho dù bằng văn bản hay bằng miệng, giữa Bạn và “Baohiemxe.ai” liên quan đến Các dịch vụ.</p>
                        <p className={styles.content}>Ngoại trừ trường hợp được nêu rõ ràng, không có điều khoản nào trong các Điều khoản này nhằm cấp cho bất kỳ bên thứ ba nào quyền thực thi bất kỳ điều khoản nào hoặc trao cho bên thứ ba bất kỳ lợi ích nào theo các Điều khoản này.</p>
                        <p className={styles.content}>Việc một trong hai Bên không thực thi bất kỳ điều khoản nào trong các Điều khoản này vào bất kỳ lúc nào sẽ không được coi là sự từ bỏ quyền của mỗi Bên trong việc thực thi vi phạm điều khoản đó hoặc bất kỳ điều khoản nào khác trong các Điều khoản này hoặc là sự khước từ bất kỳ điều khoản nào đang tiếp tục, thành công hoặc tiếp theo vi phạm bất kỳ điều khoản nào hoặc các quy định khác của các Điều khoản này.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">BẤT KHẢ KHÁNG</h2>
                        <p className={styles.content}>“Baohiemxe.ai” sẽ không chịu trách nhiệm pháp lý đối với bất kỳ việc không thực hiện hoặc vi phạm các Điều khoản này, chẳng hạn như giao dịch thất bại, quyền truy cập bị hạn chế vào “Baohiemxe.ai” hoặc bất kỳ thiệt hại hoặc tổn hại nào cho Người dùng do bất kỳ hành động hoặc điều kiện nào ngoài tầm kiểm soát hợp lý của Bạn hoặc Chúng tôi ("Sự kiện bất khả kháng").
                            Các Sự kiện Bất khả kháng bao gồm nhưng không giới hạn ở thảm họa thiên nhiên (lũ lụt, động đất), dịch bệnh, bạo loạn, tuyên chiến, chiến tranh, hành động quân sự, hành động khủng bố, cấm vận, trừng phạt, thay đổi luật hoặc quy định, sét, bão / cuồng phong / lốc xoáy, đình công lao động, biểu tình, và các cuộc tấn công mạng.</p>
                        <p className={styles.content}>“Baohiemxe.ai” sẽ không chịu trách nhiệm về bất kỳ thiệt hại hoặc mất mát nào do bất kỳ bên nào gây ra cho bất kỳ bên nào vì Sự kiện Bất khả kháng.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">LUẬT ÁP DỤNG VÀ GIẢI QUYẾT TRANH CHẤP</h2>
                        <p className={styles.content}>Thỏa thuận của Bạn với “Baohiemxe.ai” trong các Điều khoản và Điều kiện này sẽ chịu sự điều chỉnh của Luật pháp Việt Nam.</p>
                        <p className={styles.content}>Trong trường hợp bất kỳ tranh chấp nào giữa Bạn và “Baohiemxe.ai” phát sinh từ hoặc liên quan đến thỏa thuận này (“Tranh chấp”), các Bên liên quan đồng ý nỗ lực hết sức để thảo luận các vấn đề nhằm thống nhất một giải pháp hòa giải Tranh chấp.</p>
                        <p className={styles.content}>Trong trường hợp không đạt được giải quyết Tranh chấp hữu nghị trong vòng 30 (ba mươi) ngày kể từ ngày xảy ra Tranh chấp, Tranh chấp cuối cùng sẽ được giải quyết tại toà án có thẩm quyền tại Việt Nam.</p>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-[18px] font-semibold ">
                            LƯU Ý KHÁC:
                        </h2>
                        <p className={styles.content}>Trong trường hợp có sự thay đổi về Luật và/hoặc Quy định của Nhà nước với các Sản phẩm bảo hiểm được bán trên trang web này, cũng như là thay đổi để phù hợp hơn trong quá trình vận hành thực tế, Chúng tôi có thể và/hoặc sẽ thay đổi, điều chỉnh, thêm và bỏ bớt một phần hoặc toàn bộ các Điều khoản, thoả thuận để đảm bảo tính tuân thủ Pháp luật cũng như bảo đảm tính phù hợp với thực tiễn.</p>
                        <p className={styles.content}>Chúng tôi sẽ cố gắng bằng hết sức khả năng có thể để truyền tải thông tin một cách đơn giản nhất đến Người dùng. Đảm bảo hạn chế tối thiểu sự bất tiện, mất thời gian, làm phiền Người dùng. Đồng thời, đảm bảo quyền lợi hết mức có thể cho Người dùng.</p>
                    </div>
                </div>

            </div>
        </MainLayout>
    )
}

export default Policy