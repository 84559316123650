import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import Axios from "../../functions/axios";
import GetInsuranceSection from "../../components/PaymentCompleted/GetInsuranceSection";
import PaymentCompletedForm from "../../components/PaymentCompleted/PaymentCompletedForm";
import { act } from "../../App";

export default function PaymentOnePay() {
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams("");
    const [status, setStatus] = useState(null);
    const [message, setMessage] = useState("");
    const [orderId, setOrderId] = useState("");
    const [note, setNote] = useState("");
    const [email, setEmail] = useState('');
    const [linkFileCertificate, setLinkFileCertificate] = useState("");

    // Sau khi thanh toán giấy bảo hiểm có thể chưa có liền nên phải refetch
    const refetchAPI = (refetchCount = 0, orderId) => {
        let linkFileCertificate = "";
        let voucherOrderId = 0
        let transactionId = ''
        let netAmount = 0 
        refetchCount++;
        Axios.get(`/api/insurance/GetInsuranceOrderById/${orderId}`)
            .then(
                (res) => {
                    linkFileCertificate = res.data.data?.detail?.linkFileCertificate;
                    voucherOrderId = res.data.data?.voucherOrderId
                    transactionId = res.data.data?.voucherOrderId
                    netAmount = res.data.data?.netAmount
                    const email =
                        res.data.data?.detail?.email;
                    if (email) setEmail(email);
                    if (linkFileCertificate) {
                        setLinkFileCertificate(linkFileCertificate);
                    }
                    console.log('Checker', act.checkCookieOnBrowser())
                    let statusCheck = act.checkCookieOnBrowser()
                    // Nếu như có thì gọi qua lại accessTrade để lưu lại record cho họ rồi xóa cookie
                    try {
                      if (statusCheck) {
                        console.log('RUN HERE')
                        let cookieValues = act.getCookieValues() // Định dạng là 1 mảng [_aff_network,_aff_sid]
                        console.log('cookieValues', cookieValues)
                        let _aff_network = cookieValues[0]
                        let _aff_sid = cookieValues[1]
                        // Gọi api để lưu lại record
                        // _aff_network, _aff_sid khác null thì mới gọi api
                        if (_aff_network && _aff_sid)
                            Axios.post('api/Insurance/SendConversionsAccessTrade', {
                            _aff_network: _aff_network,
                            _aff_sid: _aff_sid,
                            VoucherOrderId: voucherOrderId,
                            TransactionId: transactionId.toString(),
                            NetAmount: netAmount,
                            })
                            .then((res) => {
                                console.log('SendConversionsAccessTrade', res.data)
                                // Xóa cookie
                                act.clearCookieOnBrowser()
                                act.clearCookieValues()
                                console.log('Delete cookie', act.getCookieValueFromBrowser())
                            })
                            .catch((err) => {
                                console.log('SendConversionsAccessTrade', err)
                            })
                      }
                    } catch (err) {
                      console.log('Error', err)
                    }
                }
            )
            .catch(() => { })
            .finally(() => {
                setLoading(false); //loading nguyên page ko cần load bây h chỉ cần load giấy thôi (có 2 loading lận)
                if (refetchCount >= 5 && !linkFileCertificate) {
                    setStatus("fail");
                    setMessage("Hoàn thành");
                    setNote("Đơn hàng đã thanh toán nhưng không tìm thấy giấy bảo hiểm. Vui lòng liên hệ với chúng tôi qua email buuboi@bagang.ai để được hỗ trợ. Chúng tôi sẽ liên hệ lại và hỗ trợ sớm nhất có thể trong vòng 48h.")
                    setLoading(false);
                } else if (!linkFileCertificate) {
                    setTimeout(() => {
                        refetchAPI(refetchCount, orderId);
                    }, 4500);
                };
            });
    };

    useEffect(() => {
        const message = searchParams.get("vpc_Message");
        const orderId = searchParams.get("vpc_OrderInfo");
        const resCode = searchParams.get("vpc_TxnResponseCode");
        const status = resCode === "0" ? "success" : "fail";
        setMessage(message);
        setOrderId(orderId);
        setStatus(status);
        setNote(getMsgFromOnePayResCode(resCode));
        if (status === "success" && orderId) {
            setLoading(true);
            refetchAPI(0, orderId);
        }
    }, []);

    const getMsgFromOnePayResCode = (resCode) => {
        switch (resCode) {
            case "0":
                return "Giao dịch thành công.";
            case "1":
                return "Ngân hàng từ chối cấp phép giao dịch.";
            case "2":
                return "Ngân hàng phát hành thẻ từ chối cấp phép giao dịch.";
            case "3":
                return "Không nhận được kết quả phản hồi từ Tổ chức phát hành thẻ.";
            case "4":
                return "Tháng/Năm hết hạn của thẻ không đúng hoặc thẻ đã hết hạn sử dụng.";
            case "5":
                return "Số dư/Hạn mức của thẻ không đủ để thanh toán.";
            case "6":
                return "Không nhận được kết quả phản hồi từ Tổ chức phát hành thẻ.";
            case "7":
                return "Lỗi trong quá trình xử lý giao dịch của Ngân hàng.";
            case "8":
                return "Ngân hàng phát hành thẻ không hỗ trợ thanh toán trực tuyến.";
            case "9":
                return "Tên chủ thẻ/tài khoản không hợp lệ.";
            case "10":
                return "Thẻ hết hạn/Thẻ bị khóa.";
            case "11":
                return "Thẻ/Tài khoản chưa đăng ký dịch vụ hỗ trợ thanh toán trực tuyến.";
            case "12":
                return "Tháng/Năm phát hành hoặc hết hạn của thẻ không hợp lệ.";
            case "13":
                return "Giao dịch vượt quá hạn mức thanh toán trực tuyến theo quy định của Ngân hàng.";
            case "14":
                return "Số thẻ không hợp lệ.";
            case "21":
                return "Số dư tài khoản không đủ để thanh toán.";
            case "22":
                return "Thông tin tài khoản không hợp lệ.";
            case "23":
                return "Thẻ/Tài khoản bị khóa hoặc chưa được kích hoạt.";
            case "24":
                return "Thông tin thẻ/tài khoản không hợp lệ.";
            case "25":
                return "Mã xác thực OTP không hợp lệ.";
            case "26":
                return "Mã xác thực OTP đã hết hiệu lực.";
            case "98":
                return "Xác thực giao dịch bị hủy.";
            case "99":
                return "Người dùng hủy giao dịch.";
            case "B":
                return "Lỗi trong quá trình xác thực giao dịch của Ngân hàng phát hành thẻ.";
            case "D":
                return "Lỗi trong quá trình xác thực giao dịch của Ngân hàng phát hành thẻ.";
            case "F":
                return "Xác thực giao dịch không thành công.";
            case "U":
                return "Xác thực mã CSC không thành công.";
            case "Z":
                return "Giao dịch bị từ chối.";
            case "253":
                return "Hết thời hạn nhập thông tin thanh toán.";
            default:
                return "Lỗi không xác định";
        }
    };

    const getTextClassColor = (status) => {
        if (status === "success") {
            return "text-green-600";
        }
        if (status === "fail") {
            return "text-red-600";
        }
        return "text-gray-600";
    };

    const getBackgroundClassColor = (status) => {
        if (status === "success") {
            return "bg-green-600";
        }
        if (status === "fail") {
            return "bg-red-600";
        }
        return "bg-gray-600";
    };

    const getClassIcon = (status) => {
        if (status === "success") {
            return "fa-solid fa-check";
        }
        if (status === "fail") {
            return "fa-solid fa-xmark";
        }
        return "fa-solid fa-check";
    };

    return (
        <>
            <Loading loading={loading} />
            <PaymentCompletedForm status={status} message={message} orderId={orderId} email={email} linkFileCertificate={linkFileCertificate} note={note} />
        </>
    );
}
