import React from 'react'

export default function SearchResultMainLayout({ children }) {
  return (
    // It's a layout for SearchResult page
    // In the full-page layout, the children will be separated into 2 parts: sidebar and main content
    // In the mobile layout, the children will be displayed continuously in the main content
    <div
      className="
        w-full
        flex
        flex-col
        lg:flex-row
        lg:justify-between
    "
    >
      {children}
    </div>
  )
}
