import React from 'react'

export default function AuthInput({ id, type = 'text', placeholder, name, maxLength = 255, value, onChange, required = false, error = {} }) {
    return (
        <>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                className={`w-full py-2 px-2 border-b-2 ${error[name] ? 'border-red-600' : 'border-[#004caf] placeholder-shown:border-gray-500'} focus:outline-none h-[40px]  text-lg  autofill:bg-green-100`}
                id={id}
                placeholder={placeholder}
                maxLength={maxLength}
                required={required}
            />
            {error[name] && <p className="mt-1 text-red-600 text-sm text-left">{error[name]}</p>}
        </>
    )
}
