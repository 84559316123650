import { useEffect, useState, useRef } from "react";
import Axios from "../functions/axios";
import CommonInput from "../components/Input/CommonInput";
import {
  getPriceInfoVNIDefault,
  getDateEndContract,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
  createBodyInsurancesCar,
  isRequiredLoad,
  getPriceCarHDI,
  getPriceCarVNI,
  getPriceInfoAAADefault,
  getPriceCarAAA,
} from "../functions";
import TitleBanner from "../components/Banner/TitleBanner";
import CameraSection from "../components/Camera/CameraSection";
import PaymentMethodSection from "../components/PaymentMethod/PaymentMethodSection";
import axios from "axios";
import qs from "qs";
import PriceFooter from "../components/Footer/PriceFooter";
import ShopLayout from "../layouts/ShopLayout";
import InsuranceProviderSection from "../components/InsuranceProvider/InsuranceProviderSection";
import ContactRegisterSection from "../components/ContactRegister/ContactRegisterSection";
import { useDispatch } from "react-redux";
import { addSuccessModal } from "../reducers/modal/dialogModalSlice";
import Loading from "../components/Loading/Loading";
import { useParams } from "react-router";

export default function CarInsuranceForm() {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [price, setPrice] = useState(0);
  const [uri, setUri] = useState("");
  const [error, setError] = useState({
    plateNumber: "",
    fullName: "",
    address: "",
    engineNumber: "",
    chassisNumber: "",
  });

  const [loading, setLoading] = useState(false);
  const [plateNumber, setPlateNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [engineNumber, setEngineNumber] = useState("");
  const [chassisNumber, setChassisNumber] = useState("");
  const [dateStartContract, setDateStartContract] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dateEndContract, setDateEndContract] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [maxDateEndContract, setMaxDateEndContract] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [seat, setSeat] = useState(5);
  const [thirdParty, setThirdParty] = useState(true);
  const [selectedInsuranceForCarSitter, setSelectedInsuranceForCarSitter] =
    useState("NTX10");
  const [optionsInsuranceForCarSitter, setOptionsInsuranceForCarSitter] =
    useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState("KKD"); //kd = kinh doanh, kkd = không kinh doanh
  const [selectedCarType, setSelectedCarType] = useState(null);
  const [selectedCarTypeString, setSelectedCarTypeString] = useState(null);

  const [optionsCarType, setOptionsCarType] = useState([]);

  const [renderOptionsCarType, setRenderOptionsCarType] = useState([]);

  const [paymentMethod, setPaymentMethod] = useState(10);
  const [bankCodeZalo, setBankCodeZalo] = useState("zalopayapp");
  const [insuranceProvider, setInsuranceProvider] = useState("AAA");
  const [previewBody, setPreviewBody] = useState("");
  const [showLoad, setShowLoad] = useState(false);
  const [load, setLoad] = useState("");

  // Khi tạo đơn VNI, cần lấy vài thông tin giá bảo hiểm từ VNI
  const [priceInfoVNI, setPriceInfoVNI] = useState(getPriceInfoVNIDefault());
  // Khi tạo đơn AAA, cần lấy vài thông tin giá bảo hiểm từ AAA
  const [priceInfoAAA, setPriceInfoAAA] = useState(getPriceInfoAAADefault());

  useEffect(() => {
    const _dateEndContract = getDateEndContract({
      dateStartContract,
      yearCount: 1,
    });
    setDateEndContract(_dateEndContract);
    setMaxDateEndContract(_dateEndContract);
  }, [dateStartContract]);

  useEffect(() => {
    Axios.get("/api/Insurance/GetAllBaoHiemNguoiNgoiTrenXe").then(
      (response) => {
        setOptionsInsuranceForCarSitter(response.data.data);
      }
    );
  }, []);

  useEffect(() => {
    if (orderId) {
      setLoading(true);
      Axios.get(`/api/insurance/GetInsuranceOrderById/${orderId}`)
        .then((res) => {
          const data = res.data.data;
          setPlateNumber(data.detail.plateNumber);
          setFullName(data.detail.fullName);
          setAddress(data.detail.address);
          setPhoneNumber(data.detail.phoneNumber);
          setEmail(data.detail.email);
          setThirdParty(data.detail.thirdParty);
          setDateStartContract(() => {
            let date = new Date(data.detail.expireDate);
            if (date.valueOf() < new Date().valueOf()) date = new Date();
            return date.toISOString().split("T")[0];
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  //Nếu có tick vào ô bảo hiểm người ngồi trên xe và đã fetch các option đó về thì set option đầu tiên là selected
  useEffect(() => {
    if (thirdParty && optionsInsuranceForCarSitter.length > 0)
      setSelectedInsuranceForCarSitter("NTX10");
    else if (thirdParty === false) setSelectedInsuranceForCarSitter("");
    // else if (selectedInsuranceForCarSitter != null) setSelectedInsuranceForCarSitter("");
  }, [thirdParty, optionsInsuranceForCarSitter]);

  useEffect(() => {
    console.log("insuranceProvider", insuranceProvider);
    Axios.get("/api/Insurance/GetAllCarTypeV2").then((response) => {
      const optionsCarType = response.data.data?.filter((item) => {
        return item.nhaBaoHiem === insuranceProvider;
      });
      console.log("optionsCarType", optionsCarType);
      setOptionsCarType(optionsCarType);
      setRenderOptionsCarType(optionsCarType);
    });
  }, [insuranceProvider]);

  useEffect(() => {
    if (optionsCarType.length === 0) return;
    const renderOptionsCarType = optionsCarType.filter(
      (option) => option[selectedBusiness.toLocaleLowerCase()] === true
    );
    console.log("renderOptionsCarType", renderOptionsCarType);
    setRenderOptionsCarType(renderOptionsCarType);
  }, [selectedBusiness]);

  //Khi đổi từ xe kinh doanh sang ko kinh doanh thì nó chọn mặc định một loại xe cho mình
  useEffect(() => {
    if (insuranceProvider === "HDI") {
      if (selectedBusiness === "KKD") setSelectedCarType("XE_CN1");
      if (selectedBusiness === "KD") setSelectedCarType("XE_CN2");
    } else if (insuranceProvider === "VNI") {
      if (selectedBusiness === "KKD") setSelectedCarType("OTO_KKDVT_U6");
      if (selectedBusiness === "KD") setSelectedCarType("OTO_HANG_U3");
    } else if (insuranceProvider === "AAA") {
      if (selectedBusiness === "KKD") {
        setSelectedCarType("KKD1");
        setSelectedCarTypeString("Loại xe dưới 6 chỗ ngồi");
      }
      if (selectedBusiness === "KD") {
        setSelectedCarType("KKD2-1");
        setSelectedCarTypeString(
          "Xe vừa chở người vừa chở hàng (Pickup, minivan)"
        );
      }
    }
    // setPrice(0);
  }, [selectedBusiness, optionsCarType]);

  //nếu khách hàng vừa chọn xe, thì xem xem xe có cần có trường trọng tải không
  useEffect(() => {
    if (!optionsCarType.length) return;
    const trongTai = isRequiredLoad({ optionsCarType, selectedCarType });
    if (!!trongTai === false) {
      setShowLoad(false);
      setLoad("");
      return;
    } else {
      setShowLoad(true);
    }
  }, [selectedCarType]);

  // Tính giá bảo hiểm xe ô tô
  useEffect(() => {
    if (selectedCarType === "" || selectedCarType === null) return;
    setLoading(true);
    if (insuranceProvider === "HDI") {
      getPriceCarHDI({
        selectedCarType,
        seat,
        selectedInsuranceForCarSitter,
        selectedBusiness,
        load,
        dateStartContract,
        optionsCarType,
      })
        .then((res) => {
          setPrice(res.price);
        })
        .catch(() => {
          setPrice(0);
        })
        .finally(() => setLoading(false));
    } else if (insuranceProvider === "VNI") {
      getPriceCarVNI({
        selectedCarType,
        seat,
        selectedInsuranceForCarSitter,
        selectedBusiness,
        load,
        dateStartContract,
        thirdParty,
      })
        .then((res) => {
          setPrice(res.price);
          setPriceInfoVNI(res.priceInfoVNI);
        })
        .catch(() => {
          setPrice(0);
        })
        .finally(() => setLoading(false));
      // setLoading(false)
    } else if (insuranceProvider === "AAA") {
      getPriceCarAAA({
        selectedCarType,
        seat,
        selectedInsuranceForCarSitter,
        selectedBusiness,
        load,
        dateStartContract,
        thirdParty,
        BaoHiemNguoiNgoiTrenXe:
          selectedInsuranceForCarSitter === "NTX10" ? true : false, //NTX10 có bảo hiểm người ngồi trên xe
      })
        .then((res) => {
          setLoading(false);
          setPrice(res.price);
          setPriceInfoAAA(res.priceInfoAAA);
        })
        .catch(() => {
          setPrice(0);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  }, [
    selectedCarType,
    seat,
    selectedInsuranceForCarSitter,
    load,
    dateStartContract,
    selectedBusiness,
    insuranceProvider,
    thirdParty,
  ]);

  // Dev, preview body xem body sao
  // useEffect(() => {
  //   const _previewBody = JSON.stringify(
  //     createBodyInsurancesCar({
  //       plateNumber,
  //       engineNumber,
  //       chassisNumber,
  //       fullName,
  //       address,
  //       phoneNumber,
  //       email,
  //       price,
  //       paymentMethod,
  //       thirdParty,
  //       dateStartContract,
  //       dateEndContract,
  //       insuranceProvider,
  //       load,
  //       seat,
  //       selectedCarType,
  //       selectedBusiness,
  //       selectedInsuranceForCarSitter,
  //       priceInfoVNI,
  //       priceInfoAAA,
  //     })
  //   );

  //   setPreviewBody(_previewBody);
  // }, [
  //   plateNumber,
  //   engineNumber,
  //   chassisNumber,
  //   fullName,
  //   address,
  //   phoneNumber,
  //   email,
  //   price,
  //   paymentMethod,
  //   thirdParty,
  //   dateStartContract,
  //   dateEndContract,
  //   insuranceProvider,
  //   load,
  //   seat,
  //   selectedCarType,
  //   selectedBusiness,
  //   selectedInsuranceForCarSitter,
  //   priceInfoVNI,
  //   priceInfoAAA,
  // ]);

  const isValidated = () => {
    let validated = true;
    let _error = {};
    if (phoneNumber === "") {
      validated = false;
      _error.phoneNumber = "Vui lòng nhập số điện thoại";
    } else if (!isValidPhoneNumber(phoneNumber)) {
      validated = false;
      _error.phoneNumber = "Số điện thoại không hợp lệ";
    }
    if (email === "") {
      validated = false;
      _error.email = "Vui lòng nhập email";
    } else if (!isValidEmail(email)) {
      validated = false;
      _error.email = "Email không hợp lệ";
    }

    if (plateNumber === "") {
      validated = false;
      _error.plateNumber = "Vui lòng nhập biển số xe";
    }

    if (fullName === "") {
      validated = false;
      _error.fullName = "Vui lòng nhập họ tên";
    } else if (!isValidName(fullName)) {
      validated = false;
      _error.fullName = "Họ tên không hợp lệ";
    }

    if (address === "") {
      validated = false;
      _error.address = "Vui lòng nhập địa chỉ";
    }
    if (selectedCarType === "") {
      validated = false;
      _error.selectedCarType = "Vui lòng chọn loại xe";
    }
    if (showLoad && load === "") {
      validated = false;
      _error.load = "Vui lòng nhập trọng tải";
    } else if (showLoad && (Number(load) <= 0 || Number(load) > 50)) {
      validated = false;
      _error.load = "Trọng tải chỉ được trong khoảng từ lớn hơn 0 đến 50";
    }

    setError(_error);
    return validated;
  };

  const handlePaymentOnClick = ({ nguoiGioiThieu = "" }) => {
    if (loading) return;
    handlePayment({
      nguoiGioiThieu,
    });
  };

  const scrollToInput = () => {
    formRef.current.scrollIntoView();
  };

  // Tạo đơn bảo hiểm khi người dùng ấn thanh toán
  const handlePayment = ({ nguoiGioiThieu = "" }) => {
    if (!isValidated()) return scrollToInput();
    // Tạo body tạo đơn bảo hiểm
    const body = createBodyInsurancesCar({
      plateNumber,
      engineNumber,
      chassisNumber,
      fullName,
      address,
      phoneNumber,
      email,
      price,
      paymentMethod,
      thirdParty,
      dateStartContract,
      dateEndContract,
      insuranceProvider,
      load,
      seat,
      selectedCarType,
      selectedBusiness,
      selectedInsuranceForCarSitter,
      priceInfoVNI,
      priceInfoAAA,
      nguoiGioiThieu,
    });

    // // mục đích cho dev, bỏ comment dòng dưới để xem body khi ấn nút thanh toán
    // return setPreviewBody(JSON.stringify(body));

    setLoading(true);

    Axios.post("/api/insurance/CreateInsurance", body)
      .then((response) => {
        console.log(response);
        const orderId = response.data.data?.id;
        if (!orderId) return alert("Có lỗi xảy ra vui lòng quay lại sau");
        let params = {};
        if (paymentMethod === 10) {
          params = {
            VoucherOrderId: orderId,
            AppId: 7,
            redirectUrl:
              window.location.origin +
              `/payment/completed?paymentMethod=${paymentMethod}`,
          };
        } else {
          params = {
            orderId: orderId,
            repay: false,
            appId: 7, //web
            redirectUrl:
              window.location.origin +
              `/payment/completed?paymentMethod=${paymentMethod}`,
          };
        }
        // ZaloPay
        if (paymentMethod === 12) {
          params = {
            VoucherOrderId: orderId,
            appId: 7,
            redirectUrl:
              window.location.origin +
              `/payment/completed?paymentMethod=${paymentMethod}&orderId=${orderId}`,
            bankcode: "zalopayapp",
          };
        }
        // Axios.post('/api/userrole/userjoint', { email: email, fullName: fullName, phoneNumber: phoneNumber, linkEmailRegister: `${window.location.origin}/activate-account?email=${email}&token=` })
        //   .then(() => {
        const stringParams = new URLSearchParams(params).toString();
        let url = "/api/ProductOnline";
        if (paymentMethod === 2)
          url = "/api/ProductOnline/PayByMomo" + "?" + stringParams;
        if (paymentMethod === 3)
          url = "/api/ProductOnline/GeneratePayooOrder" + "?" + stringParams;
        if (paymentMethod === 10)
          url = "/api/ProductOnline/PayByOnePay" + "?" + stringParams;
        if (paymentMethod === 12)
          url = "/api/ProductOnline/PayByZalo" + "?" + stringParams;

        Axios.post(url)
          .then((response) => {
            if (paymentMethod === 3) {
              const params = {
                data: response.data.data.payooOrder.payooOrder,
                checksum: response.data.data.payooOrder.payooOrderCheckSum,
                refer: window.location.origin,
                payment_group: "",
              };
              axios({
                method: "POST",
                headers: {
                  "content-type": "application/x-www-form-urlencoded",
                },
                data: qs.stringify(params),
                url: "https://newsandbox.payoo.com.vn/v2/paynow/order/create",
              }).then((response) => {
                const url = response.data.order?.payment_url;
                if (!url) return alert("Có lỗi xảy ra vui lòng quay lại sau");
                window.location.href = response.data.order.payment_url;
              });
            }
            //Momo hoặc onepay thì không cần gọi api lần nữa
            if (
              paymentMethod === 2 ||
              paymentMethod === 10 ||
              paymentMethod === 12
            ) {
              console.log(response);
              const url = response.data.data?.res;
              if (!url) return alert("Có lỗi xảy ra vui lòng quay lại sau");
              window.location.href = url;
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra vui lòng quay lại sau");
          })
          .finally(() => {
            setLoading(false);
          });
      })
      //     .catch(() => {
      //       alert("Có lỗi xảy ra vui lòng quay lại sau");
      //       setLoading(false);
      //     })
      // })
      .catch(() => {
        alert("Thanh toán thất bại");
        setLoading(false);
      });
  };

  function handleTakePhotoAnimationDone(dataUri) {
    setUri(dataUri);
  }

  // const handleCameraError = () => {};
  // const handleCameraStart = () => {};
  // const handleCameraStop = () => {};

  const handleInsuranceForSitter = () => {
    if (thirdParty)
      return dispatch(
        addSuccessModal({
          title: "XÁC NHẬN HỦY",
          content:
            "<div className='text-lg uppercase'><b>HỦY ĐĂNG KÝ BẢO HIỂM TỰ NGUYỆN</b> sẽ bỏ đi rất nhiều <b>quyền lợi của bạn và người ngồi sau xe</b>! Bạn có chắc chắn muôn bỏ?</div>",
          rightButtonText: "XÁC NHẬN",
          rightButtonFunction: () => setThirdParty(false),
        })
      );
    setThirdParty(!selectedInsuranceForCarSitter);
  };
  const [showCam, setShowCam] = useState(0);
  const handleShowCam = () => setShowCam(true);
  const handleInput = (cavet) => {
    console.log(cavet);
    setPlateNumber(cavet.plate ? cavet.plate : "");
    setFullName(cavet.vehical_owner_name ? cavet.vehical_owner_name : "");
    setAddress(cavet.vehical_owner_address ? cavet.vehical_owner_address : "");
    // setEngineNuber(cavet.engine ? cavet.engine : "");
    // setChassisNummber(cavet.chassis ? cavet.chassis : "");
    if (
      Number(cavet.sit) > 0 &&
      !Number.isNaN(Number(cavet.sit)) &&
      Number(cavet.sit) <= 60
    )
      setSeat(Number(cavet.sit));
    // setSeat(cavet.sit ? cavet.sit : 1);
  };

  // const [typeBusiness, setTypeBusiness] = useState()

  return (
    <ShopLayout>
      <Loading loading={loading} opacity={0.5} />
      {previewBody !== "{}" ? previewBody : ""}
      <div
        className="bg-cover bg-center"
        style={{ backgroundImage: 'url("/images/bg-shop.webp")' }}
      >
        <div className="max-w-[1200px]  bg-white mx-auto border pt-4">
          <TitleBanner title={"NHÀ BẢO HIỂM"} />
          <InsuranceProviderSection
            insuranceProvider={insuranceProvider}
            handleInsuranceProvider={(value) => setInsuranceProvider(value)}
            hiddenSections={["bsh", "vni"]}
          />

          <TitleBanner title={"MỤC ĐÍCH SỬ DỤNG"} />

          <div className="mb-5 px-5">
            <div className="mb-2">
              <label className="font-bold text-[20px]">Mục đích sử dụng</label>
              <span className="text-red-600 text-[20px]">*</span>
            </div>
            <div className="flex gap-1 lg:flex-row xs:flex-col xs:w-full">
              <button
                onClick={() => {
                  setSelectedBusiness("KKD");
                }}
                className={`h-[35px] lg:w-1/2 w-full flex items-center justify-center border-2 font-bold rounded-[4px] ${
                  selectedBusiness === "KKD"
                    ? "border-primary-color text-primary-color"
                    : "border-gray-400"
                }`}
              >
                {selectedBusiness === "KKD" ? (
                  <i className="mr-2 flex items-center">
                    <ion-icon name="shield-checkmark-outline"></ion-icon>
                  </i>
                ) : (
                  ""
                )}
                Không kinh doanh
              </button>
              <button
                onClick={() => {
                  setSelectedBusiness("KD");
                }}
                className={`h-[35px] lg:w-1/2 w-full flex items-center justify-center border-2 font-bold rounded-[4px] ${
                  selectedBusiness === "KD"
                    ? "border-primary-color text-primary-color"
                    : "border-gray-400"
                }`}
              >
                {selectedBusiness === "KD" ? (
                  <i className="mr-2 flex items-center">
                    <ion-icon name="shield-checkmark-outline"></ion-icon>
                  </i>
                ) : (
                  ""
                )}
                Kinh doanh
              </button>
            </div>
          </div>

          <div className="mb-5 px-5">
            <label className="font-bold text-[20px]">Loại xe</label>
            <span className="text-red-600 text-[20px]">*</span>
            <select
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedCarType(
                  renderOptionsCarType.find(
                    (item) => item.name === e.target.value
                  ).value
                );
                setSelectedCarTypeString(e.target.value);
              }}
              value={selectedCarTypeString}
              className="w-full rounded-[4px] px-2 border-b-2 border-[#004caf] placeholder-shown:border-gray-500 focus:outline-none h-[40px]  text-lg  mt-4"
            >
              {/* <option value={""}>----Chọn loại xe----</option> */}
              {renderOptionsCarType &&
                renderOptionsCarType.length > 0 &&
                renderOptionsCarType.map((option) => (
                  <option
                    value={option.name}
                    key={option.name}
                    selected={option.name === selectedCarTypeString}
                  >
                    {option.name}
                  </option>
                ))}
            </select>
            {error.selectedCarType && (
              <span className="text-red-600">{error.selectedCarType}</span>
            )}
          </div>

          {showLoad && (
            <div className="px-5">
              <span className="text-yellow-600">
                *Đối với một số xe sẽ cần thêm thông tin về trọng tải, vui lòng
                điền trọng tải
              </span>
              <div className="md:w-1/2 xl:w-1/3">
                <CommonInput
                  field="Trọng tải"
                  name="load"
                  value={load}
                  onChange={(e) => setLoad(e.target.value)}
                  placeholder="Điền trọng tải xe (đơn vị Tấn) VD: 4"
                  maxLength={2}
                  error={error.load}
                  type="number"
                  required
                />
              </div>
            </div>
          )}
          <TitleBanner title="THÔNG TIN ĐĂNG KÝ" />
          <CameraSection
            uri={uri}
            handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
            handleShowCam={handleShowCam}
            showCam={showCam}
            onUpload={handleInput}
            scrollToInput={scrollToInput}
          />

          <form ref={formRef} className="">
            <div className="px-5">
              <CommonInput
                field="Tên chủ xe"
                name="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Điền tên chủ xe. vd: Nguyen Van A"
                maxLength={50}
                error={error.fullName}
                required
              />

              <CommonInput
                field="Địa chỉ"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Điền địa chỉ. vd: 123 Đường ABC, Phường XYZ, Quận 1, TP.HCM"
                maxLength={100}
                error={error.address}
                required
              />
              <CommonInput
                field="Biển số xe"
                name="plateNumber"
                value={plateNumber}
                onChange={(e) => setPlateNumber(e.target.value)}
                placeholder="Điền biển số xe. vd: 59T1-999.99"
                maxLength={11}
                error={error.plateNumber}
                required
              />
              <div></div>
              {/* <CommonInput
                field="Số khung"
                name="chassisNumber"
                value={chassisNumber}
                onChange={(e) => setChassisNumber(e.target.value)}
                placeholder="Điền số khung...."
                maxLength={30}
                error={error.chassisNumber}
                note
              >
                jksbdjbsjbj
              </CommonInput>
              <CommonInput
                field="Số máy"
                name="engineNumber"
                value={engineNumber}
                onChange={(e) => setEngineNumber(e.target.value)}
                placeholder="Điền số máy...."
                maxLength={30}
                error={error.engineNumber}
                note
              /> */}

              <div className="mb-5">
                <label className="font-bold">Số chỗ ngồi</label>
                <span className="text-red-600 text-[20px]">*</span>
                {/* <div className="w-full flex items-center justify-between py-2">
                <button
                  type="button"
                  onClick={() => (seat > 1 ? setSeat(seat - 1) : "")}
                  className="h-7 w-7 text-[25px] font-bold mr-5 bg-gray-300 flex items-center justify-center rounded-[5px]"
                >
                  -
                </button>
                <input
                  type="range"
                  min="1"
                  max="60"
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  value={seat}
                  onChange={(e) => setSeat(Number(e.target.value))}
                ></input>
                <button
                  type="button"
                  onClick={() => (seat < 60 ? setSeat(seat + 1) : "")}
                  className="h-7 w-7 text-[25px] font-bold ml-5 bg-gray-300 flex items-center justify-center rounded-[5px]"
                >
                  +
                </button>
              </div>
              <span className="w-full">Bạn đang chọn {seat}</span> */}
                <select
                  onChange={(e) => {
                    setSeat(e.target.value);
                  }}
                  type="text"
                  className="w-full rounded-[4px] px-2 border-b-2 border-[#004caf] placeholder-shown:border-gray-500 focus:outline-none h-[40px]  text-lg  mt-4"
                >
                  {[...Array(60)].map((value, index) => (
                    <option key={index + 1} selected={index + 1 === seat}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <ContactRegisterSection
              phoneNumber={phoneNumber}
              email={email}
              setEmail={setEmail}
              setPhoneNumber={setPhoneNumber}
              error={error}
            />
          </form>
          <TitleBanner title={"ThỜI HẠN ĐĂNG KÝ"} />

          <div className="px-5">
            <div className="mb-4">
              <label className="font-bold">
                Ngày đăng ký<span className="text-red-600 text-[20px]">*</span>
              </label>
              <div className="md:w-1/3">
                <input
                  type="date"
                  onChange={(e) => {
                    setDateStartContract(e.target.value);
                  }}
                  value={dateStartContract}
                  min={new Date().toISOString().split("T")[0]}
                  className="w-full rounded-[4px] px-2 border-b-2 border-[#004caf] placeholder-shown:border-gray-500 focus:outline-none h-[40px]  text-lg  mt-2"
                ></input>
              </div>
            </div>

            <div className="mb-4">
              <label className="font-bold">
                Ngày hết hạn<span className="text-red-600 text-[20px]">*</span>
              </label>
              <div className="md:w-1/3">
                <input
                  type="date"
                  onChange={(e) => setDateEndContract(e.target.value)}
                  value={dateEndContract}
                  min={new Date().toISOString().split("T")[0]}
                  max={maxDateEndContract}
                  className="w-full rounded-[4px] px-2 border-b-2 border-[#004caf] placeholder-shown:border-gray-500 focus:outline-none h-[40px]  text-lg  mt-2"
                  disabled
                ></input>
              </div>
            </div>
          </div>

          <TitleBanner title={"QUYỀN LỢI BẢO HIỂM"} />

          <div className="w-[90%] m-auto pb-[5px]">
            {/* <h2 className="text-[20px] font-bold">QUYỀN LỢI KHI CÓ BẢO HIỂM: <span className="text-red-600">*</span></h2> */}
            <div className="flex">
              <i className="text-[18px] items-center justify-center mr-[5px]">
                <ion-icon name="checkbox-outline"></ion-icon>
              </i>
              <h2 className="text-[15px]">
                Bồi thường thiệt hại về sức khỏe, tính mạng cho người bị đụng
                đến 150 triệu đồng/người/vụ.
              </h2>
            </div>
            <div className="flex">
              <i className="text-[18px] items-center justify-center mr-[5px]">
                <ion-icon name="checkbox-outline"></ion-icon>
              </i>
              <h2 className="text-[15px]">
                Bồi thường thiệt hại về tài sản cho người bị đụng đến 50 triệu
                đồng/vụ cho xe máy, đến 100 triệu đồng/vụ cho xe ôtô.
              </h2>
            </div>

            <div className="flex">
              <i className="text-[18px] items-center justify-center mr-[5px]">
                <ion-icon name="checkbox-outline"></ion-icon>
              </i>
              <h2 className="text-[15px]">
                Nhận giấy chứng nhận điện tử hợp pháp, không cần mang thẻ giấy.
              </h2>
            </div>
          </div>
          <div className="text-[15px] pl-[20px] pb-[8px] text-gray-600 font-medium">
            Tóm tắt Quyền lợi bảo hiểm xem{" "}
            <a
              target="_blank"
              className="hover:opacity-75 cursor-pointer text-blue-600 font-medium"
              href="/poclicy/interests"
            >
              tại đây
            </a>
          </div>

          <TitleBanner title={"PHƯƠNG THỨC THANH TOÁN"} />
          <PaymentMethodSection
            paymentMethod={paymentMethod}
            bankCodeZalo={bankCodeZalo}
            handlePaymentMethod={(value) => setPaymentMethod(value)}
            handleBankCodeZalo={(value) => setBankCodeZalo(value)}
          />
          <TitleBanner title={"BẢO HIỂM TAI NẠN"} />
          <div className="mb-5 flex items-center px-5">
            <input
              type="checkbox"
              id="insuranceForSitter"
              checked={thirdParty}
              onChange={handleInsuranceForSitter}
            ></input>
            <label className="ml-2" htmlFor="insuranceForSitter">
              Bảo hiểm tai nạn cho người lái xe, phụ xe & người ngồi trên xe
            </label>
          </div>

          {selectedInsuranceForCarSitter &&
            insuranceProvider !== "VNI" &&
            insuranceProvider !== "AAA" && (
              <div className="mb-5 px-5">
                <select
                  onChange={(e) => {
                    setSelectedInsuranceForCarSitter(e.target.value);
                  }}
                  value={selectedInsuranceForCarSitter}
                  className="w-full rounded-[4px] px-2 border-b-2 border-[#004caf] placeholder-shown:border-gray-500 focus:outline-none h-[40px]  text-lg  "
                >
                  {optionsInsuranceForCarSitter.map((option) => (
                    <option
                      value={option.value}
                      key={option.value}
                      selected={option.value === selectedInsuranceForCarSitter}
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
                {/* {selectedInsuranceForCarSitter} */}
              </div>
            )}
          <PriceFooter
            loading={loading}
            price={price}
            handlePaymentOnClick={handlePaymentOnClick}
          />
        </div>
      </div>
    </ShopLayout>
  );
}
