import React, { useEffect, useState } from 'react'
import Download from '../../../components/Button/Download'
import { downloadFile } from '../../../functions/downloadfile'
export default function ViewDetailLayout({
  selectedItem = null,
  listResult = [],
}) {
  const [url, setUrl] = useState('')
  useEffect(() => {
    if (selectedItem) {
      const url = listResult[selectedItem - 1].fileCertificate
      setUrl(url)
    } else {
      setUrl('')
    }
  }, [selectedItem])

  return (
    // Đây là phần hiển thị chi tiết bảo hiêm của từng kết quả tìm kiếm
    // Ở chế độ full page nó sẽ có chiều rộng 3/4 trang (w-3/4)
    // Ở chế độ mobile nó sẽ có chiều rộng 100% (w-full)
    // Nó chứa pdf viewer để hiển thị file pdf
    <div
      className="
            w-full
            lg:w-3/4
            flex
            flex-col
            items-center
            justify-center
            lg:h-screen
            relative
            h-screen
        "
      style={{
        backgroundImage: "url('/images/car.webp')",
        backgroundRepeat: 'repeat',
        // loop car background, each car is 100px
        backgroundSize: '80px',
        backgroundPosition: 'center',
      }}
    >
      {/*Nền*/}
      <div
        className="
                w-full
                h-full
                flex
                items-center
                justify-center
        "
        style={{
          backgroundColor: '#F5F5F5',
          opacity: '0.8',
        }}
      ></div>
      {/*Nội dung*/}
      <div
        className={`
                ${url !== '' ? 'lg:w-4/5 ' : 'lg:w-3/5 lg:h-3/4'}
                border-2
                border-solid
                border-primary-color
                h-[calc(100%-8rem)]
                absolute
                top-1/2
                left-1/2
                transform
                -translate-x-1/2
                -translate-y-1/2
                shadow-lg
                w-[calc(100%-1rem)]
                `}
      >
        <div
          className="
                    w-full
                    h-full
                    flex
                    items-end
                    justify-center
                    px-4
                    py-8
            "
          style={{
            backgroundImage:
              "url('/images/vecteezy_folder-open-data-storage-blue-icon-on-abstract-cloud-background_13295522.jpg')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
          }}
        >
          {/*Chữ*/}
          {url === '' ? (
            // Nếu url là rỗng thì sẽ hiển thị background
            <div
              className="
                w-full
              "
            >
              <span
                className="
                  text-sm
                  lg:text-base
                  font-bold
                  text-primary-color
                  text-center
                "
              >
                Xin chào! Vui lòng chọn một{' '}
                <span className="font-bold text-secondary-color">
                  kết quả tìm kiếm
                </span>{' '}
                để xem File Bảo hiểm nhé.
              </span>
            </div>
          ) : (
            // <verify-viewer
            //   url={url}
            //   show-signature-if-present="true"
            //   notify-if-not-signed="true"
            // ></verify-viewer>
            <div
              className="
                w-full
                h-full
                overflow-hidden
              "
            >
              <div
                className="
                w-full
                flex
                justify-center
                items-center
                space-x-2
                mb-4
                "
              >
                <span
                  className="
                    text-sm
                    lg:text-base
                    font-bold
                    text-primary-color
                    lg:text-center
                  "
                >
                  Tải xuống file bảo hiểm{' '}
                </span>
                {/* <Download
                  onClick={() => {
                    downloadFile(url, `${listResult[selectedItem-1].insuranceFullName}.pdf`)
                  }}
                /> */}
              </div>
              <iframe src={url} width="100%" height="100%" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
