// import { useEffect, useState } from 'react'
// import PageNotFound from '../pages/PageNotFound';
// import bcrypt from 'bcryptjs';


export default function ({ children }) {
  // const [authenticated, setAuthenticated] = useState(null);

  // useEffect(() => {
  //   let textPassword = prompt('Enter the password to view the content');
  //   const authenticated = bcrypt.compareSync(textPassword, '$2a$13$k8JiFDbk6qXE6B8kCOcWIeHBCN3K9oHOsG.VqopH3xc6WnEhypZ2.');
  //   setAuthenticated(authenticated);
  // }, [])

  // if (authenticated === null) return;
  // if (authenticated === false) return <PageNotFound />

  return children;
}
