import React, { useEffect, useState } from 'react'
import ItemSearchResult from './Components/ItemResult/ItemSearchResult'
import InforDetail from '../InforDetail/InforDetail'
import { useLocation } from 'react-router-dom'

export default function ListResults({
  // Các props này được truyền từ component SearchResult
  listResult = [],
  selectedItem = null,
  setSelectedItem = () => {},
}) {
  const [isShowDetail, setIsShowDetail] = useState(false)
  useEffect(() => {
    if (selectedItem) {
      setIsShowDetail(true)
    }
  }, [selectedItem])

  // let item = {
  //   orderId: 45679,
  //   inceptionDate: '2022-09-06T00:00:00',
  //   expireDate: '2025-09-05T00:00:00',
  //   insuranceFullName: 'PHAN THANH VÂN',
  //   fileCertificate:
  //     'https://dev-hyperservices.hdinsurance.com.vn/f/a5791e8dd513402aba4f972ec08a52ff',
  //   netAmount: 218000.0,
  //   nhaBaoHiem: 'HDI',
  //   insuranceVehicle: 2,
  //   typeVehicle: 'Mô tô - xe máy',
  //   paymentType: 0,
  //   prettyPaymentType: 'Khác',
  // }
  return (
    <div
      className="
            w-full
            h-auto
            lg:max-h-[calc(100vh-20rem)]
            max-h-[calc(100vh-20rem)]
            overflow-auto
            lg:overflow-y-auto
            lg:overflow-x-hidden
            border-secondary-color
            border-solid
            border-t-2
            border-b-2
        "
    >
      <div
        className={`
                w-full
                h-full        
                flex-col
                items-center
                justify-between
                lg:justify-start
                lg:items-start
                lg:space-y-8
                space-y-6
                bg-[#F5F5F5]
                px-2
                py-6
                lg:shadow-inner 
                ${isShowDetail ? 'hidden' : 'flex'}
            `}
      >
        {/* <ItemSearchResult onClick={() => setSelectedItem(1)} item={item} />
        <ItemSearchResult onClick={() => setSelectedItem(1)} /> */}
        {listResult &&
          listResult.map((item, index) => (
            <ItemSearchResult
              onClick={() => setSelectedItem(index + 1)}
              item={item}
              key={item.orderId}
            />
          ))}
      </div>
      {/*Cái này sẽ được show up để hiện chi tiết thông tin của item được chọn*/}
      {/*Nó sẽ được show up khi mà người dùng click vào 1 item trong list kết quả tìm kiếm*/}
      <div
        className={`
                w-full
                h-full   
                ${isShowDetail ? 'flex' : 'hidden'}   
            `}
      >
        <InforDetail
          item={listResult[selectedItem - 1]}
          onBackClick={() => {
            setIsShowDetail(false)
            setSelectedItem(null)
          }}
        />
      </div>
    </div>
  )
}
