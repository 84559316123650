import React from "react";
import MainLayout from "../layouts/MainLayout";

const PaymentPolicy = () => {
    return (
        <MainLayout>
            <div className="bg-white m-auto w-[90%]">
                <div className="p-7">
                    <div className="text-center border-b border-solid border-slate-300 pb-[15px] mb-5 lg:flex lg:justify-between lg:items-center">
                        <h2 className="text-[20px] font-bold">Chính sách thanh toán</h2>
                        <div className="flex justify-center pt-3 lg:pt-0"><a href="/" className="text-gray-400 hover:text-black text-[15px]">Trang chủ</a><span className="mx-2">/</span><h3 className="font-semibold text-[15px]">Chính sách thanh toán</h3></div>
                    </div>
                    <div className="mb-5">
                        <ul className="text-[15px] pt-2 pl-5">– Thanh toán bằng hình thức chuyển khoản:
                            <li>- Thanh toán bằng ví điên tử MoMo</li>
                            <li>- Thanh toán bằng Payoo: Thẻ ngân hàng/ VISA/ Credit Card</li>
                            {/* <li>+ CTK : Công ty cổ phần Công nghệ Bagang</li>
                            <li>+ Số tài khoản : 2021122008</li>
                            <li>+ Tại Ngân hàng : Ngân hàng TMCP Á Châu(ACB) Chi nhánh Nguyễn Văn Trỗi</li> */}
                        </ul>
                    </div>

                </div>

            </div>
        </MainLayout>
    )
}

export default PaymentPolicy