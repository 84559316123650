import React from "react";
import MainLayout from "../layouts/MainLayout";

const InterestsPolicy = () => {
    return (
        <MainLayout>
            <div className="bg-white m-auto w-[90%]">
                <div className="p-5">
                    <div className="text-center border-b border-solid border-slate-300 pb-[15px] mb-5 lg:flex lg:justify-between lg:items-center">
                        <h2 className="text-[20px] font-bold">Chính sách tránh nhiệm và quyền lợi</h2>
                        <div className="flex justify-center pt-3 lg:pt-0"><a href="/" className="text-gray-400 hover:text-black text-[15px]">Trang chủ</a><span className="mx-2">/</span><h3 className="font-semibold text-[15px]">Chính sách tránh nhiệm và quyền lợi</h3></div>
                    </div>
                    <div className="mb-5">
                        <h2 className="border-b-2 border-[#bbbbbb] w-1/2 text-[#ee5959] font-medium text-[14px] mb-[20px] lg:w-[11%] lg:text-[17px]">Tóm tắt nghị định</h2>
                        <h2 className="text-center font-semibold text-[20px]">NHỮNG QUY ĐỊNH CHUNG</h2>
                        <div className="mt-5">
                            <h3 className="font-semibold">Điều 17. Quyền của bên mua bảo hiểm, người được bảo hiểm</h3>
                            <p className="text-[15px] pt-2 pl-5 pb-2 text-black">Ngoài các quyền quy định tại Luật Kinh doanh bảo hiểm, bên mua bảo hiểm, người được bảo hiểm có quyền tính phí bảo hiểm vào chi phí hoạt động kinh doanh trong trường hợp bên mua bảo hiểm là đơn vị sản xuất kinh doanh;
                                được bố trí phí bảo hiểm trong kinh phí hoạt động thường xuyên của cơ quan, đơn vị trong trường hợp bên mua bảo hiểm là cơ quan hành chính, đơn vị sự nghiệp của Nhà nước.</p>
                            <h3 className="font-semibold">Điều 18. Nghĩa vụ của bên mua bảo hiểm, người được bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Ngoài các nghĩa vụ quy định tại Luật Kinh doanh bảo hiểm, bên mua bảo hiểm, người được bảo hiểm có nghĩa vụ:
                                <li className="pb-2">1.	Phối hợp với doanh nghiệp bảo hiểm xem xét tình trạng xe trước khi cấp Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">2.	Kịp thời thông báo cho doanh nghiệp bảo hiểm để áp dụng phí bảo hiểm phù hợp cho thời gian còn lại của hợp đồng bảo hiểm trong trường hợp có sự thay đổi những yếu tố làm cơ sở để tính phí bảo hiểm, dẫn đến tăng các rủi ro được bảo hiểm.</li>
                                <li className="pb-2">3.	Luôn mang theo Giấy chứng nhận bảo hiểm còn hiệu lực (bản cứng hoặc bản điện tử) khi tham gia giao thông, xuất trình giấy tờ này khi có yêu cầu của lực lượng Cảnh sát giao thông và cơ quan chức năng có thẩm quyền khác theo quy định của pháp luật.</li>
                                <li className="pb-2">4.	Tuân thủ các quy định về đảm bảo an toàn giao thông đường bộ.</li>
                                <li className="pb-2">5.	Khi tai nạn giao thông xảy ra, phải có trách nhiệm:</li>
                                <li className="pb-2">a)	Thông báo ngay cho doanh nghiệp bảo hiểm để phối hợp giải quyết, tích cực cứu chữa, hạn chế thiệt hại về sức khỏe, tính mạng và tài sàn, bảo vệ hiện trường tai nạn; thông báo ngay cho cơ quan Công an hoặc chính quyền địa phương nơi gần nhất để phối hợp giải quyết vụ tai nạn giao thông theo quy định và phối hợp với cơ quan chức năng trong việc điều tra, xác minh nguyên nhân vụ tai nạn giao thông.</li>
                                <li className="pb-2">b)	Không được di chuyển, tháo gỡ hoặc sửa chữa tài sản khi chưa có ý kiến chấp thuận của doanh nghiệp bảo hiểm; trừ trường hợp cần thiết để đảm bảo an toàn, đề phòng hạn chế thiệt hại về sức khỏe, tính mạng và tài sản hoặc phải thi hành theo yêu cầu của cơ quan có thẩm quyền.</li>
                                <li className="pb-2">c)	Chủ động thu thập và cung cấp các tài liệu quy định trong hồ sơ bồi thường bảo hiểm thuộc trách nhiệm của bên mua bảo hiểm, người được bảo hiểm cho doanh nghiệp bảo hiểm quy định tại Điều 15 Nghị định này.</li>
                                <li className="pb-2">d)	Tạo điều kiện thuận lợi cho doanh nghiệp bảo hiểm trong quá trình xác minh các tài liệu do mình cung cấp.</li>
                                <li className="pb-2">6.	Thông báo và trả tiền bồi thường cho người bị thiệt hại hoặc người thừa kế hoặc đại diện của người bị thiệt hại biết số tiền họ được doanh nghiệp bảo hiểm trả đối với từng trường hợp thiệt hại về sức khỏe, tính mạng theo quy định tại điểm a khoản 3 Điều 14 Nghị định này.</li>
                                <li className="pb-2">7.	Thông báo bằng văn bản cho doanh nghiệp bảo hiểm theo quy định tại điểm a khoản 1 và điểm a khoản 3 Điều 11 Nghị định này.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 19. Quyền của doanh nghiệp bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Ngoài các quyền theo quy định tại Luật Kinh doanh bảo hiểm, doanh nghiệp bảo hiểm có quyền:
                                <li className="pb-2">1. Yêu cầu bên mua bảo hiểm, người được bảo hiểm cung cấp đầy đủ và trung thực những nội dung đã được quy định trong Giấy chứng nhận bảo hiểm; xem xét tình trạng xe cơ giới trước khi cấp Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">2. Giảm trừ tối đa 5% số tiền bồi thường thiệt hại đối với tài sản trong trường hợp bên mua bảo hiểm, người được bảo hiểm không thông báo tai nạn cho doanh nghiệp bảo hiểm theo quy định tại khoản 2 Điều 16 Nghị định này hoặc sau khi xảy ra sự kiện bảo hiểm, doanh nghiệp bảo hiểm phát hiện trong quá trình thực hiện hợp đồng bảo hiểm, bên mua bảo hiểm, người được bảo hiểm không thực hiện nghĩa vụ thông báo khi có sự thay đổi những yếu tố làm cơ sở để tính phí bảo hiểm, dẫn đến tăng các rủi ro được bảo hiểm.</li>
                                <li className="pb-2">3. Sau khi đã thực hiện tạm ứng bồi thường theo quy định tại điểm b khoản 2 Điều 14 Nghị định này, có quyền yêu cầu Ban điều hành Quỹ bảo hiểm xe cơ giới hoàn trả số tiền đã tạm ứng bồi thường trong trường hợp tai nạn được xác định thuộc loại trừ trách nhiệm bảo hiểm hoặc khoản tiền tạm ứng bồi thường vượt mức bồi thường bảo hiểm theo quy định trong trường hợp tai nạn thuộc phạm vi bồi thường thiệt hại.</li>
                                <li className="pb-2">4. Đề nghị cơ quan Công an cung cấp bản sao các tài liệu liên quan đến vụ tai nạn theo quy định tại khoản 4 Điều 15 Nghị định này.</li>
                                <li className="pb-2">5. Kiến nghị sửa đổi, bổ sung quy tắc, điều khoản, biểu phí bảo hiểm, mức trách nhiệm bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới phù hợp với thực tế triển khai loại hình bảo hiểm này.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 20. Nghĩa vụ của doanh nghiệp bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Ngoài các nghĩa vụ theo quy định tại Luật Kinh doanh bảo hiểm, doanh nghiệp bảo hiểm có nghĩa vụ:
                                <li className="pb-2">1. Bán bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới theo đúng điều kiện, mức phí bảo hiểm, mức trách nhiệm bảo hiểm quy định tại Nghị định này và cấp Giấy chứng nhận bảo hiểm cho bên mua bảo hiểm bao gồm các nội dung theo quy định tại khoản 3 Điều 6 Nghị định này.</li>
                                <li className="pb-2">2. Thiết lập, duy trì hoạt động 24 giờ/7 ngày đường dây nóng để kịp thời tiếp nhận thông tin tai nạn, hướng dẫn, giải đáp cho bên mua bảo hiểm, người được bảo hiểm và các bên có liên quan về các vấn đề liên quan tới bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                                <li className="pb-2">3. Tích hợp tính năng tra cứu Giấy chứng nhận bảo hiểm trên trang thông tin điện tử, cổng thông tin điện tử của doanh nghiệp bảo hiểm, cho phép cơ quan có chức năng thanh tra, kiểm tra, giám sát và bên mua bảo hiểm, người được bảo hiểm tra cứu, xác minh thời hạn và hiệu lực bảo hiểm của Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">4. Khi nhận được thông báo về tai nạn, trong vòng 1 giờ doanh nghiệp bảo hiểm phải hướng dẫn bên mua bảo hiểm, người được bảo hiểm, chủ xe cơ giới các biện pháp đảm bảo an toàn, đề phòng hạn chế thiệt hại về người và tài sản, hướng dẫn hồ sơ, thủ tục yêu cầu bồi thường bảo hiểm; phối hợp chặt chẽ với bên mua bảo hiểm, người được bảo hiểm, bên thứ ba và các bên liên quan trong vòng 24 giờ tổ chức thực hiện việc giám định tổn thất xác định nguyên nhân và mức độ tổn thất làm căn cứ giải quyết bồi thường bảo hiểm.</li>
                                <li className="pb-2">5. Thông báo cho bên mua bảo hiểm, người được bảo hiểm, người bị tai nạn biết số tiền bồi thường thiệt hại về sức khỏe, tính mạng và thanh toán số tiền bồi thường theo quy định tại điểm a khoản 3 Điều 14 Nghị định này.</li>
                                <li className="pb-2">6. Giải thích rõ quy tắc, điều khoản và biểu phí bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới, đảm bảo bên mua bảo hiểm, người được bảo hiểm phân biệt rõ giữa loại hình bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới với các loại hình bảo hiểm tự nguyện khác.</li>
                                <li className="pb-2">7. Chủ động thu thập các tài liệu quy định trong hồ sơ bồi thường bảo hiểm thuộc trách nhiệm của doanh nghiệp bảo hiểm quy định tại Điều 15 Nghị định này.</li>
                                <li className="pb-2">8. Tạm ứng bồi thường, chi trả bồi thường nhanh chóng và chính xác theo quy định của Nghị định này.</li>
                                <li className="pb-2">9. Thông báo bằng văn bản cho bên mua bảo hiểm theo quy định tại điểm c khoản 1, điểm a khoản 2 và điểm b khoản 3 Điều 11 Nghị định này.</li>
                                <li className="pb-2">10.	Thanh toán cho cơ quan Công an chi phí sao chụp những hồ sơ, biên bản tai nạn đã được cung cấp theo quy định pháp luật và có trách nhiệm giữ gìn bí mật trong quá trình điều tra.</li>
                                <li className="pb-2">11.	Trong vòng 15 ngày trước khi hết thời hạn bảo hiểm phải thông báo cho bên mua bảo hiểm, người được bảo hiểm về việc hết thời hạn của hợp đồng bảo hiểm.</li>
                                <li className="pb-2">12.	Đóng góp Quỹ bảo hiểm xe cơ giới theo quy định tại Điều 26 Nghị định này.</li>
                                <li className="pb-2">13.	Phải hạch toán tách biệt doanh thu phí bảo hiểm, hoa hồng, bồi thường và các khoản chi phí liên quan đến bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới. Chi phí liên quan đến bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới không bao gồm chi hỗ trợ đại lý ngoài hoa hồng bảo hiểm đại lý được hưởng theo quy định của Bộ Tài chính, chi khuyến mại và chiết khấu thanh toán dưới mọi hình thức.</li>
                                <li className="pb-2">14.	Xây dựng, triển khai, bố trí nhân sự kiểm soát thực hiện các quy chế, quy trình về hoạt động nghiệp vụ, kiểm soát rủi ro, kiểm soát nội bộ và phòng, chống gian lận trong kinh doanh bảo hiểm liên quan đến bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                                <li className="pb-2">15.	Chịu sự kiểm tra, giám sát của các cơ quan nhà nước có thẩm quyền trong việc thực hiện bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                                <li className="pb-2">16.	Tổ chức thực hiện chế độ bảo hiểm bắt buộc về trách nhiệm dân sự của chủ xe cơ giới.</li>
                            </ul>
                            <h2 className="text-center font-semibold text-[20px]">TRÁCH NHIỆM CỦA ĐẠI LÝ BẢO HIỂM (Bagang)</h2>
                            <ul>
                                <li className="pb-2">1. Yêu cầu doanh nghiệp bảo hiểm liên kết xử lý bồi thường có xảy ra sự kiện bồi thường bảo hiểm khi người mua bảo hiểm, người được bảo hiểm thông báo yêu cầu bồi thường đến đại lý.</li>
                                <li className="pb-2">2. Hỗ trợ hướng dẫn làm các thủ tục bồi thường để bảo vệ quyền lợi của người mua bảo hiểm, người được bảo hiểm.</li>
                                <li className="pb-2">3. Hỗ trợ giải quyết các sự cố trong quá trình mua bảo hiểm tại đại lý bảo hiểm.</li>
                                <li className="pb-2">4. Hỗ trợ giải quyết các vấn đề thắc mắc của người mua bảo hiểm, người được bảo hiểm đối với các chính sách bảo hiểm, quyền lợi bảo hiểm, các vấn đề có liên quan,...</li>
                            </ul>

                        </div>
                        {/* <div className="mt-5">
                            <h3 className="font-semibold">Điều 1. Phạm vi điều chỉnh</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Nghị định này quy định về bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới:
                                <li className="pb-2">1.	Bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                                <li className="pb-2">2.	Quản lý, sử dụng Quỹ bảo hiểm xe cơ giới.</li>
                                <li className="pb-2">3.	Quản lý, vận hành và khai thác cơ sở dữ liệu bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                                <li className="pb-2">4.	Trách nhiệm của các bộ, cơ quan ngang bộ và các tổ chức có liên quan trong việc thực hiện bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>

                            </ul>
                            <h3 className="font-semibold">Điều 2. Đối tượng áp dụng</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Nghị định này áp dụng đối với:
                                <li className="pb-2">1.	Chủ xe cơ giới tham gia giao thông và hoạt động trên lãnh thổ nước Cộng hòa xã hội chủ nghĩa Việt Nam.</li>
                                <li className="pb-2">2.	Doanh nghiệp bảo hiểm được phép triển khai bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới theo quy định của pháp luật.</li>
                                <li className="pb-2">3.	Cơ quan, tổ chức, cá nhân có liên quan đến việc thực hiện bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>

                            </ul>
                            <h3 className="font-semibold">Điều 3. Giải thích từ ngữ</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Trong Nghị định này, các từ ngữ dưới đây được hiểu như sau:
                                <li className="pb-2">1.	Chủ xe cơ giới là chủ sở hữu xe cơ giới hoặc được chủ sở hữu xe cơ giới giao chiếm hữu, sử dụng hợp pháp xe cơ giới.</li>
                                <li className="pb-2">2.	Xe cơ giới bao gồm phương tiện giao thông cơ giới đường bộ và xe máy chuyên dùng, trong đó:</li>
                                <li className="pb-2">a)	Phương tiện giao thông cơ giới đường bộ gồm xe ô tô; máy kéo; rơ moóc hoặc sơ mi rơ moóc được kéo bởi xe ô tô, máy kéo; xe mô tô hai bánh; xe mô tô ba bánh; xe gắn máy (kể cả xe máy điện) và các loại xe có kết cấu tương tự theo quy định của Luật Giao thông đường bộ.</li>
                                <li className="pb-2">b)	Xe máy chuyên dùng gồm xe máy thi công, xe máy nông nghiệp, lâm nghiệp và các loại xe đặc chủng khác sử dụng vào mục đích quốc phòng, an ninh theo quy định của Luật Giao thông đường bộ.</li>
                                <li className="pb-2">3.	Bên thứ ba là người bị thiệt hại về sức khỏe, tính mạng, tài sản do xe cơ giới gây ra, trừ những người sau:</li>
                                <li className="pb-2">a)	Người lái xe, người trên chính chiếc xe đó.</li>
                                <li className="pb-2">b)	Chủ sở hữu xe trừ trường hợp chủ sở hữu đã giao cho tổ chức, cá nhân khác chiếm hữu, sử dụng chiếc xe đó.</li>
                                <li className="pb-2">4.	Người được bảo hiểm là chủ xe cơ giới hoặc người lái xe có trách nhiệm dân sự được bảo hiểm theo hợp đồng bảo hiểm.</li>
                                <li className="pb-2">5.	Xe cơ giới hoạt động là xe cơ giới có sự điều khiển của người lái xe đang vận hành gồm di chuyển, dững xe, đỗ xe.</li>
                                <li className="pb-2">6.	Tham gia giao thông là việc chủ xe cơ giới, người lái xe điều khiển xe cơ giới tham gia giao thông đường bộ.</li>
                            </ul>
                        </div>
                        <h2 className="text-center font-semibold text-[20px]">Chương II
                            BẢO HIỂM BẮT BUỘC TRÁCH NHIỆM DÂN SỰ CỦA CHỦ XE CƠ GIỚI
                        </h2>
                        <h2 className="text-center font-semibold text-[17px] pt-5">
                            Mục 1 <br />ĐIỀU KIỆN BẢO HIỂM
                        </h2>
                        <div className="mt-5">
                            <h3 className="font-semibold">Điều 4. Nguyên tắc tham gia bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1.	Doanh nghiệp bảo hiểm và bên mua bảo hiểm triển khai bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới theo điều kiện, mức phí bảo hiểm, mức trách nhiệm bảo hiểm quy định tại Nghị định này.</li>
                                <li className="pb-2">2.	Đối với mỗi xe cơ giới, trách nhiệm bồi thường bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới chỉ phát sinh theo một hợp đồng bảo hiểm duy nhất.</li>
                                <li className="pb-2">3.	Ngoài việc tham gia hợp đồng bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới theo điều kiện, mức phí bảo hiểm, mức trách nhiệm bảo hiểm quy định tại Nghị định này,
                                    chủ xe cơ giới và doanh nghiệp bảo hiểm có thể thỏa thuận tại hợp đồng bảo hiểm về mở rộng điều kiện bảo hiểm, mức trách nhiệm bảo hiểm tăng thêm và mức phí bảo hiểm bổ sung tương ứng. Trong trường hợp này,
                                    doanh nghiệp bảo hiểm có trách nhiệm tách riêng phần bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới trong Giấy chứng nhận bảo hiểm.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 5. Phạm vi bồi thường thiệt hại</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1.	Thiệt hại ngoài hợp đồng về sức khỏe, tính mạng và tài sản đối với bên thứ ba do xe cơ giới gây ra.</li>
                                <li className="pb-2">2. Thiệt hại về sức khỏe, tính mạng của hành khách do xe cơ giới gây ra.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 6. Giấy chứng nhận bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới (sau đây gọi là Giấy chứng nhận bảo hiểm)</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1.	Giấy chứng nhận bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới là bằng chứng giao kết hợp đồng bảo hiểm bắt buộc trách nhiệm dân sự giữa chủ xe cơ giới với doanh nghiệp bảo hiểm.
                                    Mỗi xe cơ giới được cấp 1 Giấy chứng nhận bảo hiểm.
                                    Chủ xe cơ giới bị mất Giấy chứng nhận bảo hiểm phải có văn bản đề nghị doanh nghiệp bảo hiểm (nơi đã cấp Giấy chứng nhận bảo hiểm) cấp lại Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">2.	Khi mua bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới, chủ xe cơ giới được doanh nghiệp bảo hiểm cấp Giấy chứng nhận bảo hiểm.
                                    Doanh nghiệp bảo hiểm chỉ cấp Giấy chứng nhận bảo hiểm cho chủ xe cơ giới khi chủ xe cơ giới đã đóng đủ phí bảo hiểm hoặc có thỏa thuận với chủ xe cơ giới về thời hạn thanh toán phí bảo hiểm theo quy định của Bộ Tài chính.</li>
                                <li className="pb-2">3.	Giấy chứng nhận bảo hiểm do doanh nghiệp bảo hiểm chủ động thiết kế và phải bao gồm các nội dung sau đây:</li>
                                <li className="pb-2">a)	Tên, địa chỉ, số điện thoại (nếu có) của chủ xe cơ giới.</li>
                                <li className="pb-2">b)	Số biển kiểm soát hoặc số khung, số máy.</li>
                                <li className="pb-2">c)	Loại xe, trọng tải, số chỗ ngồi, mục đích sử dụng đối với xe ô tô.</li>
                                <li className="pb-2">d)	Tên, địa chỉ, số điện thoại đường dây nóng của doanh nghiệp bảo hiểm.</li>
                                <li className="pb-2">đ) Mức trách nhiệm bảo hiểm dân sự đối với bên thứ ba và hành khách.</li>
                                <li className="pb-2">e)	Trách nhiệm của chủ xe cơ giới, người lái xe khi xảy ra tai nạn.</li>
                                <li className="pb-2">g)	Thời hạn bảo hiểm, phí bảo hiểm, thời hạn thanh toán phí bảo hiểm.</li>
                                <li className="pb-2">h)	Ngày, tháng, năm cấp Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">i)	Mã số, mã vạch được đăng ký, quản lý và sử dụng theo quy định của Bộ Khoa học và Công nghệ để lưu trữ, chuyển tải và truy xuất thông tin định danh doanh nghiệp bảo hiểm và nội dung cơ bản của Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">4.	Trường hợp cấp Giấy chứng nhận bảo hiểm điện tử, doanh nghiệp bảo hiểm phải tuân thủ các quy định của Luật Giao dịch điện tử và các văn bản hướng dẫn thi hành; Giấy chứng nhận bảo hiểm điện tử phải tuân thủ đầy đủ các quy định hiện hành và phản ánh đầy đủ các nội dung quy định tại khoản 3 Điều này.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 7. Phí bảo hiểm và thanh toán phí bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1.	Phí bảo hiểm là khoản tiền mà chủ xe cơ giới phải thanh toán cho doanh nghiệp bảo hiểm khi mua bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                                <li className="pb-2">2.	Bộ Tài chính quy định phí bảo hiểm dựa trên số liệu thống kê, bảo đảm khả năng thanh toán của doanh nghiệp bảo hiểm, tương ứng với điều kiện bảo hiểm, mức trách nhiệm bảo hiểm, mức độ rủi ro theo loại xe cơ giới và mục đích sử dụng.</li>
                                <li className="pb-2">3.	Căn cứ vào lịch sử tai nạn của từng xe cơ giới và năng lực chấp nhận rủi ro của mình, doanh nghiệp bảo hiểm chủ động xem xét, điều chỉnh tăng phí bảo hiểm. Mức tăng phí bảo hiểm tối đa là 15% tính trên phí bảo hiểm do Bộ Tài chính quy định.</li>
                                <li className="pb-2">4.	Đối với các xe cơ giới được phép mua bảo hiểm có thời hạn khác 1 năm, phí bảo hiểm được tính dựa trên phí bảo hiểm do Bộ Tài chính quy định và tương ứng với thời hạn được bảo hiểm. Cách tính cụ thể như sau:</li>
                                <li className="pb-2 flex items-center text-center"><p className="w-[25%] text-black text-[15px]">Phí bảo hiểm phải nộp</p>	= <p className=" mx-4 w-[30%] text-black text-[15px]"><span className="border-b border-b-black block pb-1 mb-2">Phí bảo hiểm năm theo loại xe cơ giới</span><span>365 (ngày)</span></p>	x	<p className="ml-3 w-[25%] text-black text-[15px]">Thời hạn được bảo hiểm (ngày)</p>
                                </li>
                                <li className="pb-2">Trường hợp thời hạn được bảo hiểm từ 30 ngày trở xuống, phí bảo hiểm phải nộp được tính bằng phí bảo hiểm năm theo loại xe cơ giới/(chia) cho 12 tháng.</li>
                                <li className="pb-2">5. Việc thanh toán phí bảo hiểm thực hiện theo quy định của Bộ Tài chính.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 8. Mức trách nhiệm bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1.	Mức trách nhiệm bảo hiểm là số tiền tối đa mà doanh nghiệp bảo hiểm có thể phải trả đối với thiệt hại về sức khỏe, tính mạng và tài sản của bên thứ ba và hành khách do xe cơ giới gây ra trong mỗi vụ tai nạn xảy ra thuộc phạm vi bồi thường thiệt hại.</li>
                                <li className="pb-2">2.	Căn cứ chi phí thực tế về giá dịch vụ khám, điều trị, chăm sóc y tế và chi phí khắc phục thiệt hại đối với tài sản, Bộ Tài chính quy định mức trách nhiệm bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 9. Thời hạn và hiệu lực bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1. Thời hạn ghi trên Giấy chứng nhận bảo hiểm cụ thể:</li>
                                <li className="pb-2">a)	Đối với xe mô tô hai bánh, ba bánh, xe gắn máy (kể cả xe máy điện) và các loại xe có kết cấu tương tự theo quy định của Luật Giao thông đường bộ, thời hạn bảo hiểm tối thiểu là 1 năm và tối đa là 3 năm.</li>
                                <li className="pb-2">b)	Đối với các xe cơ giới còn lại, thời hạn bảo hiểm tối thiểu là 1 năm và thời hạn tối đa tương ứng với thời hạn kiểm định an toàn kỹ thuật và bảo vệ môi trường định kỳ có thời hạn trên 1 năm.</li>
                                <li className="pb-2">c)	Trong các trường hợp sau, thời hạn bảo hiểm dưới 1 năm: Xe cơ giới nước ngoài tạm nhập,
                                    tái xuất có thời hạn tham gia giao thông trên lãnh thổ nước Cộng hòa xã hội chủ nghĩa Việt Nam dưới 1 năm; niên hạn sử dụng của xe cơ giới nhỏ hơn 1 năm theo quy định của pháp luật; xe cơ giới thuộc đối tượng đăng ký tạm thời theo quy định của Bộ Công an.</li>
                                <li className="pb-2">d)	Trường hợp chủ xe cơ giới có nhiều xe tham gia bảo hiểm vào nhiều thời điểm khác nhau trong năm nhưng đến năm tiếp theo có nhu cầu đưa về cùng một thời điểm bảo hiểm để quản lý,
                                    thời hạn bảo hiểm của các xe này có thể nhỏ hơn 1 năm và bằng thời gian hiệu lực còn lại của hợp đồng bảo hiểm giao kết đầu tiên của năm dó. Thời hạn bảo hiểm của năm tiếp theo đối với các hợp đồng bảo hiểm,
                                    Giấy chứng nhận bảo hiểm sau khi được đưa về cùng thời điểm thực hiện theo quy định tại điểm a, điểm b khoản 1 Điều này.</li>
                                <li className="pb-2">2.	Hiệu lực bảo hiểm bắt đầu và kết thúc theo thời hạn ghi trên Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">3. Trong thời hạn còn hiệu lực ghi trên Giấy chứng nhận bảo hiểm, nếu có sự chuyển quyền sở hữu xe cơ giới,
                                    mọi quyền lợi bảo hiểm liên quan đến trách nhiệm dân sự của chủ xe cơ giới cũ vẫn còn hiệu lực đối với chủ xe cơ giới mới.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 10. Chấm dứt hợp đồng bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1. Chấm dứt hợp đồng bảo hiểm trong các trường hợp phải thu hồi Giấy chứng nhận đăng ký xe, biển số xe theo quy định của Bộ Công an.</li>
                                <li className="pb-2">2. Chấm dứt hợp đồng bảo hiểm trong trường hợp chủ xe cơ giới không thanh toán đủ phí bảo hiểm theo quy định tại khoản 5 Điều 7 Nghị định này.</li>
                                <li className="pb-2">3. Đơn phương đình chỉ thực hiện hợp đồng bảo hiểm trong trường hợp có sự thay đổi những yếu tố làm cơ sở tính phí bảo hiểm dẫn đến tăng, giảm các rủi ro được bảo hiểm.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 11. Hậu quả pháp lý của việc chấm dứt hợp đồng bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1. Trường hợp chấm dứt hợp đồng bảo hiểm theo quy định tại khoản 1 Điều 10 Nghị định này:</li>
                                <li className="pb-2">a) Bên mua bảo hiểm, người được bảo hiểm phải thông báo bằng văn bản cho doanh nghiệp bảo hiểm kèm theo Giấy chứng nhận bảo hiểm muốn chấm dứt và Giấy chứng nhận thu hồi giấy chứng nhận đăng ký xe, biển số xe hoặc quyết định, thông báo của cơ quan có thẩm quyền về việc thu hồi Giấy chứng nhận đăng ký xe, biển số xe.</li>
                                <li className="pb-2">Hợp đồng bảo hiểm chấm dứt từ thời điểm doanh nghiệp bảo hiểm nhận được thông báo chấm dứt hợp đồng bảo hiểm. Thời điểm chấm dứt hợp đồng bảo hiểm được xác định như sau:</li>
                                <li className="pb-2">-	Trường hợp bên mua bảo hiểm, người được bảo hiểm gửi thông báo bằng hình thức trực tiếp, qua dịch vụ bưu chính, thời điểm chấm dứt hợp đồng bảo hiểm tính từ thời điểm ghi vào số tiếp nhận của doanh nghiệp bảo hiểm.</li>
                                <li className="pb-2">-	Trường hợp bên mua bảo hiểm, người được bảo hiểm gửi thông báo bằng hình thức fax hoặc hệ thống thư điện tử, thời điểm chấm dứt hợp đồng bảo hiểm tính từ thời điểm doanh nghiệp bảo hiểm nhận fax, nhận thư điện tử.</li>
                                <li className="pb-2">b) Trong thời hạn 5 ngày làm việc, kể từ ngày nhận được thông báo, doanh nghiệp bảo hiểm phải hoàn lại phí bảo hiểm cho bên mua bảo hiểm, người được bảo hiểm tương ứng với thời gian còn lại của hợp đồng bảo hiểm mà bên mua bảo hiểm, người được bảo hiểm đã đóng phí bảo hiểm, sau khi đã trừ các chi phí hợp lý có liên quan đến hợp đồng bảo hiểm. Doanh nghiệp bảo hiểm không phải hoàn phí bảo hiểm trong trường hợp đã xảy ra sự kiện bảo hiểm và phát sinh trách nhiệm bồi thường bảo hiểm trước khi hợp đồng bảo hiểm bị chấm dứt.</li>
                                <li className="pb-2">c) Trường hợp bên mua bảo hiểm, người được bảo hiểm không thông báo về việc chấm dứt hợp đồng bảo hiểm, nhưng doanh nghiệp bảo hiểm có những bằng chứng cụ thể về việc xe cơ giới thuộc trường hợp được chấm dứt hợp đồng bảo hiểm theo quy định tại khoản 1 Điều 10 Nghị định này, doanh nghiệp bảo hiểm phải thông báo bằng văn bản cho bên mua bảo hiểm, người được bảo hiểm và các bằng chứng về việc xe cơ giới thuộc trường hợp chấm dứt hợp đồng bảo hiểm.</li>
                                <li className="pb-2">Sau 15 ngày kể từ ngày nhận được thông báo mà bên mua bảo hiểm, người được bảo hiểm không thực hiện các thủ tục chấm dứt hợp đồng bảo hiểm quy định tại điểm a khoản 1 Điều này, hợp đồng bảo hiểm mặc nhiên được chấm dứt. Doanh nghiệp bảo hiểm thực hiện hoàn phí bảo hiểm theo quy định tại điểm b khoản 1 Điều này. Trường hợp xảy ra sự kiện bảo hiểm trong vòng 15 ngày kể từ ngày doanh nghiệp bảo hiểm gửi thông báo cho bên mua bảo hiểm, người được bảo hiểm về việc chấm dứt hợp đồng bảo hiểm, doanh nghiệp bảo hiểm vẫn phải chịu trách nhiệm bồi thường cho người được bảo hiểm.</li>
                                <li className="pb-2">2. Trường hợp chấm dứt hợp đồng bảo hiểm theo quy định tại khoản 2 Điều 10 Nghị định này:</li>
                                <li className="pb-2">a) Hợp đồng bảo hiểm sẽ chấm dứt hiệu lực vào ngày kế tiếp ngày bên mua bảo hiểm, người được bảo hiểm phải thanh toán phí bảo hiểm.</li>
                                <li className="pb-2">Trong thời hạn 5 ngày làm việc kể từ ngày hợp đồng bảo hiểm chấm dứt hiệu lực, doanh nghiệp bảo hiểm phải thông báo bằng văn bản cho bên mua bảo hiểm, người được bảo hiểm về việc chấm dứt hợp đồng bảo hiểm và hoàn lại cho bên mua bảo hiểm, người được bảo hiểm phần phí bảo hiểm đã thanh toán thừa (nếu có) hoặc yêu cầu bên mua bảo hiểm, người được bảo hiểm thanh toán đủ phí bảo hiểm đến thời điểm chấm dứt hợp đồng bảo hiểm.</li>
                                <li className="pb-2">b) Doanh nghiệp bảo hiểm không phải hoàn phí bảo hiểm trong trường hợp đã xảy ra sự kiện bảo hiểm và phát sinh trách nhiệm bồi thường bảo hiểm trước khi hợp đồng bảo hiểm bị chấm dứt.</li>
                                <li className="pb-2">c) Doanh nghiệp bảo hiểm không có trách nhiệm bồi thường cho người được bảo hiểm khi xảy ra sự kiện bảo hiểm. Hợp đồng bảo hiểm chấm dứt từ thời điểm bên mua bảo hiểm, người được bảo hiểm nhận được thông báo chấm dứt hợp đồng bảo hiểm. Thời điểm chấm dứt hợp đồng bảo hiểm được xác định như sau:</li>
                                <li className="pb-2">- Trường hợp doanh nghiệp bảo hiểm gửi thông báo bằng hình thức trực tiếp, qua dịch vụ bưu chính, thời điểm chấm dứt hợp đồng bảo hiểm tính từ thời điểm bên mua bảo hiểm, người được bảo hiểm ký nhận thông báo.</li>
                                <li className="pb-2">- Trường hợp doanh nghiệp bảo hiểm gửi thông báo bằng hình thức fax hoặc hệ thống thư điện tử, thời điểm chấm dứt hợp đồng bảo hiểm tính từ thời điểm bên mua bảo hiểm, người được bảo hiểm nhận fax, nhận thư điện tử.</li>
                                <li className="pb-2">d) Hợp đồng bảo hiểm tiếp tục có hiệu lực kể từ thời điểm chủ xe cơ giới thanh toán đủ phí bảo hiểm và được doanh nghiệp bảo hiểm chấp thuận bằng văn bản.</li>
                                <li className="pb-2">3. Trường hợp đơn phương đình chỉ thực hiện hợp đồng bảo hiểm theo quy định tại khoản 3 Điều 10 Nghị định này:</li>
                                <li className="pb-2">a) Trường hợp đơn phương đình chỉ thực hiện hợp đồng báo hiểm khi có sự thay đổi những yếu tố làm cơ sở để tính phí bảo hiểm, dẫn đến giảm các rủi ro được bảo hiểm nhưng doanh nghiệp bảo hiểm không chấp nhận giảm phí bảo hiểm:</li>
                                <li className="pb-2">Bên mua bảo hiểm phải thông báo bằng văn bản trong vòng 5 ngày làm việc kể từ ngày doanh nghiệp bảo hiểm không chấp nhận giảm phí bảo hiểm. Hợp đồng bảo hiểm chấm dứt từ thời điểm doanh nghiệp bảo hiểm nhận được thông báo chấm dứt hợp đồng bảo hiểm. Hậu quả pháp lý của việc chấm dứt hợp đồng bảo hiểm thực hiện theo quy định tại điểm b khoản 1 Điều này.</li>
                                <li className="pb-2">b)	Trường hợp đơn phương đình chỉ thực hiện hợp đồng bảo hiểm khi có sự thay đổi những yếu tố làm cơ sở để tính phí bảo hiểm, dẫn đến tăng các rủi ro được bảo hiểm, nhưng bên mua bảo hiểm không chấp nhận tăng phí bảo hiểm:</li>
                                <li className="pb-2">Doanh nghiệp bảo hiểm phải thông báo bằng văn bản trong vòng 5 ngày làm việc kể từ ngày bên mua bảo hiểm không chấp nhận tăng phí bảo hiểm. Hợp đồng bảo hiểm chấm dứt từ thời điểm bên mua bảo hiểm nhận được thông báo chấm dứt hợp đồng bảo hiểm.</li>
                                <li className="pb-2">Trong vòng 5 ngày làm việc kể từ ngày chấm dứt hợp đồng bảo hiểm, doanh nghiệp bảo hiểm phải hoàn lại phí bảo hiểm cho bên mua bảo hiểm, người được bảo hiểm tương ứng với thời gian còn lại của hợp đồng bảo hiểm mà bên mua bảo hiểm, người được bảo hiểm đã đóng phí bảo hiểm, sau khi đã trừ các chi phí hợp lý có liên quan đến hợp đồng bảo hiểm.</li>
                                <li className="pb-2">Doanh nghiệp bảo hiểm không phải hoàn phí bảo hiểm trong trường hợp đã xảy ra sự kiện bảo hiểm và phát sinh trách nhiệm bồi thường.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 12. Giám định thiệt hại</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1. Khi xảy ra tai nạn, doanh nghiệp bảo hiểm hoặc người được doanh nghiệp bảo hiểm ủy quyền phải phối hợp chặt chẽ với chủ xe cơ giới, người lái xe và bên thứ ba hoặc người đại diện hợp pháp của các bên liên quan thực hiện việc giám định tổn thất để xác định nguyên nhân và mức độ tổn thất. Kết quả giám định phải lập thành văn bản có chữ ký của các bên liên quan. Chi phí giám định thiệt hại do doanh nghiệp bảo hiểm chi trả.</li>
                                <li className="pb-2">2. Trường hợp chủ xe cơ giới, người lái xe không thống nhất về nguyên nhân và mức độ thiệt hại do doanh nghiệp bảo hiểm xác định, hai bên có thể trưng cầu giám định viên độc lập, trừ trường hợp có thỏa thuận khác trong hợp đồng bảo hiểm. Trong trường hợp các bên không thoả thuận được việc trưng cầu giám định viên độc lập,
                                    một trong các bên được yêu cầu Tòa án nơi xảy ra tổn thất hoặc nơi cư trú của người được bảo hiểm chỉ định giám định viên độc lập. Kết luận của giám định viên độc lập có giá trị bắt buộc đối với các bên.</li>
                                <li className="pb-2">3. Trường hợp kết luận của giám định viên độc lập khác với kết luận giám định của doanh nghiệp bảo hiểm, doanh nghiệp bảo hiểm phải trả chi phí giám định viên độc lập. Trường hợp kết luận của giám định viên độc lập trùng với kết luận giám định của doanh nghiệp bảo hiểm, chủ xe cơ giới, người lái xe phải trả chi phí giám định viên độc lập.</li>
                                <li className="pb-2">4. Trong trường hợp bất khả kháng hoặc trở ngại khách quan không thể thực hiện được việc giám định, doanh nghiệp bảo hiểm được căn cứ vào các biên bản, kết luận của các cơ quan chức năng có thẩm quyền và các tài liệu liên quan để xác định nguyên nhân và mức độ thiệt hại.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 13. Loại trừ trách nhiệm bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2"> Doanh nghiệp bảo hiểm không bồi thường thiệt hại đối với các trường hợp sau:
                                <li className="pb-2">1. Hành động cố ý gây thiệt hại của chủ xe cơ giới, người lái xe hoặc người bị thiệt hại.</li>
                                <li className="pb-2">2. Người lái xe gây tai nạn cố ý bỏ chạy không thực hiện trách nhiệm dân sự của chủ xe cơ giới. Trường hợp người lái xe gây tai nạn cố ý bỏ chạy nhưng đã thực hiện trách nhiệm dân sự của chủ xe cơ giới thì không thuộc trường hợp loại trừ trách nhiệm bảo hiểm.</li>
                                <li className="pb-2">3. Người lái xe chưa đủ độ tuổi hoặc quá độ tuổi điều khiển xe cơ giới theo quy định pháp luật về giao thông đường bộ; người lái xe không có Giấy phép lái xe hoặc sử dụng Giấy phép lái xe không hợp lệ
                                    (có số phôi ghi ở mặt sau không trùng với số phôi được cấp mới nhất trong hệ thống thông tin quản lý Giấy phép lái xe) hoặc sử dụng Giấy phép lái xe không do cơ quan có thẩm quyền cấp, Giấy phép lái xe bị tẩy xóa hoặc sử dụng Giấy phép lái xe hết hạn sử dụng tại thời điểm xảy ra tai nạn hoặc sử dụng Giấy phép lái xe không phù hợp đối với xe cơ giới bắt buộc phải có Giấy phép lái xe.
                                    Trường hợp người lái xe bị tước quyền sử dụng Giấy phép lái xe có thời hạn hoặc bị thu hồi Giấy phép lái xe thì được coi là không có Giấy phép lái xe.</li>
                                <li className="pb-2">4.	Thiệt hại gây ra hậu quả gián tiếp bao gồm: giảm giá trị thương mại, thiệt hại gắn liền với việc sử dụng và khai thác tài sản bị thiệt hại.</li>
                                <li className="pb-2">5.	Thiệt hại đối với tài sản do lái xe điều khiển xe mà trong máu hoặc hơi thở có nồng độ cồn, sử dụng ma túy và chất kích thích bị cấm theo quy định của pháp luật.</li>
                                <li className="pb-2">6.	Thiệt hại đối với tài sản bị mất cắp hoặc bị cướp trong tai nạn.</li>
                                <li className="pb-2">7.	Thiệt hại đối với tài sản đặc biệt bao gồm: vàng, bạc, đá quý, các loại giấy tờ có giá trị như tiền, đồ cổ, tranh ảnh quý hiếm, thi hài, hài cốt.</li>
                                <li className="pb-2">8.	Chiến tranh, khủng bố, động đất.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 14. Bồi thường bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1.	Khi tai nạn xảy ra, trong phạm vi mức trách nhiệm bảo hiểm, doanh nghiệp bảo hiểm phải bồi thường cho người được bảo hiểm số tiền mà người được bảo hiểm đã bồi thường hoặc sẽ phải bồi thường cho người bị thiệt hại.</li>
                                <li className="pb-2">Trường hợp người được bảo hiểm chết, mất năng lực hành vi dân sự theo quyết định của Tòa án, doanh nghiệp bảo hiểm bồi thường trực tiếp cho người bị thiệt hại hoặc người thừa kế của người bị thiệt hại (trong trường hợp người bị thiệt hại đã chết)
                                    hoặc đại diện của người bị thiệt hại (trong trường hợp người bị thiệt hại mất năng lực hành vi dân sự theo quyết định của Tòa án hoặc chưa đủ sáu tuổi).</li>
                                <li className="pb-2">2.	Trong vòng 3 ngày làm việc kể từ ngày nhận được thông báo của bên mua bảo hiểm, người được bảo hiểm về vụ tai nạn, doanh nghiệp bảo hiểm phải tạm ứng bồi thường đối với thiệt hại về sức khỏe, tính mạng, cụ thể:</li>
                                <li className="pb-2">a)	Trường hợp đã xác định được vụ tai nạn thuộc phạm vi bồi thường thiệt hại:</li>
                                <li className="pb-2">-	70% mức bồi thường bảo hiểm theo quy định/1 người/1 vụ đối với trường hợp tử vong.</li>
                                <li className="pb-2">-	50% mức bồi thường bảo hiểm theo quy định/1 người/1 vụ đối với trường hợp tổn thương bộ phận được điều trị cấp cứu.</li>
                                <li className="pb-2">b)	Trường hợp chưa xác định được vụ tai nạn thuộc phạm vi bồi thường thiệt hại:</li>
                                <li className="pb-2">-	30% mức trách nhiệm bảo hiểm theo quy định/1 người/1 vụ đối với trường hợp tử vong.</li>
                                <li className="pb-2">- 10% mức trách nhiệm bảo hiểm theo quy định/1 người/1 vụ đối với trường hợp tổn thương bộ phận được điều trị cấp cứu.</li>
                                <li className="pb-2">3. Mức bồi thường bảo hiểm:</li>
                                <li className="pb-2">a) Mức bồi thường cụ thể về sức khỏe, tính mạng được xác định theo từng loại thương tật, thiệt hại theo Bảng quy định trả tiền bồi thường thiệt hại về sức khỏe,
                                    tính mạng theo quy định tại Phụ lục I ban hành kèm theo Nghị định này hoặc theo thoả thuận (nếu có) giữa người được bảo hiểm và người bị thiệt hại hoặc người thừa kế của người bị thiệt hại (trong trường hợp người bị thiệt hại đã chết)
                                    hoặc đại diện của người bị thiệt hại (trong trường hợp người bị thiệt hại mất năng lực hành vi dân sự theo quyết định của Tòa án hoặc chưa đủ sáu tuổi), nhưng không vượt quá mức bồi thường quy định tại Phụ lục I ban hành kèm theo Nghị định này. Trường hợp có quyết định của Tòa án thì căn cứ vào quyết định của Tòa án nhưng không vượt quá mức bồi thường quy định tại Phụ lục I ban hành kèm theo Nghị định này.</li>
                                <li className="pb-2">Trường hợp nhiều xe cơ giới gây tai nạn dẫn đến thiệt hại về sức khỏe, tính mạng, mức bồi thường được xác định theo mức độ lỗi của người được bảo hiểm nhưng tổng mức bồi thường không vượt quá mức trách nhiệm bảo hiểm.</li>
                                <li className="pb-2">Đối với vụ tai nạn được cơ quan có thẩm quyền xác định nguyên nhân do lỗi hoàn toàn của bên thứ ba, thì mức bồi thường bảo hiểm về sức khỏe, tính mạng đối với các đối tượng thuộc bên thứ ba bằng 50% mức bồi thường quy định tại Phụ lục I ban hành kèm theo Nghị định này hoặc theo thoả thuận (nếu có) giữa người được bảo hiểm
                                    hoặc người thừa kế của người bị thiệt hại (trong trường hợp người bị thiệt hại đã chết) hoặc đại diện của người bị thiệt hại (trong trường hợp người bị thiệt hại mất năng lực hành vi dân sự theo quyết định của Tòa án hoặc chưa đủ sáu tuổi), nhưng không vượt quá 50% mức bồi thường quy định tại Phụ lục I ban hành kèm theo Nghị định này.</li>
                                <li className="pb-2">b) Mức bồi thường cụ thể về thiệt hại đối với tài sản/1 vụ tai nạn được xác định theo thiệt hại thực tế và theo mức độ lỗi của người được bảo hiểm nhưng không vượt quá mức trách nhiệm bảo hiểm.</li>
                                <li className="pb-2">4. Doanh nghiệp bảo hiểm không có trách nhiệm bồi thường phần vượt quá mức trách nhiệm bảo hiểm theo quy định của Bộ Tài chính, trừ trường hợp chủ xe cơ giới tham gia hợp đồng bảo hiểm tự nguyện.</li>
                                <li className="pb-2">5. Trường hợp nhiều hợp đồng bảo hiểm bắt buộc trách nhiệm dân sự được giao kết cho cùng một xe cơ giới, số tiền bồi thường chỉ được giải quyết theo hợp đồng bảo hiểm giao kết đầu tiên. Doanh nghiệp bảo hiểm phải hoàn lại cho bên mua bảo hiểm 100% phí bảo hiểm đã đóng đối với các hợp đồng bảo hiểm còn lại.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 15. Hồ sơ bồi thường bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Doanh nghiệp bảo hiểm có trách nhiệm phối hợp với bên mua bảo hiểm và các bên có liên quan để thu thập 1 bộ hồ sơ bồi thường bảo hiểm và chịu trách nhiệm trước pháp luật về tính chính xác, đầy đủ, hợp lệ của hồ sơ bồi thường bảo hiểm. Hồ sơ bồi thường bảo hiểm bao gồm các tài liệu sau:
                                <li className="pb-2">1.	Tài liệu liên quan đến xe, người lái xe (Bản sao công chứng hoặc Bản sao có xác nhận của doanh nghiệp bảo hiểm sau khi đã đối chiếu với bản chính) do bên mua bảo hiểm, người được bảo hiểm cung cấp:</li>
                                <li className="pb-2">a)	Giấy đăng ký xe (hoặc bản sao chứng thực Giấy đăng ký xe kèm bản gốc Giấy biên nhận của tổ chức tín dụng còn hiệu lực, thay cho bản chính Giấy đăng ký xe trong thời gian tổ chức tín dụng giữ bản chính Giấy đăng ký xe) hoặc chứng từ chuyển quyền sở hữu xe và chứng tử nguồn gốc xe (trường hợp không có giấy đăng ký xe).</li>
                                <li className="pb-2">b)	Giấy phép lái xe.</li>
                                <li className="pb-2">c)	Giấy chứng minh nhân dân hoặc Thẻ căn cước công dân hoặc Hộ chiếu hoặc các giấy tờ tùy thân khác của người lái xe.</li>
                                <li className="pb-2">d)	Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">2.	Tài liệu chứng minh thiệt hại về sức khỏe, tính mạng (Bản sao của các cơ sở y tế hoặc bản sao có xác nhận của doanh nghiệp bảo hiểm sau khi đã đối chiếu với bản chính) do bên mua bảo hiểm, người được bảo hiểm cung cấp:</li>
                                <li className="pb-2">a)	Giấy chứng nhận thương tích.</li>
                                <li className="pb-2">b)	Hồ sơ bệnh án.</li>
                                <li className="pb-2">c)	Trích lục khai tử hoặc Giấy báo tử hoặc văn bản xác nhận của cơ quan công an hoặc kết quả giám định của Cơ quan giám định pháp y đối với trường hợp nạn nhân chết trên phương tiện giao thông, chết do tai nạn.</li>
                                <li className="pb-2">3.	Tài liệu chứng minh thiệt hại đối với tài sản do bên mua bảo hiểm, người được bảo hiểm cung cấp:</li>
                                <li className="pb-2">a) Hóa đơn, chứng từ hợp lệ về việc sửa chữa, thay mới tài sản bị thiệt hại do tai nạn giao thông gây ra (trường hợp doanh nghiệp bảo hiểm thực hiện sửa chữa, khắc phục thiệt hại, doanh nghiệp bảo hiểm có trách nhiệm thu thập giấy tờ này).</li>
                                <li className="pb-2">b) Các giấy tờ, hóa đơn, chứng từ liên quan đến chi phí phát sinh mà chủ xe cơ giới đã chi ra để giảm thiểu tổn thất hay để thực hiện theo chỉ dẫn của doanh nghiệp bảo hiểm.</li>
                                <li className="pb-2">4. Bản sao các tài liệu liên quan của cơ quan Công an do doanh nghiệp bảo hiểm thu thập trong các vụ tai nạn gây tử vong đối với bên thứ ba và hành khách, bao gồm: Thông báo kết quả điều tra, xác minh, giải quyết vụ tai nạn giao thông hoặc Thông báo kết luận điều tra giải quyết vụ tai nạn giao thông.</li>
                                <li className="pb-2">5. Biên bản giám định xác định nguyên nhân và mức độ tổn thất do doanh nghiệp bảo hiểm lập được thống nhất giữa doanh nghiệp bảo hiểm và bên mua bảo hiểm, người được bảo hiểm.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 16. Thời hạn yêu cầu, thanh toán bồi thường</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">
                                <li className="pb-2">1. Thời hạn yêu cầu bồi thường là 1 năm kể từ ngày xảy ra tai nạn, trừ trường hợp chậm trễ do nguyên nhân khách quan và bất khả kháng theo quy định của pháp luật.</li>
                                <li className="pb-2">2. Trong thời hạn 5 ngày làm việc kể từ ngày xảy ra tai nạn (trừ trường hợp bất khả kháng), bên mua bảo hiểm, người được bảo hiểm phải gửi thông báo tai nạn bằng văn bản hoặc thông qua hình thức điện tử cho doanh nghiệp bảo hiểm.</li>
                                <li className="pb-2">3. Thời hạn thanh toán bồi thường của doanh nghiệp bảo hiểm là 15 ngày kể từ khi nhận được đầy đủ hồ sơ yêu cầu bồi thường bảo hiểm hợp lệ và không quá 30 ngày kể từ khi nhận được đầy đủ hồ sơ yêu cầu bồi thường bảo hiểm hợp lệ trong trường hợp phải tiến hành xác minh hồ sơ.</li>
                                <li className="pb-2">4. Trường hợp từ chối bồi thường, doanh nghiệp bảo hiểm phải thông báo bằng văn bản cho bên mua bảo hiểm, người được bảo hiểm biết lý do từ chối bồi thường trong thời hạn 30 ngày kể từ ngày nhận được đầy đủ hồ sơ yêu cầu bồi thường bảo hiểm hợp lệ.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 17. Quyền của bên mua bảo hiểm, người được bảo hiểm</h3>
                            <p className="text-[15px] pt-2 pl-5 pb-2 text-black">Ngoài các quyền quy định tại Luật Kinh doanh bảo hiểm, bên mua bảo hiểm, người được bảo hiểm có quyền tính phí bảo hiểm vào chi phí hoạt động kinh doanh trong trường hợp bên mua bảo hiểm là đơn vị sản xuất kinh doanh;
                                được bố trí phí bảo hiểm trong kinh phí hoạt động thường xuyên của cơ quan, đơn vị trong trường hợp bên mua bảo hiểm là cơ quan hành chính, đơn vị sự nghiệp của Nhà nước.</p>
                            <h3 className="font-semibold">Điều 18. Nghĩa vụ của bên mua bảo hiểm, người được bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Ngoài các nghĩa vụ quy định tại Luật Kinh doanh bảo hiểm, bên mua bảo hiểm, người được bảo hiểm có nghĩa vụ:
                                <li className="pb-2">1.	Phối hợp với doanh nghiệp bảo hiểm xem xét tình trạng xe trước khi cấp Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">2.	Kịp thời thông báo cho doanh nghiệp bảo hiểm để áp dụng phí bảo hiểm phù hợp cho thời gian còn lại của hợp đồng bảo hiểm trong trường hợp có sự thay đổi những yếu tố làm cơ sở để tính phí bảo hiểm, dẫn đến tăng các rủi ro được bảo hiểm.</li>
                                <li className="pb-2">3.	Luôn mang theo Giấy chứng nhận bảo hiểm còn hiệu lực (bản cứng hoặc bản điện tử) khi tham gia giao thông, xuất trình giấy tờ này khi có yêu cầu của lực lượng Cảnh sát giao thông và cơ quan chức năng có thẩm quyền khác theo quy định của pháp luật.</li>
                                <li className="pb-2">4.	Tuân thủ các quy định về đảm bảo an toàn giao thông đường bộ.</li>
                                <li className="pb-2">5.	Khi tai nạn giao thông xảy ra, phải có trách nhiệm:</li>
                                <li className="pb-2">a)	Thông báo ngay cho doanh nghiệp bảo hiểm để phối hợp giải quyết, tích cực cứu chữa, hạn chế thiệt hại về sức khỏe, tính mạng và tài sàn, bảo vệ hiện trường tai nạn; thông báo ngay cho cơ quan Công an hoặc chính quyền địa phương nơi gần nhất để phối hợp giải quyết vụ tai nạn giao thông theo quy định và phối hợp với cơ quan chức năng trong việc điều tra, xác minh nguyên nhân vụ tai nạn giao thông.</li>
                                <li className="pb-2">b)	Không được di chuyển, tháo gỡ hoặc sửa chữa tài sản khi chưa có ý kiến chấp thuận của doanh nghiệp bảo hiểm; trừ trường hợp cần thiết để đảm bảo an toàn, đề phòng hạn chế thiệt hại về sức khỏe, tính mạng và tài sản hoặc phải thi hành theo yêu cầu của cơ quan có thẩm quyền.</li>
                                <li className="pb-2">c)	Chủ động thu thập và cung cấp các tài liệu quy định trong hồ sơ bồi thường bảo hiểm thuộc trách nhiệm của bên mua bảo hiểm, người được bảo hiểm cho doanh nghiệp bảo hiểm quy định tại Điều 15 Nghị định này.</li>
                                <li className="pb-2">d)	Tạo điều kiện thuận lợi cho doanh nghiệp bảo hiểm trong quá trình xác minh các tài liệu do mình cung cấp.</li>
                                <li className="pb-2">6.	Thông báo và trả tiền bồi thường cho người bị thiệt hại hoặc người thừa kế hoặc đại diện của người bị thiệt hại biết số tiền họ được doanh nghiệp bảo hiểm trả đối với từng trường hợp thiệt hại về sức khỏe, tính mạng theo quy định tại điểm a khoản 3 Điều 14 Nghị định này.</li>
                                <li className="pb-2">7.	Thông báo bằng văn bản cho doanh nghiệp bảo hiểm theo quy định tại điểm a khoản 1 và điểm a khoản 3 Điều 11 Nghị định này.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 19. Quyền của doanh nghiệp bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Ngoài các quyền theo quy định tại Luật Kinh doanh bảo hiểm, doanh nghiệp bảo hiểm có quyền:
                                <li className="pb-2">1. Yêu cầu bên mua bảo hiểm, người được bảo hiểm cung cấp đầy đủ và trung thực những nội dung đã được quy định trong Giấy chứng nhận bảo hiểm; xem xét tình trạng xe cơ giới trước khi cấp Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">2. Giảm trừ tối đa 5% số tiền bồi thường thiệt hại đối với tài sản trong trường hợp bên mua bảo hiểm, người được bảo hiểm không thông báo tai nạn cho doanh nghiệp bảo hiểm theo quy định tại khoản 2 Điều 16 Nghị định này hoặc sau khi xảy ra sự kiện bảo hiểm, doanh nghiệp bảo hiểm phát hiện trong quá trình thực hiện hợp đồng bảo hiểm, bên mua bảo hiểm, người được bảo hiểm không thực hiện nghĩa vụ thông báo khi có sự thay đổi những yếu tố làm cơ sở để tính phí bảo hiểm, dẫn đến tăng các rủi ro được bảo hiểm.</li>
                                <li className="pb-2">3. Sau khi đã thực hiện tạm ứng bồi thường theo quy định tại điểm b khoản 2 Điều 14 Nghị định này, có quyền yêu cầu Ban điều hành Quỹ bảo hiểm xe cơ giới hoàn trả số tiền đã tạm ứng bồi thường trong trường hợp tai nạn được xác định thuộc loại trừ trách nhiệm bảo hiểm hoặc khoản tiền tạm ứng bồi thường vượt mức bồi thường bảo hiểm theo quy định trong trường hợp tai nạn thuộc phạm vi bồi thường thiệt hại.</li>
                                <li className="pb-2">4. Đề nghị cơ quan Công an cung cấp bản sao các tài liệu liên quan đến vụ tai nạn theo quy định tại khoản 4 Điều 15 Nghị định này.</li>
                                <li className="pb-2">5. Kiến nghị sửa đổi, bổ sung quy tắc, điều khoản, biểu phí bảo hiểm, mức trách nhiệm bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới phù hợp với thực tế triển khai loại hình bảo hiểm này.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 20. Nghĩa vụ của doanh nghiệp bảo hiểm</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Ngoài các nghĩa vụ theo quy định tại Luật Kinh doanh bảo hiểm, doanh nghiệp bảo hiểm có nghĩa vụ:
                                <li className="pb-2">1. Bán bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới theo đúng điều kiện, mức phí bảo hiểm, mức trách nhiệm bảo hiểm quy định tại Nghị định này và cấp Giấy chứng nhận bảo hiểm cho bên mua bảo hiểm bao gồm các nội dung theo quy định tại khoản 3 Điều 6 Nghị định này.</li>
                                <li className="pb-2">2. Thiết lập, duy trì hoạt động 24 giờ/7 ngày đường dây nóng để kịp thời tiếp nhận thông tin tai nạn, hướng dẫn, giải đáp cho bên mua bảo hiểm, người được bảo hiểm và các bên có liên quan về các vấn đề liên quan tới bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                                <li className="pb-2">3. Tích hợp tính năng tra cứu Giấy chứng nhận bảo hiểm trên trang thông tin điện tử, cổng thông tin điện tử của doanh nghiệp bảo hiểm, cho phép cơ quan có chức năng thanh tra, kiểm tra, giám sát và bên mua bảo hiểm, người được bảo hiểm tra cứu, xác minh thời hạn và hiệu lực bảo hiểm của Giấy chứng nhận bảo hiểm.</li>
                                <li className="pb-2">4. Khi nhận được thông báo về tai nạn, trong vòng 1 giờ doanh nghiệp bảo hiểm phải hướng dẫn bên mua bảo hiểm, người được bảo hiểm, chủ xe cơ giới các biện pháp đảm bảo an toàn, đề phòng hạn chế thiệt hại về người và tài sản, hướng dẫn hồ sơ, thủ tục yêu cầu bồi thường bảo hiểm; phối hợp chặt chẽ với bên mua bảo hiểm, người được bảo hiểm, bên thứ ba và các bên liên quan trong vòng 24 giờ tổ chức thực hiện việc giám định tổn thất xác định nguyên nhân và mức độ tổn thất làm căn cứ giải quyết bồi thường bảo hiểm.</li>
                                <li className="pb-2">5. Thông báo cho bên mua bảo hiểm, người được bảo hiểm, người bị tai nạn biết số tiền bồi thường thiệt hại về sức khỏe, tính mạng và thanh toán số tiền bồi thường theo quy định tại điểm a khoản 3 Điều 14 Nghị định này.</li>
                                <li className="pb-2">6. Giải thích rõ quy tắc, điều khoản và biểu phí bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới, đảm bảo bên mua bảo hiểm, người được bảo hiểm phân biệt rõ giữa loại hình bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới với các loại hình bảo hiểm tự nguyện khác.</li>
                                <li className="pb-2">7. Chủ động thu thập các tài liệu quy định trong hồ sơ bồi thường bảo hiểm thuộc trách nhiệm của doanh nghiệp bảo hiểm quy định tại Điều 15 Nghị định này.</li>
                                <li className="pb-2">8. Tạm ứng bồi thường, chi trả bồi thường nhanh chóng và chính xác theo quy định của Nghị định này.</li>
                                <li className="pb-2">9. Thông báo bằng văn bản cho bên mua bảo hiểm theo quy định tại điểm c khoản 1, điểm a khoản 2 và điểm b khoản 3 Điều 11 Nghị định này.</li>
                                <li className="pb-2">10.	Thanh toán cho cơ quan Công an chi phí sao chụp những hồ sơ, biên bản tai nạn đã được cung cấp theo quy định pháp luật và có trách nhiệm giữ gìn bí mật trong quá trình điều tra.</li>
                                <li className="pb-2">11.	Trong vòng 15 ngày trước khi hết thời hạn bảo hiểm phải thông báo cho bên mua bảo hiểm, người được bảo hiểm về việc hết thời hạn của hợp đồng bảo hiểm.</li>
                                <li className="pb-2">12.	Đóng góp Quỹ bảo hiểm xe cơ giới theo quy định tại Điều 26 Nghị định này.</li>
                                <li className="pb-2">13.	Phải hạch toán tách biệt doanh thu phí bảo hiểm, hoa hồng, bồi thường và các khoản chi phí liên quan đến bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới. Chi phí liên quan đến bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới không bao gồm chi hỗ trợ đại lý ngoài hoa hồng bảo hiểm đại lý được hưởng theo quy định của Bộ Tài chính, chi khuyến mại và chiết khấu thanh toán dưới mọi hình thức.</li>
                                <li className="pb-2">14.	Xây dựng, triển khai, bố trí nhân sự kiểm soát thực hiện các quy chế, quy trình về hoạt động nghiệp vụ, kiểm soát rủi ro, kiểm soát nội bộ và phòng, chống gian lận trong kinh doanh bảo hiểm liên quan đến bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                                <li className="pb-2">15.	Chịu sự kiểm tra, giám sát của các cơ quan nhà nước có thẩm quyền trong việc thực hiện bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới.</li>
                                <li className="pb-2">16.	Tổ chức thực hiện chế độ bảo hiểm bắt buộc về trách nhiệm dân sự của chủ xe cơ giới.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 21. Trách nhiệm lập và gửi báo cáo</h3>
                            <ul className="text-[15px] pt-2 pl-5 pb-2">Doanh nghiệp bảo hiểm có trách nhiệm lập và gửi các báo cáo đối với bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới theo quy định sau:
                                <li className="pb-2">1. Báo cáo nghiệp vụ:</li>
                                <li className="pb-2">Doanh nghiệp bảo hiểm phải lập và gửi Bộ Tài chính các báo cáo nghiệp vụ quý, năm bằng một trong các phương thức gồm gửi trực tiếp hoặc gửi qua dịch vụ bưu chính và gửi qua hệ thống thư điện tử theo mẫu quy định tại Phụ lục II về Báo cáo về tình hình thực hiện bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới và Phụ lục III về Báo cáo kết quả kinh doanh bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới ban hành kèm theo Nghị định này, cụ thể như sau:</li>
                                <li className="pb-2">a)	Báo cáo quý: Thời gian chốt số liệu tính từ ngày 01 của tháng đầu tiên kỳ báo cáo đến ngày 30 hoặc 31 của tháng cuối quý thuộc kỳ báo cáo. Thời hạn gửi báo cáo quý chậm nhất là 30 ngày, kể từ ngày kết thúc quý.</li>
                                <li className="pb-2">b)	Báo cáo năm: Thời gian chốt số liệu tính từ ngày 01 của tháng 01 đến ngày 31 tháng 12 của kỳ báo cáo. Thời hạn gửi báo cáo năm chậm nhất là 90 ngày, kể từ ngày kết thúc năm.</li>
                                <li className="pb-2">2.	Ngoài các báo cáo theo quy định tại khoản 1 Điều này, doanh nghiệp bảo hiểm có trách nhiệm lập và gửi báo cáo đột xuất theo yêu cầu của Bộ Tài chính bằng một trong các phương thức gồm gửi trực tiếp hoặc gửi qua dịch vụ bưu chính và gửi qua hệ thống thư điện tử.</li>
                            </ul>
                            <h3 className="font-semibold">Điều 22. Giải quyết tranh chấp</h3>
                            <p className="text-[15px] pt-2 pl-5 pb-2 text-black">Mọi tranh chấp phát sinh từ hợp đồng bảo hiểm trước hết giải quyết trên cơ sở thương lượng, trường hợp không thương lượng được, các bên có quyền lựa chọn giải quyết tranh chấp thông qua hòa giải thương mại hoặc Trọng tài thương mại hoặc Tòa án.</p>
                        </div> */}
                    </div>

                </div>

            </div>
        </MainLayout>
    )
}

export default InterestsPolicy