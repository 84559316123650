import React from 'react'
import { getFormattedPrice } from '../../functions/index'
import LoadingSpinner from '../Loading/LoadingSpinner'
import { useState } from 'react'

export default function PriceFooter({
  price,
  handlePaymentOnClick,
  loading = false,
}) {
  const [nguoiGioiThieu, setNguoiGioiThieu] = useState('')
  return (
    <>
      {/* <div className="h-[180px]"></div> */}
      <footer
        className={` max-w-[1200px]   bottom-0 bg-primary-color h-[180px] px-5 w-full flex items-center`}
        style={{ backgroundImage: "url('/images/bg-discover.webp')" }}
      >
        <div className="w-full">
          <div className="flex justify-between pb-2 text-white text-[20px]">
            <h3>Tổng phí (đã có VAT)</h3>
            {loading ? (
              <LoadingSpinner loading={true} />
            ) : (
              <h3>{getFormattedPrice(price)}</h3>
            )}
          </div>
          <div
            className="flex flex-row justify-around items-center rounded-[10px]
              xs:flex-col xs:items-start xs:justify-start xs:space-y-2 xs:space-x-0 xs:mt-2
            "
          >
            <div className="flex flex-col lg:items-center mr-5 xs:w-full lg:w-auto">
              <div className="w-max">
                <label className="text-white text-[15px]">
                  Nhập mã người giới thiệu (Nếu có)
                </label>
              </div>

              <input
                className="w-full rounded-[10px] py-2 px-3"
                type="text"
                placeholder="Nhập mã người giới thiệu"
                onChange={(e) => {
                  console.log(e.target.value)
                  setNguoiGioiThieu(e.target.value)
                }}
              />
            </div>
            <button
              onClick={() => handlePaymentOnClick({
                nguoiGioiThieu: nguoiGioiThieu
              })}
              className="text-black bg-white w-full rounded-[10px] py-2 uppercase font-semibold"
              type="button"
            >
              Thanh Toán
            </button>
          </div>
        </div>
      </footer>
    </>
  )
}
