import React from 'react'
import InsuranceProviderOption from './InsuranceProviderOption'

export default function InsuranceProviderSection({
  insuranceProvider,
  handleInsuranceProvider,
  hiddenSections = [],
}) {
  return (
    <div className="flex flex-wrap px-5">
      <InsuranceProviderOption
        id="aaa"
        value={'AAA'}
        title="Công ty Cổ phần Bảo hiểm AAA"
        currentValue={insuranceProvider}
        onClick={(value) => handleInsuranceProvider(value)}
        imgSrc="/images/aaa.jpg"
        hidden={hiddenSections.includes('aaa')}
      />

      {/* <InsuranceProviderOption
        id="bsh"
        value={'BSH'}
        title="TỔNG CÔNG TY CỔ PHẦN BẢO HIỂM SÀI GÒN - HÀ NỘI"
        currentValue={insuranceProvider}
        onClick={(value) => handleInsuranceProvider(value)}
        imgSrc="/images/bsh.jpg"
        hidden={hiddenSections.includes('bsh')}
      /> */}

      {/* <InsuranceProviderOption
        id="hdi"
        value={'HDI'}
        title="Công ty TNHH Bảo hiểm HD"
        currentValue={insuranceProvider}
        onClick={(value) => handleInsuranceProvider(value)}
        imgSrc="/images/hdi.webp"
        hidden={hiddenSections.includes('hdi')}
      /> */}

      <InsuranceProviderOption
        id="vni"
        value={'VNI'}
        title="Công ty Bảo hiểm Hàng không Kỹ Thuật số (VNI Digital)"
        currentValue={insuranceProvider}
        onClick={(value) => handleInsuranceProvider(value)}
        imgSrc="/images/vni.webp"
        hidden={hiddenSections.includes('vni')}
      />
    </div>
  )
}
