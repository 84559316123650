import { useState } from 'react'
import AuthLayout from '../layouts/AuthLayout'
import AuthInput from '../components/Input/AuthInput'
import { Link } from 'react-router-dom';
import Loading from '../components/Loading/Loading'
import Axios from '../functions/axios';
import { isValidEmail } from '../functions'

export default function Register() {
  const [error, setError] = useState({ fullName: '', email: '', password: '' });
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const validateInput = () => {
    let isValid = true;
    let error = { email: '', password: '' };

    if (fullName === '') {
      error.fullName = 'Vui lòng nhập họ tên';
      isValid = false;
    };

    if (email === '') {
      error.email = 'Vui lòng nhập email';
      isValid = false;
    };

    if (isValidEmail(email) === false) {
      error.email = 'Email không hợp lệ';
      isValid = false;
    }

    if (password === '') {
      error.password = 'Vui lòng nhập mật khẩu';
      isValid = false;
    };

    if (confirmPassword === '') {
      error.confirmPassword = 'Vui lòng nhập lại mật khẩu';
      isValid = false;
    };

    if (password.length < 6) {
      error.password = 'Mật khẩu phải có ít nhất 6 ký tự';
      isValid = false;
    };

    if (password !== confirmPassword) {
      error.confirmPassword = 'Mật khẩu không khớp';
      isValid = false;
    };

    setError(error);
    return isValid;
  };


  const handleSubmitRegister = (e) => {
    e.preventDefault();
    if (!validateInput()) return;
    setLoading(true);
    Axios.post('/api/UserRole/SignUp', { email: email, fullName: fullName, password: password})
      .then(() => {
        alert('Đăng ký thành công');
        window.location.href = '/login';
      })
      .catch(() => {
        alert('Đăng ký thất bại');
      })
      .finally(() => setLoading(false))
  }
  return (
    <AuthLayout>
      <Loading loading={loading} />
      <section className="absolute top-0 h-full overflow-hidden w-full">
        <div className="px-6 h-full text-gray-800">
          <div
            className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6"
          >
            <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
              <form>
                <div className='mb-6'>
                  <div className='text-[28px] font-bold'>Đăng ký</div>
                  <div className='font-bold'>Đăng ký để dễ dàng quản lý bảo hiểm của bạn và tận hưởng sự hỗ trợ tốt nhất từ baohiemxe.ai !</div>
                </div>

                <div className="mb-6">
                  <AuthInput
                    id="fullName"
                    name="fullName"
                    type="text"
                    value={fullName}
                    placeholder="Nhập họ tên của bạn"
                    onChange={(e) => setFullName(e.target.value)}
                    maxLength={40}
                    error={error}
                    required
                  />
                </div>

                <div className="mb-6">
                  <AuthInput
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    placeholder="Nhập địa chỉ email"
                    onChange={(e) => setEmail(e.target.value)}
                    maxLength={50}
                    error={error}
                    required
                  />
                </div>

                <div className="mb-6">
                  <AuthInput
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    placeholder="Nhập mật khẩu"
                    onChange={(e) => setPassword(e.target.value)}
                    maxLength={20}
                    error={error}
                    required
                  />
                </div>

                <div className="mb-6">
                  <AuthInput
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    placeholder="Nhập lại mật khẩu"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    maxLength={20}
                    error={error}
                    required
                  />
                </div>

                <div className="flex justify-between items-center mb-6">
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none h-[40px]  text-lg  transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      id="exampleCheck2"
                    />
                    <label className="form-check-label inline-block text-gray-800" for="exampleCheck2"
                    >Đăng nhập tự động sau khi đăng ký</label
                    >
                  </div>
                </div>

                <div className="flex items-center mt-2">
                  <p className="text-sm font-semibold">
                    Đã có tài khoản rồi?
                    <Link
                      to={'/login'}
                      className="ml-1 text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out underline"
                    >Đăng nhập tại đây
                    </Link>
                  </p>
                  <div className="ml-auto text-right font-bold">
                    <button onClick={handleSubmitRegister} type="submit">
                      <div className="flex items-center">
                        Đăng ký
                        <i className="fa-solid fa-chevron-right ml-2 text-[12px]"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </AuthLayout>
  )
}
