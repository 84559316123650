import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="text-[16px] bg-gray-100 text-white bg-primary-color">
      <div className="px-[8%] py-10 mx-auto">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div>
            <Link to="/">
                <img alt="banner" src="/images/banner.webp" className="w-2/3"></img>
            </Link>
            <div className="leading-[36px] font-normal">
              <div className="text-sm my-2 font-bold">Sản phẩm thuộc về: </div>
              <div>
                <img src="/images/bagang-logo.webp" alt="bagang-logo" className="w-20"></img>
              </div>
              <b className="text-[18px] md:text-[25px]">Công Ty Cổ Phần Công Nghệ Bagang</b>
              <div className="text-white">
                <div><i className="fa-solid fa-building mr-2"></i> 170 Lê Hồng Phong, Khu Phố 5, Thủ Dầu Một, Bình Dương</div>
                <div
                  className="flex items-start w-full flex-col"
                >
                  <div><i class="fas fa-phone mr-2"></i>Đường dây nóng</div>
                  <div><i class="fa-solid fa-play ml-6 mr-2"></i>1900 54 54 35 (Bảo hiểm xe AAA)</div>
                  <div><i class="fa-solid fa-play ml-6 mr-2"></i>024 3793 1111 (Bảo hiểm xe BSH)</div>
                  <div><i class="fa-solid fa-play ml-6 mr-2"></i>028 3528 2888 (Bảo hiểm xe HDI)</div>
                  <div><i class="fa-solid fa-play ml-6 mr-2"></i>024 6276 5555 (Bảo hiểm xe VNI)</div>
                </div>
                <div><i className="fa-solid fa-envelope mr-2"></i>buuboi@bagang.ai</div>
              </div>
            </div>

            {/* <b className="mt-4">Theo dõi BaoHiemXe.Ai:</b>
            <div className="flex gap-6 pymd text-white justify-center md:justify-start">
              <a
                className="hover:opacity-75"
                href="https://www.facebook.com/HDI.edu.vn"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Facebook </span>
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a
                className="hover:opacity-75"
                href="https://www.instagram.com/hdi.info/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Instagram </span>
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a
                className="hover:opacity-75"
                href="https://twitter.com/BaoHiemHD"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Twitter </span>
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            </div>
            <div className="relative">
              <b className="my-4">
                Tải ứng dụng Bảo Hiểm Ai tại đây:
              </b>
              <div className="flex flex-wrap md:flex-nowrap items-center">
                <div className="w-full md:w-auto pr-2 flex justify-center mt-4">
                  <img
                    alt="badge-qr-code"
                    className="w-32 mb-2"
                    src="/images/badge-qr-code.webp"
                  ></img>
                </div>
                <div className="w-full md:w-auto pr-2 flex justify-center mt-4">
                  <a href="https://apps.apple.com/us/app/bagang/id1568382221">
                    <img
                      alt="badge-app-store"
                      className="w-40 mb-2 rounded-[8px]"
                      src="/images/badge-app-store.webp"
                    ></img>
                  </a>
                </div>
                <div className="w-full md:w-auto pr-2 flex justify-center mt-4">
                  <a href="https://play.google.com/store/apps/details?id=com.giong">
                    <img
                      alt="badge-google-play"
                      className="w-40 mb-2 rounded-[8px]"
                      src="/images/badge-google-play.webp"
                    ></img>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
          <div className="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-3 lg:grid-cols-3">
            {/* <div>
              <p className="font-bold">Công ty</p>
              <nav className="flex flex-col mt-4 space-y-2 text-md text-white">
                <div className="hover:opacity-75">
                  Về chúng tôi
                </div>
                <div className="hover:opacity-75">
                  Lịch sử hình thành
                </div>
                <div className="hover:opacity-75">
                  Châm ngôn công ty
                </div>
              </nav>
            </div> */}
            <div>
              <b>Dịch vụ</b>
              <nav className="flex flex-col mt-4 space-y-2 text-md text-white">
                <Link className="hover:opacity-75" to="/create/motorbike">
                  Bảo hiểm trách nhiệm dân sự xe máy
                </Link>
                <Link className="hover:opacity-75" to="/create/car">
                  Bảo hiểm trách nhiệm dân sự xe ô tô
                </Link>
              </nav>
            </div>
            <div>
              <b>Trợ giúp</b>
              <nav className="flex flex-col mt-4 space-y-2 text-md text-white">
                <a className="hover:opacity-75" href="tel:1900068898">
                  Contact
                </a>
                <Link className="hover:opacity-75" to="/faq">
                  FAQs
                </Link>
              </nav>
            </div>
            <div>
              <b>Thông tin & Điều khoản</b>
              <nav className="flex flex-col mt-4 space-y-2 text-md text-white">
                <a target="_blank" className="hover:opacity-75 cursor-pointer" href="/poclicy">
                  Chính sách và điều khoản
                </a>
              </nav>
            </div>
          </div>
        </div>
        <p className="mt-8 text-md text-white">
          &copy; {new Date().getFullYear()} baohiemxe.ai
          <div className="text-gray-400">Powered by BAGang Tech</div>
        </p>
      </div>
    </footer>
  );
}
