import React, { useState } from "react";
import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import FAQSection from "../components/FAQ/FAQSection";

export default function FAQ() {
  return (
    <MainLayout>
      <div>
        <div
          className="w-full p-2 md:p-6 bg-white items-center mb-5"
          style={{ backgroundImage: "url('/images/bg-discover.webp')" }}
        >
          <Link to={"/"} className="flex justify-between items-center">
            <button className="w-12 h-12 items-center justify-center bg-linear-primary rounded-lg relative hover:opacity-75">
              <i className="text-[30px] text-white flex items-center justify-center">
                <ion-icon name="chevron-back-outline"></ion-icon>
              </i>
            </button>
            <h2 className="md:text-[22px] text-white text-center font-bold">
              CÂU HỎI THƯỜNG GẶP
            </h2>
          </Link>
        </div>
        <FAQSection />
        <div className="px-5 pb-5 leading-[30px]">
          <h2 className="text-[20px]">
            Giấy chứng nhận điện tử trong trường hợp{" "}
            <b>Công an không đồng ý?</b>
          </h2>
          <ul className="list-disc px-5">
            <li>
              Giấy chứng nhận điện tử có hiệu lực theo{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://thuvienphapluat.vn/van-ban/Bao-hiem/Nghi-dinh-03-2021-ND-CP-bao-hiem-bat-buoc-trach-nhiem-dan-su-cua-chu-xe-co-gioi-401513.aspx"
              >
                Nghị định 03/2021/NĐ-CP
              </a>{" "}
              và
              <a
                target="_blank"
                rel="noreferrer"
                className="ml-1 text-blue-600"
                href="https://thuvienphapluat.vn/van-ban/Bao-hiem/Thong-tu-04-2021-TT-BTC-huong-dan-bao-hiem-bat-buoc-trach-nhiem-dan-su-cua-chu-xe-co-gioi-454431.aspx"
              >
                Thông tư 04/2021/TT-BTC
              </a>{" "}
              ban hành cùng ngày 15/01/2021
            </li>
            <li>Hãy cố gắng thuyết phục đến cùng, đừng nộp tiền oan nhé!</li>
          </ul>
        </div>
      </div>
    </MainLayout>
  );
}
