import React from 'react'

export default function Back({ onClick = () => {}, btnText = 'Quay lại' }) {
  return (
    <div
      onClick={onClick}
      className="
            bg-primary-color
            text-white
            lg:px-3
            lg:py-2
            rounded-md
            cursor-pointer
            duration-300
            ease-in-out
            flex
            items-center
            justify-center
            px-2
            py-1
            text-sm
            text-center
        "
    >
      {btnText}
    </div>
  )
}
