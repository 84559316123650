import {
  EmailIcon,
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramShareButton,
} from 'react-share'
import LoadingSpinner from '../Loading/LoadingSpinner'
import ZaloShareButton from '../ZaloShare/ZaloShareButton'

export default function GetInsuranceSection({ linkFileCertificate, email }) {
  if (!linkFileCertificate)
    return (
      <div className="mt-2">
        <div className="flex justify-center p-5">
          <LoadingSpinner loading={true} size={50} />
        </div>
        <div className="text-[1.5rem] font-semibold">
          Giấy chứng nhận đang được tạo.<br></br>Điều này diễn ra không quá 30
          giây
        </div>
        {email && (
          <div className="mt-4">
            Bạn cũng có thể nhận qua <span className="font-bold">{email}</span>, vui lòng kiểm tra trong thư mục Inbox và Spam.
          </div>
        )}
      </div>
    )
  return (
    <div className="mt-2">
      <a href={linkFileCertificate} target="_blank" rel="noreferrer">
        <button className="bg-gray-600 text-white text-[15px] text-center h-12 w-60 font-medium hover:opacity-80 uppercase">
          Tải giấy bảo hiểm <i className="ml-1 fa-solid fa-download"></i>
        </button>
      </a>
      <div className="mt-4">
        <div className="font-bold pb-4">
          Chia sẻ ngay giấy chứng nhận bảo hiểm vừa nhận:
        </div>
        <div className="flex flex-wrap items-center justify-center">
          <div className="mx-2">
            <FacebookShareButton
              url={linkFileCertificate}
              hashtag="baohiemxe"
              quote="Giấy chứng nhận bảo hiểm xe siêu xịn từ baohiemxe.ai."
            >
              <img
                src="/images/facebook-logo.webp"
                className="h-[40px] w-[40px]"
              ></img>
            </FacebookShareButton>
          </div>
          <div className="mx-2">
            <FacebookMessengerShareButton
              appId="612416957285743"
              url={linkFileCertificate}
              title="Giấy chứng nhận bảo hiểm xe siêu xịn từ baohiemxe.ai."
            >
              <img
                src="/images/messenger-logo.webp"
                className="h-[40px] w-[40px]"
              ></img>
            </FacebookMessengerShareButton>
          </div>
          <div className="mx-2">
            <TelegramShareButton
              url={linkFileCertificate}
              title={linkFileCertificate}
            >
              <img
                src="/images/telegram-logo.webp"
                className="h-[40px] w-[40px]"
              ></img>
            </TelegramShareButton>
          </div>
          <div className="mx-2">
            <EmailShareButton
              url={linkFileCertificate}
              title="Giấy chứng nhận bảo hiểm xe siêu xịn từ baohiemxe.ai."
            >
              <EmailIcon size={40} />
            </EmailShareButton>
          </div>
          <div className="mx-2">
            <div
              className="flex items-center justify-center z-0"
              dangerouslySetInnerHTML={{
                __html: `<div class="zalo-share-button" data-href=${linkFileCertificate} data-oaid="3518713603628693068" data-layout="1" data-color="blue" data-share-type="2" data-customize="false"></div>`,
              }}
            ></div>
            {/* <ZaloShareButton dataHref={linkFileCertificate}></ZaloShareButton> */}
          </div>
        </div>
      </div>
      {email && (
        <div className="mt-4">
          Chúng tôi cũng đã gửi giấy chứng nhận của bạn về địa chỉ mail:{' '}. Vui lòng kiểm tra trong thư mục Inbox và Spam.
          <span className="font-bold">{email}</span>
        </div>
      )}
      <div className="text-green-600 mt-4">
        Cám ơn bạn đã tin tưởng và sử dụng dịch vụ của chúng tôi
      </div>
    </div>
  )
}
