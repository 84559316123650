import {useState} from "react";

export default function FAQBlock({title, children}) {
  const [showDropDown, setShowDropDown] = useState(false);
  return (
    <div className="mb-2">
      <button name="faq-block" type="button" onClick={() => setShowDropDown(!showDropDown)} className={`p-4 w-full flex items-center hover:opacity-80 ${showDropDown? 'bg-secondary-color text-white' : 'bg-white'}`}>
        <span className="font-semibold text-left">{title}</span> 
        <i className="ml-auto flex items-center text-[22px]">
          <ion-icon name="caret-down-outline"></ion-icon>
        </i>
      </button>
      {showDropDown ? <div className="p-4 bg-gray-100">{children}</div> : ''}
    </div>
  );
}
