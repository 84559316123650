import React from 'react'
import { Link } from 'react-router-dom';

export default function CarBikeChoose({ show, onClose}) {
    if (!show) return;
    return (
        <div onClick={() => onClose()} className='fixed animate__animated animate__fadeIn top-0 left-0 h-screen w-full bg-black bg-opacity-80 bg-blur z-50 flex items-center justify-center text-gray-100 overflow-hidden'>
            <div className='mb-32 font-semibold'>
                <div className='text-[2rem] md:text-[3rem] text-center'>Chọn loại bảo hiểm bạn quan tâm</div>
                <div className='flex justify-center text-[1.5rem]'>
                    <Link to="/create/motorbike" className='text-center min-w-24'>
                        <img className='w-24 h-24 md:w-48 md:h-48 mx-5 md:mx-16 hover:rotate-[-10deg]' src='/images/motorbike.webp'></img>
                        Xe máy
                    </Link>
                    <Link to="/create/car" className='text-center min-w-24'>
                        <img className='w-24 h-24 md:w-48 md:h-48 mx-5 md:mx-16 hover:animate-bounce' src='/images/car.webp'></img>
                        Xe Ô tô
                    </Link>
                </div>
            </div>
        </div>
    )
}
