import React from 'react'

export default function Logo() {
  return (
    <div
      className="
            w-fit
            bg-primary-color
            p-2
            absolute
            top-1/2
            left-1/2
            transform
            -translate-x-1/2
            -translate-y-1/2
            shadow-lg
        "
    >
      <img alt="banner" src="/images/banner.webp" className="w-[220px]"></img>
    </div>
  )
}
