import React from "react";
import MainLayout from "../layouts/MainLayout";

const PrivacyPolicy = () => {
    return (
        <MainLayout>
            <div className="bg-white m-auto w-[90%]">
                <div className="p-7">
                    <div className="text-center border-b border-solid border-slate-300 pb-[15px] mb-5 lg:flex lg:justify-between lg:items-center">
                        <h2 className="text-[20px] font-bold">Chính sách bảo mật</h2>
                        <div className="flex justify-center pt-3 lg:pt-0"><a href="/" className="text-gray-400 hover:text-black text-[15px]">Trang chủ</a><span className="mx-2">/</span><h3 className="font-semibold text-[15px]">Chính sách bảo mật</h3></div>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-black font-semibold">1. Mục đính thu thập thông tin cá nhân</h2>
                        <ul className="text-[15px] pt-2 pl-5">
                            <li>Mục đích của việc thu thập thông tin khách hàng nhằm liên quan đến các vấn đề như:</li>
                            <li>- Hỗ trợ khách hàng: mua hàng, thanh toán, giao hàng. </li>
                            <li>- Cung cấp thông tin sản phẩm, các dịch vụ và hỗ trợ theo yêu cầu của khách hàng.</li>
                            <li>- Gửi thông báo các chương trình, sản phẩm mới nhất của chúng tôi. </li>
                            <li>- Giải quyết vấn đề phát sinh khi mua hàng. </li>
                        </ul>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-black font-semibold">2. Phạm vi thu thập thông tin</h2>
                        <ul className="text-[15px] pt-2 pl-5 text-justify">
                            <li>Chúng tôi thu thập thông tin cá nhân của khách hàng khi tiến hàng đặt hàng trên website :</li>
                            <li>Họ  tên :</li>
                            <li>Địa chỉ email :</li>
                            <li>Số điện thoại :</li>
                            <li>Địa chỉ :</li>
                        </ul>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-black font-semibold">3. Thời gian lưu trữ thông tin</h2>
                        <div className="text-[15px] pt-2 pl-5">Dữ liệu cá nhân của Thành viên sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ hoặc tự thành viên đăng nhập và thực hiện hủy bỏ.
                            Còn lại trong mọi trường hợp thông tin cá nhân thành viên sẽ được bảo mật trên máy chủ của tnds.online .</div>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-black font-semibold">4. Những người hoặc tổ chức có thể được tiếp cận với thông tin đó</h2>
                        <ul className="text-[15px] pt-2 pl-5 text-justify">
                            <li>– Đối với các bên vận chuyển, sẽ cung cấp các thông tin để phục vụ cho việc giao nhận hàng hóa như Tên, địa chỉ và số điện thoại.</li> 
                            <li>– Đối với nhân viên công ty sẽ có các bộ phận chuyên trách để phục vụ việc chăm sóc khách hàng trong quá trình sử dụng sản phẩm.</li>
                            <li>– Các chương trình có tính liên kết, đồng thực hiện, thuê ngoài cho các mục đích được nêu tại Mục 1 và luôn áp dụng các yêu cầu bảo mật thông tin cá nhân.</li>
                            <li>– Yêu cầu pháp lý: Chúng tôi có thể tiết lộ các thông tin cá nhân nếu điều đó do luật pháp yêu cầu và việc tiết lộ như vậy là cần thiết một cách hợp lý để tuân thủ các quy trình pháp lý.</li>
                            <li>– Chuyển giao kinh doanh (nếu có): trong trường hợp sáp nhập, hợp nhất toàn bộ hoặc một phần với công ty khác, người mua sẽ có quyền truy cập thông tin được chúng tôi lưu trữ, duy trì trong đó bao gồm cả thông tin cá nhân. </li>
                        </ul>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-black font-semibold">5. Địa chỉ của đơn vị thu thập và quản lý thông tin</h2>
                        <ul className="text-[15px] pt-2 pl-5 text-justify">
                            <li>– Tên doanh nghiệp: CÔNG TY CỔ PHẦN CÔNG NGHỆ BAGANG</li>
                            <li>– Thành lập và hoạt động theo Giấy chứng nhận đăng ký doanh nghiệp số: 3702996002 do Sở Kế hoạch và Đầu tư tỉnh Bình Dương cấp ngày 20 tháng 08 năm 2021</li>
                            <li>–Trụ sở chính: 170 Lê Hồng Phong, Phường Phú lợi, Thành phố Thủ Dầu Một, Tỉnh Bình Dương, Việt Nam</li>
                        </ul>
                    </div>
                    <div className="mb-5">
                        <h2 className="text-black font-semibold">6. Phương thức và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu</h2>
                        <ul className="text-[15px] pt-2 pl-5 text-justify">
                            <li>Nếu quý khách có bất cứ về yêu cầu nào về việc tiếp cận và chỉnh sửa thông tin cá nhân đã cung cấp, quý khách có thể:</li>
                            <li>– Gọi điện trực tiếp về số điện thoại: (+84) 28 36222084</li>
                            <li>– Gửi mail: bagang@bagang.ai</li>
                        </ul>
                    </div>
                </div>

            </div>
        </MainLayout>
    )
}

export default PrivacyPolicy