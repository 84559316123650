import { MA_XE_3_BANH } from "../data/maLoaiXe";
import Axios from "./axios";

const formatPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace('+84', '0');
};

const formatMonthAndDate = (md) => {
  if (Number(md) < 10) {
    return `0${md}`;
  }
  return md;
};

const getFormattedPrice = (price = 0, currency = "VND") => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });
  const formattedPrice = formatter.format(price);
  return formattedPrice;
};

const convertDateToDateTime = (date) => {
  return new Date(date).toISOString();
};

const removeAscent = (str) => {
  if (str === null || str === undefined) return str;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  return str;
}

const isValidEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const isValidName = (name) => {
  //var re = /^[a-zA-Z ]*$/;
  //return re.test(removeAscent(name))
  return true
};

const isValidPhoneNumber = (phoneNumber) => {
  var re = /(((\+)84)|0)(3|5|7|8|9)+([0-9]{8})\b/;
  if (phoneNumber.length < 10) return null;
  return re.test(phoneNumber);
};

const getMaTrongTaiHDI = (trongTai) => {
  //MẶC ĐỊNH KHI CHƯA NHẬP TRỌNG TẢI SẼ LÀ DƯỚI 3 TẤN
  if (trongTai === "") return "D3T";
  let numTrongTai = Number(trongTai);
  let maTrongTai = "";
  if (numTrongTai < 3) maTrongTai = "D3T";
  else if (numTrongTai <= 3.5) maTrongTai = "T3D3.5";
  else if (numTrongTai <= 8) maTrongTai = "T3.5D8";
  else if (numTrongTai <= 10) maTrongTai = "T8D10";
  else if (numTrongTai <= 15) maTrongTai = "T10D15";
  else maTrongTai = "T15T";
  return maTrongTai;
};

const createBodyInsurancesMotorbike = ({
  plateNumber,
  engineNumber,
  chassisNumber,
  fullName,
  address,
  phoneNumber,
  email,
  type,
  typeCode,
  price,
  paymentMethod,
  yearCount,
  thirdParty,
  dateStartContract,
  dateEndContract,
  insuranceProvider,
  priceInfoVNI,
  priceInfoAAA,
  nguoiGioiThieu
}) => {
  const dateOfBirth = "1990-01-01T00:00:00";
  const idDate = "2021-01-01T00:00:00";
  let body = {
    UId: "TBA",
    InsuranceTerm: 2,
    PlateNumber: plateNumber,
    EngineNumber: engineNumber,
    InceptionDate: convertDateToDateTime(dateStartContract),
    ExpireDate: convertDateToDateTime(dateEndContract),
    FullName: fullName,
    DateOfBirth: dateOfBirth,
    Nationality: "",
    IdNo: "",
    PhoneNumber: formatPhoneNumber(phoneNumber),
    Email: email,
    Address: address,
    Province: "",
    GrossAmount: price,
    NetAmount: price,
    VoucherAmount: 0,
    InsuranceVehicle: type,
    InsuranceName: "",
    ThirdParty: thirdParty ? 1 : 0,
    InsuranceFullName: fullName,
    InsuranceAddress: address,
    PaymentType: paymentMethod,
    ChassisNumber: chassisNumber,
    IdDate: idDate,
    IdPlace: "",
    Career: "",
    CareerPosition: "",
    VisaCode: "",
    ContractFullName: fullName,
    ContractAddress: address,
    TaxCode: "",
    InsurancePermanentAddress: "",
    LoaiXe: typeCode,
    MucDichSuDung: "KKD",
    TrongTai: "",
    SoCho: typeCode === MA_XE_3_BANH ? "3" : "2",
    MucGiaXe: "",
    BaoHiemHangHoa: "",
    BaoHiemNguoiNgoiTrenXe: "",
    ThietHaiHanhKhach: "",
    ThietHaiNguoiThu3: "",
    ThietHaiTaiSanNguoiThu3: "",
    NhaBaoHiem: insuranceProvider,
    NguoiGioiThieu: nguoiGioiThieu
  };
  if (insuranceProvider === "VNI") {
    body = { ...body, ...priceInfoVNI };
  }
  if (insuranceProvider === "AAA") {
    body = { ...body, ...priceInfoAAA };
  }
  return body;
};

const createBodyInsurancesCar = ({
  plateNumber,
  engineNumber,
  chassisNumber,
  fullName,
  address,
  phoneNumber,
  email,
  price,
  paymentMethod,
  thirdParty,
  dateStartContract,
  dateEndContract,
  insuranceProvider,
  load,
  seat,
  selectedCarType,
  selectedBusiness,
  selectedInsuranceForCarSitter,
  priceInfoVNI,
  priceInfoAAA,
  nguoiGioiThieu
}) => {
  const dateOfBirth = "1990-01-01T00:00:00";
  const idDate = "2021-01-01T00:00:00";
  let body = {
    UId: "TBA",
    InsuranceTerm: 1,
    PlateNumber: plateNumber,
    EngineNumber: engineNumber,
    InceptionDate: convertDateToDateTime(dateStartContract),
    ExpireDate: convertDateToDateTime(dateEndContract),
    FullName: fullName,
    DateOfBirth: dateOfBirth,
    Nationality: "",
    IdNo: "",
    PhoneNumber: formatPhoneNumber(phoneNumber),
    Email: email,
    Address: address,
    Province: "",
    GrossAmount: price,
    NetAmount: price,
    VoucherAmount: 0,
    InsuranceVehicle: 4,
    InsuranceName: null,
    ThirdParty: thirdParty ? 1 : 0,
    InsuranceFullName: fullName,
    InsuranceAddress: address,
    PaymentType: paymentMethod,
    ChassisNumber: chassisNumber,
    IdDate: idDate,
    IdPlace: "",
    Career: "",
    CareerPosition: "",
    VisaCode: "",
    ContractFullName: fullName,
    ContractAddress: address,
    TaxCode: "",
    InsurancePermanentAddress: "",
    LoaiXe: selectedCarType,
    MucDichSuDung: selectedBusiness,
    SoCho: seat.toString(),
    TrongTai: load.toString(),
    MucGiaXe: "",
    BaoHiemHangHoa: "",
    BaoHiemNguoiNgoiTrenXe: "",
    ThietHaiHanhKhach: "",
    ThietHaiNguoiThu3: "",
    ThietHaiTaiSanNguoiThu3: "",
    GiaTriTrongTai: load.toString(),
    NhaBaoHiem: insuranceProvider,
    NguoiGioiThieu: nguoiGioiThieu
  };
  console.log("priceInfoAAA",priceInfoAAA)
  if (insuranceProvider === "HDI") {
    body.TrongTai = getMaTrongTaiHDI(load);
    body.BaoHiemNguoiNgoiTrenXe = selectedInsuranceForCarSitter;
  } else if (insuranceProvider === "VNI") {
    body = { ...body, ...priceInfoVNI };
  } else if (insuranceProvider === "AAA") {
    body = { ...body, ...priceInfoAAA };
  }
  return body;
};

// CẤU HÌNH PAYLOAD ĐỂ TẠO ĐƠN CHO BẢO HIỂM XE MÁY AAA
const createPriceInfoTNDSAAA = ({ priceInfoAAA, res }) => {
  priceInfoAAA.TNDSTienVat = res.data.data.tndsvat;
  return priceInfoAAA;
};
/// Hàm phía dưới sẽ được chạy khi ng dùng chọn vào ng ngồi sau
const createPriceInfoNTXAAA = ({ priceInfoAAA, res }) => {
  priceInfoAAA.NTXPhiCoVat = res.data.data.ntxPhiCoVat;
  return priceInfoAAA;
};

// CẤU HÌNH PAYLOAD ĐỂ TẠO ĐƠN CHO BẢO HIỂM XE MÁY VNI
const createPriceInfoTNDSVNI = ({ priceInfoVNI, res }) => {
  priceInfoVNI.TNDSTyLe = res.data.data.tyle;
  priceInfoVNI.TNDSPhiChuaVat = res.data.data.phiChuaVat;
  priceInfoVNI.TNDSVat = res.data.data.vat;
  priceInfoVNI.TNDSTienVat = res.data.data.tienVat;
  priceInfoVNI.TNDSPhiCoVat = res.data.data.phiCoVat;
  return priceInfoVNI;
};



const createPriceInfoNTXVNI = ({ priceInfoVNI, res }) => {
  priceInfoVNI.NTXTyLe = res.data.data.tyle;
  priceInfoVNI.NTXPhiChuaVat = res.data.data.phiChuaVat;
  priceInfoVNI.NTXVat = res.data.data.vat;
  priceInfoVNI.NTXTienVat = res.data.data.tienVat;
  priceInfoVNI.NTXPhiCoVat = res.data.data.phiCoVat;
  return priceInfoVNI;
};

const getPriceInfoVNIDefault = () => {
  return {
    TNDSTyLe: 0,
    TNDSPhiChuaVat: 0,
    TNDSVat: 0,
    TNDSTienVat: 0,
    TNDSPhiCoVat: 0,
    NTXTyLe: 0,
    NTXPhiChuaVat: 0,
    NTXVat: 0,
    NTXTienVat: 0,
    NTXPhiCoVat: 0,
  };
};

const getPriceInfoAAADefault = () => {
  return {
    TNDSTienVat: 0,
    NTXPhiCoVat: 0,
  };
};


const getPriceMotobikeHDI = ({ thirdParty, type, yearCount }) => {
  let extra = 0;
  if (thirdParty) {
    switch (type) {
      case 1:
        extra = 20000 * yearCount;
        break;
      case 2:
        extra = 20000 * yearCount;
        break;
      case 3:
        extra = 30000 * yearCount;
        break;
      default:
        extra = 0;
        break;
    }
  }
  switch (type) {
    case 1:
      return ((55000 * 110) / 100) * yearCount + extra;
    case 2:
      return ((60000 * 110) / 100) * yearCount + extra;
    case 3:
      return ((290000 * 110) / 100) * yearCount + extra;
    default:
      return 0;
  }
};

const getPriceMotobikeVNI = ({
  type,
  thirdParty,
  yearCount,
  dateStartContract,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let price = 0;
      // Tạo object chứa thông tin chi tiết bảo hiểm để làm payload khi tạo đơn, trong đó default gồm 10 trường giá trị rỗng
      let priceInfoVNI = getPriceInfoVNIDefault();
      const dateEndContract = getDateEndContract({
        dateStartContract,
        yearCount,
      });
      const body = {
        InceptionDate: dateStartContract,
        ExpireDate: dateEndContract,
        InsuranceVehicle: type,
        SoCho: "2",
      }; //tam thoi de 2 cho cho moi loai xe may
      const res = await Axios.post("/api/insurance/FeeVNIMoto", body);
      const feeVNIMoto = res.data.data.phiCoVat;
      // Thêm thông tin chi tiết bảo hiểm để làm payload khi tạo đơn
      priceInfoVNI = createPriceInfoTNDSVNI({ priceInfoVNI, res });

      if (feeVNIMoto > 0) {
        price += feeVNIMoto;
      } else {
        reject("Giá bảo hiểm VNI không hợp lệ");
      }

      // Nếu có mua bảo hiểm người ngồi trên xe
      if (thirdParty) {
        const res = await Axios.post("/api/insurance/FeeVNIMotoPeople", body);
        const feeVNIMotoPeople = res.data.data.phiCoVat;
        // Thêm thông tin chi tiết bảo hiểm để làm payload khi tạo đơn
        priceInfoVNI = createPriceInfoNTXVNI({ priceInfoVNI, res });
        if (feeVNIMotoPeople >= 0) {
          price += feeVNIMotoPeople;
        } else {
          reject("Giá bảo hiểm VNI không hợp lệ");
        }
      }

      resolve({ priceInfoVNI, price });
    } catch (error) {
      reject(error);
    }
  });
};

const getPriceMotobikeAAA = ({
  thirdParty,
  yearCount,
  dateStartContract,
  typeCode
}) => {
  return new Promise(async (resolve, reject) => {
    console.log("typeCode", typeCode)
    try {
      let price = 0;
      // Tạo object chứa thông tin chi tiết bảo hiểm để làm payload khi tạo đơn, trong đó default gồm 10 trường giá trị rỗng
      let priceInfoAAA = getPriceInfoAAADefault();
      const dateEndContract = getDateEndContract({
        dateStartContract,
        yearCount,
      });
      const body = {
        InceptionDate: dateStartContract,
        ExpireDate: dateEndContract,
        SoCho: typeCode === MA_XE_3_BANH ? 3 : 2,
        LoaiXe: typeCode,
        NguoiNgoiTrenXe: thirdParty ? true : false,
        TrongTai: 0, // Xe máy không có trọng tải
      }; //tam thoi de 2 cho cho moi loai xe may
      const res = await Axios.post("/api/insurance/FeeAAA", body);
      const feeAAAMoto = res.data.data.tndsvat;
      // Thêm thông tin chi tiết bảo hiểm để làm payload khi tạo đơn
      priceInfoAAA = createPriceInfoTNDSAAA({ priceInfoAAA, res });
   
      if (feeAAAMoto > 0) {
        price += feeAAAMoto;
      } else {
        reject("Giá bảo hiểm AAA không hợp lệ");
      }
      console.log("priceInfoAAA", price)
      // Nếu có mua bảo hiểm người ngồi trên xe
      if (thirdParty) {
        const feeAAAMotoPeople = res.data.data.ntxPhiCoVat;
        // Thêm thông tin chi tiết bảo hiểm để làm payload khi tạo đơn
        priceInfoAAA = createPriceInfoNTXAAA({ priceInfoAAA, res });
        if (feeAAAMotoPeople >= 0) {
         
          price += feeAAAMotoPeople;
        } else {
          reject("Giá bảo hiểm AAA không hợp lệ");
        }
      }
      
      resolve({ priceInfoAAA, price });
    } catch (error) {
      reject(error);
    }
  });
};



// Hàm kiểm tra xe có cần trọng tải không, tham số tham gia gồm danh sách xe và loại xe cần kiểm tra
const isRequiredLoad = ({ optionsCarType, selectedCarType }) => {
  const trongTai = optionsCarType.find(
    (carType) => carType.value === selectedCarType
  )?.trongTai;
  return !!trongTai;
};

const getPriceCarHDI = ({
  selectedCarType,
  seat,
  selectedInsuranceForCarSitter,
  selectedBusiness,
  load,
  dateStartContract,
  optionsCarType,
}) => {
  return new Promise((resolve, reject) => {
    const body = {
      InceptionDate: dateStartContract,
      ExpireDate: getDateEndContract({ dateStartContract, yearCount: 1 }),
      LoaiXe: selectedCarType,
      MucDichSuDung: selectedBusiness,
      TrongTai: isRequiredLoad({ selectedCarType, optionsCarType })
        ? getMaTrongTaiHDI(load)
        : "",
      GiaTriTrongTai: isRequiredLoad({ selectedCarType, optionsCarType })
        ? load
        : "",
      SoCho: seat.toString(),
      MucGiaXe: "",
      BaoHiemHangHoa: "",
      BaoHiemNguoiNgoiTrenXe: selectedInsuranceForCarSitter,
      ThietHaiHanhKhach: "",
      ThietHaiNguoiThu3: "",
      ThietHaiTaiSanNguoiThu3: "",
    };
    Axios.post("/api/insurance/FeeHDI", body)
      .then((response) => {
        if (response.data.Data && response.data.Data.TOTAL_AMOUNT)
          resolve({ price: response.data.Data.TOTAL_AMOUNT });
        else reject("Không thể tính phí bảo hiểm");
      })
      .catch(() => {
        reject("Có lỗi xảy ra, vui lòng thử lại sau");
      });
  });
};

const getPriceCarVNI = ({
  selectedCarType,
  seat,
  selectedInsuranceForCarSitter,
  selectedBusiness,
  load,
  dateStartContract,
  thirdParty,
}) => {
  return new Promise(async (resolve, reject) => {
    const body = {
      InceptionDate: dateStartContract,
      ExpireDate: getDateEndContract({ dateStartContract, yearCount: 1 }),
      LoaiXe: selectedCarType,
      MucDichSuDung: selectedBusiness,
      SoCho: seat.toString(),
      TrongTai: load.toString(),
    };
    let price = 0;
    let priceInfoVNI = getPriceInfoVNIDefault();
    const res = await Axios.post("/api/insurance/FeeVNIOto", body);
    const feeVNIOto = res.data.data.phiCoVat;
    priceInfoVNI = createPriceInfoTNDSVNI({ priceInfoVNI, res });
    if (feeVNIOto > 0) {
      price += feeVNIOto;
    } else {
      reject("Giá bảo hiểm VNI không hợp lệ");
    }

    // Nếu có mua bảo hiểm người ngồi trên xe
    if (thirdParty && selectedInsuranceForCarSitter) {
      const res = await Axios.post("/api/insurance/FeeVNIOtoPeople", body);
      const feeVNIOtoPeople = res.data.data.phiCoVat;
      // Thêm thông tin chi tiết bảo hiểm để làm payload khi tạo đơn
      priceInfoVNI = createPriceInfoNTXVNI({ priceInfoVNI, res });
      if (feeVNIOtoPeople >= 0) {
        price += feeVNIOtoPeople;
      } else {
        reject("Giá bảo hiểm VNI không hợp lệ");
      }
    };

    resolve({ priceInfoVNI, price });
  });
};

const getPriceCarAAA = ({
  selectedCarType,
  seat,
  selectedInsuranceForCarSitter,
  selectedBusiness,
  load,
  dateStartContract,
  thirdParty,
  BaoHiemNguoiNgoiTrenXe
}) => {
  return new Promise(async (resolve, reject) => {
    const body = {
      InceptionDate: dateStartContract,
      ExpireDate: getDateEndContract({ dateStartContract, yearCount: 1 }),
      LoaiXe: selectedCarType,
      MucDichSuDung: selectedBusiness,
      SoCho: parseInt(seat),
      TrongTai: load === "" ? 0 : parseInt(load),
      nguoiNgoiTrenXe: BaoHiemNguoiNgoiTrenXe
    };
    let price = 0;
    let priceInfoAAA = getPriceInfoAAADefault();
    const res = await Axios.post("/api/insurance/FeeAAA", body);
    const feeAAAOto = res.data.data.tndsvat;
    priceInfoAAA = createPriceInfoTNDSAAA({ priceInfoAAA, res });
    if (feeAAAOto > 0) {
      price += feeAAAOto;
    } else {
      reject("Giá bảo hiểm AAA không hợp lệ");
    }

    // Nếu có mua bảo hiểm người ngồi trên xe
    if (thirdParty && selectedInsuranceForCarSitter) {
      // const res = await Axios.post("/api/insurance/FeeVNIOtoPeople", body);
      const feeAAAOtoPeople = res.data.data.ntxPhiCoVat;
      // Thêm thông tin chi tiết bảo hiểm để làm payload khi tạo đơn
      priceInfoAAA = createPriceInfoNTXAAA({ priceInfoAAA, res });
      if (feeAAAOtoPeople >= 0) {
        price += feeAAAOtoPeople;
        console.log("feeAAAOtoPeople", feeAAAOtoPeople)
      } else {
        reject("Giá bảo hiểm AAA không hợp lệ");
      }
    };

    resolve({ priceInfoAAA, price });
  });
};



const getDateEndContract = ({ dateStartContract, yearCount }) => {
  const _dateEndContract = new Date(dateStartContract);
  _dateEndContract.setDate(_dateEndContract.getDate() + 365 * yearCount);
  return _dateEndContract.toISOString().split("T")[0];
};

export {
  formatMonthAndDate,
  getFormattedPrice,
  convertDateToDateTime,
  isValidName,
  isValidEmail,
  isValidPhoneNumber,
  getMaTrongTaiHDI,
  createBodyInsurancesMotorbike,
  createBodyInsurancesCar,
  getPriceMotobikeHDI,
  getPriceMotobikeVNI,
  getPriceCarHDI,
  getPriceCarVNI,
  getPriceInfoVNIDefault,
  getDateEndContract,
  isRequiredLoad,
  getPriceInfoAAADefault,
  getPriceMotobikeAAA,
  getPriceCarAAA,
};
