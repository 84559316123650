import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import DataSP from "../data/DataSP";
import PriceMotoSelectlock from "../components/PriceSelectBlock/PriceMotoSelectlock";
import "react-html5-camera-photo/build/css/index.css";
import {
  createBodyInsurancesMotorbike,
  getDateEndContract,
  getPriceInfoAAADefault,
  getPriceInfoVNIDefault,
  getPriceMotobikeAAA,
  getPriceMotobikeHDI,
  getPriceMotobikeVNI,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../functions";
import Axios from "../functions/axios";
import TitleBanner from "../components/Banner/TitleBanner";
import CommonInput from "../components/Input/CommonInput";
import CameraSection from "../components/Camera/CameraSection";
import PaymentMethodSection from "../components/PaymentMethod/PaymentMethodSection";
import axios from "axios";
import qs from "qs";
import PriceFooter from "../components/Footer/PriceFooter";
import ShopLayout from "../layouts/ShopLayout";
import InsuranceProviderSection from "../components/InsuranceProvider/InsuranceProviderSection";
import ContactRegisterSection from "../components/ContactRegister/ContactRegisterSection";
import Loading from "../components/Loading/Loading";
import { addSuccessModal } from "../reducers/modal/dialogModalSlice";
import { MA_XE_TREN_50CC } from "../data/maLoaiXe";
import { useParams } from "react-router";

const MotorbikeInsuranceForm = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const formRef = useRef(null);
  const [uri, setUri] = React.useState("");
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [yearCount, setYearCount] = useState(1);
  const [error, setError] = useState({
    plateNumber: "",
    fullName: "",
    address: "",
    engineNumber: "",
    chassisNumber: "",
  });

  // Biển số
  const [plateNumber, setPlateNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  // Số máy
  const [engineNumber, setEngineNumber] = useState("");
  // Số khung
  const [chassisNumber, setChassisNumber] = useState("");
  // Sdt người đăng ký
  const [phoneNumber, setPhoneNumber] = useState("");
  // Email người đăng ký
  const [email, setEmail] = useState("");
  // Có bảo hiểm tự nguyện hay không
  const [thirdParty, setThirdParty] = useState(1);
  // Loại xe
  const [type, setType] = useState(2);
  // Mã loại xe
  const [typeCode, setTypeCode] = useState(MA_XE_TREN_50CC);

  // Phương thức thanh toán: 10: ZaloPay, 12: Payoo
  const [paymentMethod, setPaymentMethod] = useState(10);
  // Phương thức thanh toán zalopay: "": tât cả phương thức, "zalopayapp": app zalo pay, "CC": credit card,...
  // Xem thêm tại https://docs.zalopay.vn/v1/docs/gateway/guide.html
  const [bankCodeZalo, setBankCodeZalo] = useState("zalopayapp");
  // Nhà cung cấp bảo hiểm. "HDI": HDI, "VNI": VNI
  const [insuranceProvider, setInsuranceProvider] = useState("AAA");
  const [previewBody, setPreviewBody] = useState({});
  // Ngày bắt đầu bảo hiểm
  const [dateStartContract, setDateStartContract] = useState(
    new Date().toISOString().split("T")[0]
  );
  // Ngày kết thúc bảo hiểm
  const [dateEndContract, setDateEndContract] = useState(
    new Date().toISOString().split("T")[0]
  );
  // Ngày kết thúc bảo hiểm tối đa. Tính dựa trên ngày bắt đầu bảo hiểm
  const [maxDateEndContract, setMaxDateEndContract] = useState(
    new Date().toISOString().split("T")[0]
  );

  // Khi tạo đơn VNI, cần lấy vài thông tin giá bảo hiểm từ VNI
  const [priceInfoVNI, setPriceInfoVNI] = useState(getPriceInfoVNIDefault());

  // Khi tạo đơn AAA, cần lấy vài thông tin giá bảo hiểm từ AAA
  const [priceInfoAAA, setPriceInfoAAA] = useState(getPriceInfoAAADefault());

  const handleSelectYear = (year) => {
    setYearCount(year);
  };

  useEffect(() => {
    if (orderId) {
      setLoading(true);
      Axios.get(`/api/insurance/GetInsuranceOrderById/${orderId}`)
        .then((res) => {
          const data = res.data.data;
          setPlateNumber(data.detail.plateNumber);
          setFullName(data.detail.fullName);
          setAddress(data.detail.address);
          setPhoneNumber(data.detail.phoneNumber);
          setEmail(data.detail.email);
          setThirdParty(data.detail.thirdParty);
          setType(data.detail.insuranceVehicle);
          setDateStartContract(() => {
            const date = new Date(data.detail.expireDate);
            date.setDate(date.getDate() + 1);
            return date.toISOString().split("T")[0];
          });
          setYearCount(
            new Date(data.detail.expireDate).getFullYear() -
              new Date(data.detail.inceptionDate).getFullYear()
          );
          // data.detail.nhaBaoHiem &&
          //   setInsuranceProvider(data.detail.nhaBaoHiem);
          setThirdParty(data.detail.thirdParty);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  // Tính giá mỗi lần chọn loại xe, số năm, bào hiểm tự nguyện, nhà bảo hiểm
  useEffect(() => {
    if (insuranceProvider === "HDI" || insuranceProvider === "BSH") {
      setPrice(getPriceMotobikeHDI({ type, thirdParty, yearCount }));
      setPriceInfoVNI(getPriceInfoVNIDefault());
    } else if (insuranceProvider === "VNI") {
      setLoading(true);
      getPriceMotobikeVNI({ type, thirdParty, yearCount, dateStartContract })
        .then((res) => {
          setPrice(res.price);
          // set price info vni để tí tạo đơn
          setPriceInfoVNI(res.priceInfoVNI);
        })
        .catch((err) => {
          console.log(err);
          setPrice(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (insuranceProvider === "AAA") {
      setLoading(true);
      getPriceMotobikeAAA({
        type,
        thirdParty,
        yearCount,
        dateStartContract,
        typeCode,
      })
        .then((res) => {
          setPrice(res.price);
          // set price info aaa để tí tạo đơn
          setPriceInfoAAA(res.priceInfoAAA);
          console.log(res.priceInfoAAA);
        })
        .catch((err) => {
          console.log(err);
          setPrice(0);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [type, yearCount, thirdParty, insuranceProvider, dateStartContract]);

  // Dev, preview body xem body sao
  // useEffect(() => {
  //   const _previewBody = createBodyInsurancesMotorbike({ plateNumber, engineNumber, chassisNumber, dateStartContract, dateEndContract, fullName, phoneNumber, email, address, price, type, thirdParty, paymentMethod });
  //   setPreviewBody(_previewBody);
  // }, [plateNumber, engineNumber, chassisNumber, dateStartContract, dateEndContract, fullName, phoneNumber, email, address, price, type, thirdParty, paymentMethod]);

  // Tính ngày kết thúc bảo hiểm và ngày kết thúc tối đa
  useEffect(() => {
    const _dateEndContract = getDateEndContract({
      dateStartContract,
      yearCount,
    });
    setDateEndContract(_dateEndContract);
    setMaxDateEndContract(_dateEndContract);
  }, [dateStartContract, yearCount]);

  function handleTakePhotoAnimationDone(dataUri) {
    setUri(dataUri);
  }

  const isValidated = () => {
    let validated = true;
    let _error = {};
    if (phoneNumber === "") {
      validated = false;
      _error.phoneNumber = "Vui lòng nhập số điện thoại";
    } else if (!isValidPhoneNumber(phoneNumber)) {
      validated = false;
      _error.phoneNumber = "Số điện thoại không hợp lệ";
    }
    if (email === "") {
      validated = false;
      _error.email = "Vui lòng nhập email";
    } else if (!isValidEmail(email)) {
      validated = false;
      _error.email = "Email không hợp lệ";
    }

    if (plateNumber === "") {
      validated = false;
      _error.plateNumber = "Vui lòng nhập biển số xe";
    }
    if (fullName === "") {
      validated = false;
      _error.fullName = "Vui lòng nhập họ tên";
    } else if (!isValidName(fullName)) {
      validated = false;
      _error.fullName = "Họ tên không hợp lệ";
    }

    if (address === "") {
      validated = false;
      _error.address = "Vui lòng nhập địa chỉ";
    }
    setError(_error);
    return validated;
  };

  const handleThirdPartyOnChange = () => {
    if (thirdParty)
      return dispatch(
        addSuccessModal({
          title: "XÁC NHẬN HỦY",
          content:
            "<div className='text-lg uppercase'><b>HỦY ĐĂNG KÝ BẢO HIỂM TỰ NGUYỆN</b> sẽ bỏ đi rất nhiều <b>quyền lợi của bạn và người ngồi sau xe</b>! Bạn có chắc chắn muôn bỏ?</div>",
          rightButtonText: "XÁC NHẬN",
          rightButtonFunction: () => setThirdParty(false),
        })
      );
    setThirdParty(!thirdParty);
  };

  function handleSetType(value) {
    setType(value);
    // setSection(section + 1);
  }

  function handleSetTypeCode(value) {
    setTypeCode(value);
    // setSection(section + 1);
  }

  const handlePaymentOnClick = ({ nguoiGioiThieu = "" }) => {
    if (loading) return;
    handlePayment({
      nguoiGioiThieu,
    });
  };

  const scrollToInput = () => {
    formRef.current.scrollIntoView();
  };

  const handlePayment = async ({ nguoiGioiThieu = "" }) => {
    if (!isValidated()) return scrollToInput();
    const body = createBodyInsurancesMotorbike({
      plateNumber,
      engineNumber,
      chassisNumber,
      fullName,
      address,
      phoneNumber,
      email,
      type,
      typeCode,
      price,
      paymentMethod,
      yearCount,
      thirdParty,
      dateStartContract,
      dateEndContract,
      insuranceProvider,
      priceInfoVNI,
      priceInfoAAA,
      nguoiGioiThieu,
    });
    console.log("BODY", body);
    setLoading(true);

    Axios.post("/api/insurance/CreateInsurance", body)
      .then((response) => {
        console.log(response);
        const orderId = response.data.data?.id;
        if (!orderId) {
          setLoading(false);
          return alert("Có lỗi xảy ra vui lòng quay lại sau");
        }
        if (paymentMethod === 14) {
          return;
        }
        let params = {};
        // OnePay
        if (paymentMethod === 10) {
          params = {
            VoucherOrderId: orderId,
            AppId: 7,
            redirectUrl:
              window.location.origin +
              `/payment/completed?paymentMethod=${paymentMethod}`,
          };
        } else {
          params = {
            orderId: orderId,
            repay: false,
            appId: 7, //web
            redirectUrl:
              window.location.origin +
              `/payment/completed?paymentMethod=${paymentMethod}`,
          };
        }

        // ZaloPay
        if (paymentMethod === 12) {
          params = {
            VoucherOrderId: orderId,
            appId: 7,
            redirectUrl:
              window.location.origin +
              `/payment/completed?paymentMethod=${paymentMethod}&orderId=${orderId}`,
            bankcode: "zalopayapp",
          };
        }
        // Axios.post('/api/userrole/userjoint', { email: email, fullName: fullName, phoneNumber: phoneNumber, linkEmailRegister: `${window.location.origin}/activate-account?email=${email}&token=` })
        //   .then(() => {
        const stringParams = new URLSearchParams(params).toString();
        let url = "/api/ProductOnline";
        // Momo
        if (paymentMethod === 2)
          url = "/api/ProductOnline/PayByMomo" + "?" + stringParams;
        // Payoo
        if (paymentMethod === 3)
          url = "/api/ProductOnline/GeneratePayooOrder" + "?" + stringParams;
        // OnePay
        if (paymentMethod === 10)
          url = "/api/ProductOnline/PayByOnePay" + "?" + stringParams;
        // ZaloPay
        if (paymentMethod === 12)
          url = "/api/ProductOnline/PayByZalo" + "?" + stringParams;

        Axios.post(url)
          .then((response) => {
            if (paymentMethod === 3) {
              const params = {
                data: response.data.data.payooOrder.payooOrder,
                checksum: response.data.data.payooOrder.payooOrderCheckSum,
                refer: window.location.origin,
                payment_group: "",
              };
              axios({
                method: "POST",
                headers: {
                  "content-type": "application/x-www-form-urlencoded",
                },
                data: qs.stringify(params),
                url: "https://newsandbox.payoo.com.vn/v2/paynow/order/create",
              }).then((response) => {
                const url = response.data.order?.payment_url;
                if (!url) return alert("Có lỗi xảy ra vui lòng quay lại sau");
                window.location.href = response.data.order.payment_url;
              });
            }
            //Momo hoặc onepay thì không cần gọi api lần nữa
            if (
              paymentMethod === 2 ||
              paymentMethod === 10 ||
              paymentMethod === 12
            ) {
              console.log(response);
              const url = response.data.data?.res;
              if (!url) return alert("Có lỗi xảy ra vui lòng quay lại sau");
              window.location.href = url;
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra vui lòng quay lại sau");
          })
          .finally(() => {
            setLoading(false);
          })
          .catch(() => {
            alert("Có lỗi xảy ra vui lòng quay lại sau");
            setLoading(false);
          });
      })
      .catch(() => {
        alert("Thanh toán thất bại");
        setLoading(false);
      });
  };

  const [showCam, setShowCam] = useState(0);
  const handleShowCam = () => setShowCam(true);
  const handleInput = (cavet) => {
    console.log(cavet);
    setPlateNumber(cavet.plate ? cavet.plate : "");
    setFullName(cavet.vehical_owner_name ? cavet.vehical_owner_name : "");
    setAddress(cavet.vehical_owner_address ? cavet.vehical_owner_address : "");
    // setSection(section + 1);
  };
  // console.log(cavetInfo.plate)

  // const [showEngineChassis, setShowEngineChassis] = useState(false)
  return (
    <ShopLayout>
      {/* Loading nguyên page để ngăn người dùng thao tác qua lại 2 nhà bảo hiểm VNI(async) & HDI(sync), một cái sync một cái async sẽ bị loạn giá */}
      <Loading loading={loading} opacity={0.5} />
      <div
        style={{
          backgroundImage: 'url("/images/bg-shop.webp")',
          backgroundSize: "fit",
          backgroundPosition: "bottom",
        }}
      >
        <div className="max-w-[1200px]  bg-white mx-auto border  pt-4">
          <TitleBanner title="THÔNG TIN LOẠI XE" />
          <div className="flex flex-wrap">
            {DataSP.map((val, ind) => {
              return (
                <div key={ind} className="w-full xs:w-1/3 px-1 md:px-2 mb-5">
                  <a href="#cam">
                    <PriceMotoSelectlock
                      image={val.image}
                      title={val.title}
                      type={val.type}
                      // description={val.description}
                      currentType={type}
                      price={val.price}
                      handleSetType={handleSetType}
                      typeCode={val.typeCode}
                      handleSetTypeCode={handleSetTypeCode}
                    />
                  </a>
                </div>
              );
            })}
          </div>

          <div>
            <TitleBanner title="THÔNG TIN ĐĂNG KÝ" />
            <CameraSection
              uri={uri}
              handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
              handleShowCam={handleShowCam}
              showCam={showCam}
              onUpload={handleInput}
              scrollToInput={scrollToInput}
              id="cam"
            />

            <form ref={formRef} className="px-5">
              <CommonInput
                field="Tên chủ xe"
                name="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Điền tên chủ xe. vd: Nguyen Van A"
                maxLength={50}
                error={error.fullName}
                required
              />

              <CommonInput
                field="Địa chỉ"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Điền địa chỉ. vd: 123 Đường ABC, Phường XYZ, Quận 1, TP.HCM"
                maxLength={100}
                error={error.address}
                required
              />
              <CommonInput
                field="Biển số xe"
                name="plateNumber"
                value={plateNumber}
                onChange={(e) => setPlateNumber(e.target.value)}
                placeholder="Điền biển số xe. vd: 59T1-999.99"
                maxLength={11}
                error={error.plateNumber}
                required
              />

              {/* <CommonInput
              field="Số máy"
              name="engineNumber"
              value={engineNumber}
              onChange={(e) => setEngineNumber(e.target.value)}
              placeholder="Điền số máy...."
              maxLength={30}
              error={error.engineNumber}
            />

            <CommonInput
              field="Số khung"
              name="chassisNumber"
              value={chassisNumber}
              onChange={(e) => setChassisNumber(e.target.value)}
              placeholder="Điền số khung...."
              maxLength={30}
              error={error.chassisNumber}
            /> */}
            </form>

            <ContactRegisterSection
              phoneNumber={phoneNumber}
              email={email}
              setEmail={setEmail}
              setPhoneNumber={setPhoneNumber}
              error={error}
            />

            <TitleBanner title={"ThỜI HẠN BẢO HIỂM"} />

            <div className="flex items-center justify-center mb-4">
              <button
                type="button"
                id={"1"}
                onClick={() => {
                  handleSelectYear(1);
                }}
                className={`py-2 uppercase border-2 rounded-[4px] font-bold w-1/3 md:w-1/5 mx-2 xs:mx-5 flex items-center justify-center ${
                  yearCount === 1
                    ? "border-primary-color bg-primary-color text-white"
                    : "bg-gray-100 border-gray-300"
                }`}
              >
                1 năm
              </button>
              <button
                type="button"
                id={"2"}
                onClick={() => {
                  handleSelectYear(2);
                }}
                className={`py-2 uppercase border-2 rounded-[4px] font-bold w-1/3 md:w-1/5 mx-2 xs:mx-5 flex items-center justify-center ${
                  yearCount === 2
                    ? "border-primary-color bg-primary-color text-white"
                    : "bg-gray-100 border-gray-300"
                }`}
              >
                2 năm
              </button>
              <button
                type="button"
                id={"3"}
                onClick={() => {
                  handleSelectYear(3);
                }}
                className={`py-2 uppercase border-2 rounded-[4px] font-bold w-1/3 md:w-1/5 mx-2 xs:mx-5 flex items-center justify-center ${
                  yearCount === 3
                    ? "border-primary-color bg-primary-color text-white"
                    : "bg-gray-100 border-gray-300"
                }`}
              >
                3 năm
              </button>
            </div>
            <div className="px-5 w-full">
              <div className="pb-5 flex flex-wrap items-center">
                <label
                  htmlFor="dateStartContract"
                  className="w-full font-bold mr-4"
                >
                  Ngày đăng ký:
                  <span className="text-red-600 text-[20px]">*</span>
                </label>

                <input
                  id="dateStartContract"
                  type="date"
                  onChange={(e) => {
                    setDateStartContract(e.target.value);
                  }}
                  value={dateStartContract}
                  min={new Date().toISOString().split("T")[0]}
                  className="rounded-[4px] border-b-2 border-[#004caf] placeholder-shown:border-gray-500 focus:outline-none h-[40px] text-lg w-full md:w-1/2 lg:w-1/3 px-5"
                ></input>
              </div>
              <div className="pb-5 flex flex-wrap items-center">
                <label
                  htmlFor="dateEndContract"
                  className="w-full font-bold mr-5"
                >
                  Ngày hết hạn:
                  <span className="text-red-600 text-[20px]">*</span>
                </label>
                <input
                  id="dateEndContract"
                  type="date"
                  // onChange={(e) => setDateEndContract(e.target.value)}
                  value={dateEndContract}
                  min={new Date().toISOString().split("T")[0]}
                  max={maxDateEndContract}
                  className="rounded-[4px] border-b-2 border-[#004caf] placeholder-shown:border-gray-500 focus:outline-none h-[40px] text-lg w-full md:w-1/2 lg:w-1/3 px-5"
                  readOnly
                ></input>
              </div>
            </div>

            <TitleBanner title={"QUYỀN LỢI BẢO HIỂM"} />
            <div className="w-[90%] m-auto pb-[5px]">
              {/* <h2 className="text-[20px] font-bold">QUYỀN LỢI KHI CÓ BẢO HIỂM: <span className="text-red-600">*</span></h2> */}
              <div className="flex">
                <i className="text-[18px] items-center justify-center mr-[5px]">
                  <ion-icon name="checkbox-outline"></ion-icon>
                </i>
                <h2 className="text-[15px]">
                  Bồi thường thiệt hại về sức khỏe, tính mạng cho người bị đụng
                  đến 150 triệu đồng/người/vụ.
                </h2>
              </div>
              <div className="flex">
                <i className="text-[18px] items-center justify-center mr-[5px]">
                  <ion-icon name="checkbox-outline"></ion-icon>
                </i>
                <h2 className="text-[15px]">
                  Bồi thường thiệt hại về tài sản cho người bị đụng đến 50 triệu
                  đồng/vụ cho xe máy, đến 100 triệu đồng/vụ cho xe ôtô.
                </h2>
              </div>

              <div className="flex">
                <i className="text-[18px] items-center justify-center mr-[5px]">
                  <ion-icon name="checkbox-outline"></ion-icon>
                </i>
                <h2 className="text-[15px]">
                  Nhận giấy chứng nhận điện tử hợp pháp, không cần mang thẻ
                  giấy.
                </h2>
              </div>
            </div>
            <div className="text-[15px] pl-[20px] pb-[8px] text-gray-600 font-medium">
              Tóm tắt Quyền lợi bảo hiểm xem{" "}
              <a
                target="_blank"
                className="hover:opacity-75 cursor-pointer text-blue-600 font-medium"
                href="/poclicy/interests"
              >
                tại đây
              </a>
            </div>

            {/* <TitleBanner title={"THANH TOÁN PHÍ"} />

          <div className="px-5">
            <p>1.Xe máy điện, xe 2 bánh &le; 50cc: 55.000&#8363;/năm</p>
            <p>2.Xe 2 bánh trên 50cc: 60.000&#8363;/năm</p>
            <p>3.Xe mô tô 3 bánh &amp; các loại khác: 290.000&#8363;/năm</p>
            <p>
              *Giá trên chưa bao gồm VAT và theo quy định tại Nghị định
              03/2021/NĐ-CP của Chính Phủ và Thông tư 04/2021/TT-BTC của Bộ Tài
              Chính.
            </p>
          </div> */}

            <TitleBanner title={"PHƯƠNG THỨC THANH TOÁN"} />

            <PaymentMethodSection
              paymentMethod={paymentMethod}
              //bankCodeZalo={bankCodeZalo}
              handlePaymentMethod={(value) => {
                console.log(value);
                setPaymentMethod(value);
              }}
              //handleBankCodeZalo={(value) => setBankCodeZalo(value)}
            />

            <TitleBanner title={"NHÀ BẢO HIỂM"} />
            <InsuranceProviderSection
              insuranceProvider={insuranceProvider}
              handleInsuranceProvider={(value) => setInsuranceProvider(value)}
            />
            <TitleBanner title={"BẢO HIỂM TAI NẠN"} />

            <div className="px-5 pb-[5px]">
              <input
                id="thirdParty"
                type="checkbox"
                checked={thirdParty}
                onChange={handleThirdPartyOnChange}
                className="mr-2"
              />

              <label htmlFor="thirdParty">
                Bảo hiểm tai nạn cho người lái xe và người ngồi sau xe{" "}
                <b>10 triệu đồng / người / vụ tai nạn</b>
              </label>
            </div>

            <PriceFooter
              loading={loading}
              price={price}
              handlePaymentOnClick={handlePaymentOnClick}
            />
          </div>
        </div>
      </div>
    </ShopLayout>
  );
};

export default MotorbikeInsuranceForm;
