import { MA_XE_3_BANH, MA_XE_DUOI_50CC, MA_XE_TREN_50CC } from "./maLoaiXe";

const DataSP = [
    {
        type: 1,
        title: "Xe ≤ 50cc <br/> Xe điện",
        image: '/images/scooter.webp',
        price: "55",
        typeCode: MA_XE_DUOI_50CC
    },
    {
        type: 2,
        title: "Xe 2 bánh <br/> > 50cc",
        image: '/images/motorbike.webp',
        price: "60",
        typeCode: MA_XE_TREN_50CC
    },
    {
        type: 3,
        title: "Xe 3 bánh <br/> Xe còn lại",
        image: '/images/moto.webp',
        price: "290",
        typeCode: MA_XE_3_BANH
    }

]

export default DataSP;