import React from 'react'
import { ReactComponent as SearchLogo } from '../../assets/icons/search.svg'
export default function SearchBtn({
  iconOnly = false,
  onClick = () => {},
  iconSmallScreenOnly = false,
}) {
  return (
    <div
      className="flex flex-col h-full justify-center items-center  cursor-pointer bg-[#ffffff] p-[10px] lg:ml-2 mt-4 ml-0 lg:mt-0
        rounded-[5px]
    "
    >
      <div
        className="
        flex lg:flex-col justify-center items-center
        
        "
        onClick={onClick}
      >
        <span
          className={`text-[14px] font-semibold ${
            iconOnly ? 'lg:hidden' : ''
          } ${iconSmallScreenOnly ? 'hidden' : ''}
            mr-[5px]
            text-[#003985]
          `}
        >
          Tìm kiếm
        </span>
        <SearchLogo className="w-6 h-auto fill-[#003985] mr-[5px]  self-center" />
      </div>
    </div>
  )
}
